import { Button, Modal } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddDialogProps } from './FlexList';

const DefaultOpenModalButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<IconPlus />} onClick={() => onClick()}>
            {t('createItem', { itemType: t('item') })}
        </Button>
    );
};

type Props = {
    title: ReactNode;
    AddDialogType: (props: AddDialogProps) => ReactNode;
    AddButton?: (props: { onClick: () => void }) => ReactNode;
};

export const CreateItemModal = ({ title, AddDialogType, AddButton = DefaultOpenModalButton }: Props) => {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    return (
        <>
            <Modal
                closeOnClickOutside={false}
                opened={isCreateDialogOpen}
                size={1000}
                title={title}
                centered
                onClose={() => setIsCreateDialogOpen(false)}
            >
                <AddDialogType onCancel={() => setIsCreateDialogOpen(false)} />
            </Modal>
            <AddButton
                onClick={() => {
                    setIsCreateDialogOpen(true);
                }}
            />
        </>
    );
};
