import cx from 'clsx';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { NavLinksConfig, NavLinksContext } from 'shared/components/baseLayout/NavLinksContext';
import classes from './NavBar.module.css';

type Props = {
    onNavLinkClicked: (navLink: NavLinksConfig) => void;
};

export const NavBar = ({ onNavLinkClicked }: Props) => {
    const navLinks = useContext(NavLinksContext);

    const links = navLinks.map((item) => (
        <NavLink
            key={item.label}
            className={({ isActive }) => {
                return cx(classes.link, {
                    [classes.linkActive]: isActive
                });
            }}
            end={item.link === ''}
            to={item.link}
            onClick={() => onNavLinkClicked(item)}
        >
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
        </NavLink>
    ));

    return <>{links}</>;
};
