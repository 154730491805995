import { Button } from '@mantine/core';
import { IconMailForward } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const InviteUserButton = ({ onClick: handleClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<IconMailForward />} onClick={handleClick}>
            {t('inviteUser')}
        </Button>
    );
};
