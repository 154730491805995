/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateOrUpdateClientModel,
  GetClientActivityLogParams,
  GetClientByIdParams,
  GetClientByIdResponse,
  GetClientDetailByIdParams,
  GetClientDetailsByIdResponse,
  GetClientUserActivityLogParams,
  GetClientsParams,
  GetClientsResponse,
  GetUserActivityResponse,
  ProblemDetails,
  UploadClientBackgroundImageBody,
  UploadClientPictureBody
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all Clients<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClients = (
    params?: GetClientsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetClientsResponse>(
      {url: `/clients`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetClientsQueryKey = (params?: GetClientsParams,) => {
    return [`/clients`, ...(params ? [params]: [])] as const;
    }

    
export const getGetClientsQueryOptions = <TData = Awaited<ReturnType<typeof getClients>>, TError = ErrorType<ProblemDetails>>(params?: GetClientsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClients>>> = ({ signal }) => getClients(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClients>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientsQueryResult = NonNullable<Awaited<ReturnType<typeof getClients>>>
export type GetClientsQueryError = ErrorType<ProblemDetails>

export const useGetClients = <TData = Awaited<ReturnType<typeof getClients>>, TError = ErrorType<ProblemDetails>>(
 params?: GetClientsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClientsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the basic information of a client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClientById = (
    id: string,
    params?: GetClientByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetClientByIdResponse>(
      {url: `/clients/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetClientByIdQueryKey = (id: string,
    params?: GetClientByIdParams,) => {
    return [`/clients/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetClientByIdQueryOptions = <TData = Awaited<ReturnType<typeof getClientById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetClientByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientById>>> = ({ signal }) => getClientById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getClientById>>>
export type GetClientByIdQueryError = ErrorType<ProblemDetails>

export const useGetClientById = <TData = Awaited<ReturnType<typeof getClientById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClientByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates an existing Client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const updateClient = (
    id: string,
    createOrUpdateClientModel: CreateOrUpdateClientModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetClientDetailsByIdResponse>(
      {url: `/clients/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateClientModel
    },
      options);
    }
  


export const getUpdateClientMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateClient>>, TError,{id: string;data: CreateOrUpdateClientModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateClient>>, TError,{id: string;data: CreateOrUpdateClientModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateClient>>, {id: string;data: CreateOrUpdateClientModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateClient(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateClientMutationResult = NonNullable<Awaited<ReturnType<typeof updateClient>>>
    export type UpdateClientMutationBody = CreateOrUpdateClientModel
    export type UpdateClientMutationError = ErrorType<ProblemDetails>

    export const useUpdateClient = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateClient>>, TError,{id: string;data: CreateOrUpdateClientModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes a client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const deleteClient = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/clients/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteClientMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClient>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteClient>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteClient>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteClient(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteClientMutationResult = NonNullable<Awaited<ReturnType<typeof deleteClient>>>
    
    export type DeleteClientMutationError = ErrorType<ProblemDetails>

    export const useDeleteClient = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClient>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeleteClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the detailed information of a client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClientDetailById = (
    id: string,
    params?: GetClientDetailByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetClientDetailsByIdResponse>(
      {url: `/clients/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetClientDetailByIdQueryKey = (id: string,
    params?: GetClientDetailByIdParams,) => {
    return [`/clients/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetClientDetailByIdQueryOptions = <TData = Awaited<ReturnType<typeof getClientDetailById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetClientDetailByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientDetailById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientDetailByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientDetailById>>> = ({ signal }) => getClientDetailById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientDetailById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientDetailByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getClientDetailById>>>
export type GetClientDetailByIdQueryError = ErrorType<ProblemDetails>

export const useGetClientDetailById = <TData = Awaited<ReturnType<typeof getClientDetailById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientDetailByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientDetailById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClientDetailByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Moves a client to another application<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write<br>login.umdaschgroup.com/applications.write
 */
export const moveClient = (
    id: string,
    applicationId: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/clients/${id}/move/${applicationId}`, method: 'POST'
    },
      options);
    }
  


export const getMoveClientMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveClient>>, TError,{id: string;applicationId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof moveClient>>, TError,{id: string;applicationId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveClient>>, {id: string;applicationId: string}> = (props) => {
          const {id,applicationId} = props ?? {};

          return  moveClient(id,applicationId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type MoveClientMutationResult = NonNullable<Awaited<ReturnType<typeof moveClient>>>
    
    export type MoveClientMutationError = ErrorType<ProblemDetails>

    export const useMoveClient = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moveClient>>, TError,{id: string;applicationId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getMoveClientMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Uploads a client picture for an existing client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const uploadClientPicture = (
    id: string,
    uploadClientPictureBody: UploadClientPictureBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', uploadClientPictureBody.File)

      return customMutator<void>(
      {url: `/clients/${id}/client-picture`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadClientPictureMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadClientPicture>>, TError,{id: string;data: UploadClientPictureBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadClientPicture>>, TError,{id: string;data: UploadClientPictureBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadClientPicture>>, {id: string;data: UploadClientPictureBody}> = (props) => {
          const {id,data} = props ?? {};

          return  uploadClientPicture(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadClientPictureMutationResult = NonNullable<Awaited<ReturnType<typeof uploadClientPicture>>>
    export type UploadClientPictureMutationBody = UploadClientPictureBody
    export type UploadClientPictureMutationError = ErrorType<ProblemDetails>

    export const useUploadClientPicture = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadClientPicture>>, TError,{id: string;data: UploadClientPictureBody}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUploadClientPictureMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Uploads a login background image for an existing client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const uploadClientBackgroundImage = (
    id: string,
    uploadClientBackgroundImageBody: UploadClientBackgroundImageBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', uploadClientBackgroundImageBody.File)

      return customMutator<void>(
      {url: `/clients/${id}/background-image`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadClientBackgroundImageMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadClientBackgroundImage>>, TError,{id: string;data: UploadClientBackgroundImageBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadClientBackgroundImage>>, TError,{id: string;data: UploadClientBackgroundImageBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadClientBackgroundImage>>, {id: string;data: UploadClientBackgroundImageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  uploadClientBackgroundImage(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadClientBackgroundImageMutationResult = NonNullable<Awaited<ReturnType<typeof uploadClientBackgroundImage>>>
    export type UploadClientBackgroundImageMutationBody = UploadClientBackgroundImageBody
    export type UploadClientBackgroundImageMutationError = ErrorType<ProblemDetails>

    export const useUploadClientBackgroundImage = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadClientBackgroundImage>>, TError,{id: string;data: UploadClientBackgroundImageBody}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUploadClientBackgroundImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes a background image for an existing client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const deleteClientBackgroundImage = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/clients/${id}/background-image`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteClientBackgroundImageMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClientBackgroundImage>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteClientBackgroundImage>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteClientBackgroundImage>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteClientBackgroundImage(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteClientBackgroundImageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteClientBackgroundImage>>>
    
    export type DeleteClientBackgroundImageMutationError = ErrorType<ProblemDetails>

    export const useDeleteClientBackgroundImage = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClientBackgroundImage>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeleteClientBackgroundImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * OBSOLETE: Provides the user activity log for a client. Use /{id:guid}/activity-log instead<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 * @deprecated
 */
export const getClientUserActivityLog = (
    id: string,
    params?: GetClientUserActivityLogParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserActivityResponse>(
      {url: `/clients/${id}/user-activity-log`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetClientUserActivityLogQueryKey = (id: string,
    params?: GetClientUserActivityLogParams,) => {
    return [`/clients/${id}/user-activity-log`, ...(params ? [params]: [])] as const;
    }

    
export const getGetClientUserActivityLogQueryOptions = <TData = Awaited<ReturnType<typeof getClientUserActivityLog>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetClientUserActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientUserActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientUserActivityLogQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientUserActivityLog>>> = ({ signal }) => getClientUserActivityLog(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientUserActivityLog>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientUserActivityLogQueryResult = NonNullable<Awaited<ReturnType<typeof getClientUserActivityLog>>>
export type GetClientUserActivityLogQueryError = ErrorType<ProblemDetails>

/**
 * @deprecated
 */
export const useGetClientUserActivityLog = <TData = Awaited<ReturnType<typeof getClientUserActivityLog>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientUserActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientUserActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClientUserActivityLogQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Provides the activity log for a client<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClientActivityLog = (
    id: string,
    params?: GetClientActivityLogParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserActivityResponse>(
      {url: `/clients/${id}/activity-log`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetClientActivityLogQueryKey = (id: string,
    params?: GetClientActivityLogParams,) => {
    return [`/clients/${id}/activity-log`, ...(params ? [params]: [])] as const;
    }

    
export const getGetClientActivityLogQueryOptions = <TData = Awaited<ReturnType<typeof getClientActivityLog>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetClientActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientActivityLogQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientActivityLog>>> = ({ signal }) => getClientActivityLog(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientActivityLog>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientActivityLogQueryResult = NonNullable<Awaited<ReturnType<typeof getClientActivityLog>>>
export type GetClientActivityLogQueryError = ErrorType<ProblemDetails>

export const useGetClientActivityLog = <TData = Awaited<ReturnType<typeof getClientActivityLog>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetClientActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClientActivityLogQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



