import { Button, Group, Text, Modal, ModalProps } from '@mantine/core';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    isOpen: boolean;
    onConfirm: () => void | Promise<void>;
    onCancel: () => void;
    message?: ReactNode;
    isConfirmationDisabled?: boolean;
    confirmText?: string;
    cancelText?: string;
} & Omit<ModalProps, 'opened' | 'onClose'>;

export const ConfirmationModal = ({
    isOpen,
    onConfirm,
    onCancel: handleCancel,
    title,
    message,
    children,
    isConfirmationDisabled,
    confirmText,
    cancelText,
    ...rest
}: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
    };

    return (
        <Modal
            {...rest}
            opened={isOpen}
            title={title}
            onClick={(event) => event.stopPropagation()}
            onClose={handleCancel}
        >
            <Text c="dimmed" mb="md" mt={-12} style={{ position: 'relative', zIndex: 1001 }}>
                {message}
            </Text>
            {children}
            <Group mt="md">
                <Button ml="auto" variant="outline" onClick={handleCancel}>
                    {cancelText || t('cancel')}
                </Button>
                <Button disabled={isConfirmationDisabled} loading={isLoading} onClick={handleConfirm}>
                    {confirmText || t('confirm')}
                </Button>
            </Group>
        </Modal>
    );
};
