import { Stack, Title } from '@mantine/core';
import { t } from 'i18next';

import { GlobalActivityLogGrid } from 'modules/shared/activityLog/GlobalActivityLogGrid';

export const ActivityLog = () => {
    return (
        <Stack>
            <Title order={2}>{t('activityLog')}</Title>
            <GlobalActivityLogGrid />
        </Stack>
    );
};
