import { Button, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { getSearchUsersQueryKey, useInviteUser } from 'api/v3/user/user';
import { i18n } from 'i18n';
import { ErrorNotification } from 'shared/components/errorHandling';
import { ExtendedTextInput } from 'shared/components/inputs';
import { successNotification } from 'shared/notifications';
import { emailRegex } from 'shared/regex';

export const inviteUserSchema = z.object({
    email: z
        .string()
        .min(1, { message: i18n.t('fieldRequired') })
        .regex(emailRegex, i18n.t('invalidFormat')),
    firstName: z.string().min(1, { message: i18n.t('fieldRequired') }),
    lastName: z.string().min(1, { message: i18n.t('fieldRequired') })
});

export type InviteUserFormValues = z.infer<typeof inviteUserSchema>;

type Props = {
    onCancel: () => void;
};

export const InviteUser = ({ onCancel }: Props) => {
    const { t } = useTranslation();
    const form = useForm<InviteUserFormValues>({
        initialValues: { email: '', firstName: '', lastName: '' },
        validate: zodResolver(inviteUserSchema)
    });
    const [error, setError] = useState<unknown>();
    const { mutateAsync: inviteUser } = useInviteUser();
    const queryClient = useQueryClient();
    const [busy, setBusy] = useState(false);

    const handleInvitation = async (formValues: InviteUserFormValues) => {
        try {
            setBusy(true);
            await inviteUser({ data: formValues });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            successNotification({
                message: t('wasSuccessful', {
                    type: t('user'),
                    name: `${formValues.firstName} ${formValues.lastName}`,
                    action: t('invite')
                })
            });

            onCancel();
        } catch (err) {
            setError(err);
        } finally {
            setBusy(false);
        }
    };

    return (
        <form
            // eslint-disable-next-line react/jsx-handler-names
            onSubmit={form.onSubmit(handleInvitation)}
        >
            <Stack>
                <ExtendedTextInput
                    form={form}
                    label={t('email')}
                    placeholder={t('emailPlaceholder')}
                    propertyPath="email"
                />
                <ExtendedTextInput
                    form={form}
                    label={t('firstName')}
                    placeholder={t('firstNamePlaceholder')}
                    propertyPath="firstName"
                />
                <ExtendedTextInput
                    form={form}
                    label={t('lastName')}
                    placeholder={t('lastNamePlaceholder')}
                    propertyPath="lastName"
                />
                <ErrorNotification error={error} title={t('errorInvitingUser')} />
                <Button loading={busy} ml="auto" type="submit">
                    {t('inviteUser')}
                </Button>
            </Stack>
        </form>
    );
};
