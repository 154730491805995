import { Button } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { PrincipalsWithAccessGridType, useClientFormContext } from 'modules/applications/client/ClientFormProvider';
import { getItemLink } from 'modules/shared/functions';
import { usePermissions } from 'modules/shared/permissions';
import { PrincipalSelector, SelectedPrincipal } from 'modules/shared/PrincipalSelector';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';

const AddAccessibilityPrincipal = () => {
    const form = useClientFormContext();
    const { t } = useTranslation();

    return (
        <Button
            leftSection={<IconPlus />}
            onClick={() => form.insertListItem('accessibilitySettings.principalsWithAccess', generateNewPrincipal())}
        >
            {t('addItem', { itemType: t('principal') })}
        </Button>
    );
};

export const PrincipalWithAccessGrid = () => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    const handlePrincipalSelected = (selectedPrincipal: SelectedPrincipal, index: number) => {
        form.setFieldValue(`accessibilitySettings.principalsWithAccess.${index}.isEditable`, false);
        form.setFieldValue(`accessibilitySettings.principalsWithAccess.${index}.id`, selectedPrincipal.id);
        form.setFieldValue(
            `accessibilitySettings.principalsWithAccess.${index}.displayName`,
            selectedPrincipal.displayName
        );
        form.setFieldValue(`accessibilitySettings.principalsWithAccess.${index}.email`, selectedPrincipal.email);
        form.setFieldValue(
            `accessibilitySettings.principalsWithAccess.${index}.principalType`,
            selectedPrincipal.principalType
        );
        form.setFieldValue(
            `accessibilitySettings.principalsWithAccess.${index}.assignmentType`,
            selectedPrincipal.assignmentType
        );
    };

    return (
        <ExtendedDataTable
            canCreate={canUpdateOrCreate}
            canEdit={canUpdateOrCreate}
            canRemove={canUpdateOrCreate}
            data={form.values.accessibilitySettings.principalsWithAccess}
            fieldDescriptions={[
                {
                    header: t('displayName'),
                    data: 'displayName',
                    lockUpdate: true,
                    link: (item) => getItemLink(item)
                },
                {
                    header: t('email'),
                    data: 'email',
                    lockUpdate: true
                },
                {
                    header: t('type'),
                    data: 'principalType',
                    lockUpdate: true
                }
            ]}
            form={form}
            generateNewItem={generateNewPrincipal}
            placeholder={
                <Placeholder
                    action={canUpdateOrCreate ? <AddAccessibilityPrincipal /> : undefined}
                    text={t('noPrincipalsAssigned')}
                />
            }
            propertyPathBase="accessibilitySettings.principalsWithAccess"
            renderEdit={(_item, index, onItemSaved) => {
                return (
                    <PrincipalSelector
                        additionalPrincipals={form.values.accessibilitySettings.principalsWithAccess.map(
                            (principal) => ({
                                id: principal.id,
                                email: principal.email,
                                principalType: principal.type
                            })
                        )}
                        form={form}
                        principalTypeFilter={['Tenant', 'User']}
                        propertyPath={`accessibilitySettings.principalsWithAccess.${index}.id`}
                        onPrincipalSelected={(principal) => {
                            handlePrincipalSelected(principal, index);
                            onItemSaved && onItemSaved();
                        }}
                    />
                );
            }}
        />
    );
};

const generateNewPrincipal = (): PrincipalsWithAccessGridType => ({
    id: '',
    type: 'User',
    displayName: '',
    key: randomId(),
    isEditable: true,
    isRemoved: false
});
