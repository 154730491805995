import { ActionIcon, Tooltip, Menu } from '@mantine/core';
import { IconUserCancel, IconUserCheck } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useActivateUser, useDeactivateUser, getSearchUsersQueryKey } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { errorNotification, hideNotification, loadingNotification, successNotification } from 'shared/notifications';

export const DeactivateAction = (
    user: BasicUserModelApiResponse,
    index: number,
    rowActionVariant: RowActionVariant
) => {
    const { t } = useTranslation();
    const { mutateAsync: deactivate } = useDeactivateUser();
    const { mutateAsync: activate } = useActivateUser();
    const queryClient = useQueryClient();
    const id = useId();
    const canDeactivateUser = usePermissions('DeactivateUser', 'User', user);

    if (user.data.state === UserState.Locked || user.data.state === UserState.Deleted) {
        return null;
    }

    const isActive = user.data.state !== UserState.Deactivated;
    const labelText = isActive ? t('deactivate') : t('activate');

    const handleDeactivate = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        const action = isActive ? t('deactivate') : t('activate');
        loadingNotification({
            id,
            message: t('willBe', {
                type: t('user'),
                name: user.data.displayName,
                action: isActive ? t('deactivated') : t('activated')
            })
        });
        try {
            isActive ? await deactivate({ id: user.data.id }) : await activate({ id: user.data.id });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            successNotification({
                message: t('wasSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action
                })
            });
        } catch {
            errorNotification({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action
                })
            });
        } finally {
            hideNotification(id);
        }
    };

    return (
        canDeactivateUser &&
        (rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={labelText}>
                <ActionIcon onClick={handleDeactivate}>{isActive ? <IconUserCancel /> : <IconUserCheck />}</ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={isActive ? <IconUserCancel /> : <IconUserCheck />} onClick={handleDeactivate}>
                {labelText}
            </Menu.Item>
        ))
    );
};
