import { ActionIcon, Input, Menu, Stack, Text, Tooltip } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from 'axios';
import { MouseEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TranslationKeys } from 'i18n';
import { ConfirmationModal } from 'shared/components/confirmationModal/ConfirmationModal';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { ErrorNotification } from './errorHandling';

type Props = {
    onDeleteItem: () => void | Promise<void>;
    itemType: TranslationKeys;
    name?: string;
    labelKey?: TranslationKeys;
    titleKey?: TranslationKeys;
    confirmationText?: string;
    rowActionVariant?: RowActionVariant;
};

export const DeleteItemAction = ({
    onDeleteItem,
    itemType,
    labelKey = 'delete',
    titleKey = 'deleteItem',
    name,
    confirmationText,
    rowActionVariant = RowActionVariant.DEFAULT
}: Props) => {
    const { t } = useTranslation();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [textInput, setTextInput] = useState('');
    const [error, setError] = useState<string | object | undefined>();

    const handleDeleteItemClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        event.nativeEvent.preventDefault();
        setIsConfirmationModalOpen(true);
    };

    const handleDeleteItem = async () => {
        try {
            setError(undefined);
            await onDeleteItem();
            setTextInput('');
            setIsConfirmationModalOpen(false);
        } catch (err: Error | AxiosError | unknown) {
            if (axios.isAxiosError(err)) {
                setError(err.response?.data);
            } else if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Unknown error');
            }
        }
    };

    return (
        <>
            {rowActionVariant === RowActionVariant.DEFAULT ? (
                <Tooltip label={t(labelKey)}>
                    <ActionIcon onClick={handleDeleteItemClick}>
                        <IconTrash />
                    </ActionIcon>
                </Tooltip>
            ) : (
                <Menu.Item leftSection={<IconTrash />} style={{ width: 'content' }} onClick={handleDeleteItemClick}>
                    {t(labelKey)}
                </Menu.Item>
            )}

            <ConfirmationModal
                confirmText={t(labelKey)}
                isConfirmationDisabled={!!confirmationText && textInput !== confirmationText}
                isOpen={isConfirmationModalOpen}
                message={
                    <Trans
                        components={[<Text key={name} fw={600} span />]}
                        i18nKey="areYouSure"
                        ns="translations"
                        values={{ itemType: t(itemType), action: t(labelKey), name }}
                    />
                }
                title={t(titleKey, { itemType: t(itemType) })}
                onCancel={() => setIsConfirmationModalOpen(false)}
                onConfirm={handleDeleteItem}
            >
                {confirmationText && (
                    <Stack>
                        <Text>
                            {t('deleteItemConfirmationText', {
                                itemType: t(itemType),
                                confirmationText
                            })}
                        </Text>
                        <Input placeholder={confirmationText} value={textInput} autoFocus onChange={(event) => setTextInput(event.target.value)} />
                    </Stack>
                )}
                <ErrorNotification
                    error={error}
                    mt="lg"
                    title={t('deletingItem', {
                        itemType: t(itemType)
                    })}
                />
            </ConfirmationModal>
        </>
    );
};
