import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { DefaultErrorBoundary } from 'shared/components';
import { AuthProvider } from 'shared/components/auth';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { AppRoutes } from './AppRoutes';
import { defaultQueryClient } from './queryDefaults';
import { ThemeOverrides } from './ThemeOverrides';
import './App.css';
import '../i18n';
import './MantineStyles.css';

export const App = () => {
    const { t } = useTranslation();

    return (
        <BrowserRouter>
            <MantineProvider theme={ThemeOverrides}>
                <Notifications />
                <ModalsProvider labels={{ confirm: t('yes'), cancel: t('no') }} modalProps={{ centered: true }}>
                    <AuthProvider autoLogin={true} configuration={configurationIdentityServer}>
                        <AppInsightsContextProvider>
                            <QueryClientProvider client={defaultQueryClient}>
                                <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                    <AppRoutes />
                                </DefaultErrorBoundary>
                            </QueryClientProvider>
                        </AppInsightsContextProvider>
                    </AuthProvider>
                </ModalsProvider>
            </MantineProvider>
        </BrowserRouter>
    );
};
