/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetCurrentUserDetailsParams,
  GetCurrentUserParams,
  GetCurrentUsersActivityLogParams,
  GetSupportedCultureCodesResponse,
  GetUserActivityLogByIdParams,
  GetUserActivityLogParams,
  GetUserActivityResponse,
  GetUserByIdParams,
  GetUserClientsResponse,
  GetUserDetailsByIdParams,
  GetUserDetailsResponse,
  GetUserGroupsResponse,
  GetUserResponse,
  GetUserRolesResponse,
  GetUsersParams,
  GetUsersResponse,
  InviteUserModel,
  PreRegistrationModel,
  ProblemDetails,
  ResolveUsersParams,
  ResolveUsersResponse,
  SearchUsersParams,
  UpdateUserModel,
  UpdateUserPasswordModel,
  UploadCurrentUserImageBody,
  UploadUserImageByUserIdBody,
  UriApiResponse,
  ValidateNewUserPasswordModel,
  ValidatePasswordResponse
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of users<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUsers = (
    params?: GetUsersParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUsersResponse>(
      {url: `/users`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersQueryKey = (params?: GetUsersParams,) => {
    return [`/users`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<ProblemDetails>>(params?: GetUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = ErrorType<ProblemDetails>

export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<ProblemDetails>>(
 params?: GetUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the basic information of a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserById = (
    id: string,
    params?: GetUserByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserResponse>(
      {url: `/users/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserByIdQueryKey = (id: string,
    params?: GetUserByIdParams,) => {
    return [`/users/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetUserByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({ signal }) => getUserById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserById>>>
export type GetUserByIdQueryError = ErrorType<ProblemDetails>

export const useGetUserById = <TData = Awaited<ReturnType<typeof getUserById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetUserByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Deletes an existing user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const deleteUserById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteUserByIdMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserById>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteUserById(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteUserByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserById>>>
    
    export type DeleteUserByIdMutationError = ErrorType<ProblemDetails>

    export const useDeleteUserById = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeleteUserByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Updates an existing user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const updateUser = (
    id: string,
    updateUserModel: UpdateUserModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetUserResponse>(
      {url: `/users/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserModel
    },
      options);
    }
  


export const getUpdateUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{id: string;data: UpdateUserModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{id: string;data: UpdateUserModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, {id: string;data: UpdateUserModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateUser(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
    export type UpdateUserMutationBody = UpdateUserModel
    export type UpdateUserMutationError = ErrorType<ProblemDetails>

    export const useUpdateUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{id: string;data: UpdateUserModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the basic information of the current user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getCurrentUser = (
    params?: GetCurrentUserParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserResponse>(
      {url: `/users/me`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCurrentUserQueryKey = (params?: GetCurrentUserParams,) => {
    return [`/users/me`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentUser>>, TError = ErrorType<ProblemDetails>>(params?: GetCurrentUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentUser>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUser>>> = ({ signal }) => getCurrentUser(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentUser>>>
export type GetCurrentUserQueryError = ErrorType<ProblemDetails>

export const useGetCurrentUser = <TData = Awaited<ReturnType<typeof getCurrentUser>>, TError = ErrorType<ProblemDetails>>(
 params?: GetCurrentUserParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentUser>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Deletes the current user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const deleteCurrentUser = (
    
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/me`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteCurrentUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCurrentUser>>, TError,void, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCurrentUser>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCurrentUser>>, void> = () => {
          

          return  deleteCurrentUser(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCurrentUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCurrentUser>>>
    
    export type DeleteCurrentUserMutationError = ErrorType<ProblemDetails>

    export const useDeleteCurrentUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCurrentUser>>, TError,void, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeleteCurrentUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Updates an existing user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const updateCurrentUser = (
    updateUserModel: UpdateUserModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetUserResponse>(
      {url: `/users/me`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserModel
    },
      options);
    }
  


export const getUpdateCurrentUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCurrentUser>>, TError,{data: UpdateUserModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCurrentUser>>, TError,{data: UpdateUserModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCurrentUser>>, {data: UpdateUserModel}> = (props) => {
          const {data} = props ?? {};

          return  updateCurrentUser(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateCurrentUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateCurrentUser>>>
    export type UpdateCurrentUserMutationBody = UpdateUserModel
    export type UpdateCurrentUserMutationError = ErrorType<ProblemDetails>

    export const useUpdateCurrentUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCurrentUser>>, TError,{data: UpdateUserModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateCurrentUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the detail information of a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserDetailsById = (
    id: string,
    params?: GetUserDetailsByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserDetailsResponse>(
      {url: `/users/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserDetailsByIdQueryKey = (id: string,
    params?: GetUserDetailsByIdParams,) => {
    return [`/users/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserDetailsByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserDetailsById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetUserDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDetailsByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDetailsById>>> = ({ signal }) => getUserDetailsById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserDetailsById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserDetailsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDetailsById>>>
export type GetUserDetailsByIdQueryError = ErrorType<ProblemDetails>

export const useGetUserDetailsById = <TData = Awaited<ReturnType<typeof getUserDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetUserDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserDetailsByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the detail information of the current user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getCurrentUserDetails = (
    params?: GetCurrentUserDetailsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserDetailsResponse>(
      {url: `/users/me/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCurrentUserDetailsQueryKey = (params?: GetCurrentUserDetailsParams,) => {
    return [`/users/me/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCurrentUserDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentUserDetails>>, TError = ErrorType<ProblemDetails>>(params?: GetCurrentUserDetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentUserDetails>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentUserDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUserDetails>>> = ({ signal }) => getCurrentUserDetails(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrentUserDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentUserDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentUserDetails>>>
export type GetCurrentUserDetailsQueryError = ErrorType<ProblemDetails>

export const useGetCurrentUserDetails = <TData = Awaited<ReturnType<typeof getCurrentUserDetails>>, TError = ErrorType<ProblemDetails>>(
 params?: GetCurrentUserDetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentUserDetails>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentUserDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Uploads a user image for an existing user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const uploadUserImageByUserId = (
    id: string,
    uploadUserImageByUserIdBody: UploadUserImageByUserIdBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', uploadUserImageByUserIdBody.File)

      return customMutator<void>(
      {url: `/users/${id}/image`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadUserImageByUserIdMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadUserImageByUserId>>, TError,{id: string;data: UploadUserImageByUserIdBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadUserImageByUserId>>, TError,{id: string;data: UploadUserImageByUserIdBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadUserImageByUserId>>, {id: string;data: UploadUserImageByUserIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  uploadUserImageByUserId(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadUserImageByUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof uploadUserImageByUserId>>>
    export type UploadUserImageByUserIdMutationBody = UploadUserImageByUserIdBody
    export type UploadUserImageByUserIdMutationError = ErrorType<ProblemDetails>

    export const useUploadUserImageByUserId = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadUserImageByUserId>>, TError,{id: string;data: UploadUserImageByUserIdBody}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUploadUserImageByUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Uploads a user image for the current user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const uploadCurrentUserImage = (
    uploadCurrentUserImageBody: UploadCurrentUserImageBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', uploadCurrentUserImageBody.File)

      return customMutator<void>(
      {url: `/users/me/image`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadCurrentUserImageMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadCurrentUserImage>>, TError,{data: UploadCurrentUserImageBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadCurrentUserImage>>, TError,{data: UploadCurrentUserImageBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadCurrentUserImage>>, {data: UploadCurrentUserImageBody}> = (props) => {
          const {data} = props ?? {};

          return  uploadCurrentUserImage(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadCurrentUserImageMutationResult = NonNullable<Awaited<ReturnType<typeof uploadCurrentUserImage>>>
    export type UploadCurrentUserImageMutationBody = UploadCurrentUserImageBody
    export type UploadCurrentUserImageMutationError = ErrorType<ProblemDetails>

    export const useUploadCurrentUserImage = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadCurrentUserImage>>, TError,{data: UploadCurrentUserImageBody}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUploadCurrentUserImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a list of users, based on given filters<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const searchUsers = (
    params?: SearchUsersParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUsersResponse>(
      {url: `/users/search`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSearchUsersQueryKey = (params?: SearchUsersParams,) => {
    return [`/users/search`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchUsersQueryOptions = <TData = Awaited<ReturnType<typeof searchUsers>>, TError = ErrorType<ProblemDetails>>(params?: SearchUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchUsers>>> = ({ signal }) => searchUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchUsersQueryResult = NonNullable<Awaited<ReturnType<typeof searchUsers>>>
export type SearchUsersQueryError = ErrorType<ProblemDetails>

export const useSearchUsers = <TData = Awaited<ReturnType<typeof searchUsers>>, TError = ErrorType<ProblemDetails>>(
 params?: SearchUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchUsers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Undeletes an soft-deleted user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const undeleteUser = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}/undelete`, method: 'POST'
    },
      options);
    }
  


export const getUndeleteUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof undeleteUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof undeleteUser>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof undeleteUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  undeleteUser(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UndeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof undeleteUser>>>
    
    export type UndeleteUserMutationError = ErrorType<ProblemDetails>

    export const useUndeleteUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof undeleteUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUndeleteUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Enforces deletion of a soft-deleted user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const hardDeleteUserById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}/hard-delete`, method: 'DELETE'
    },
      options);
    }
  


export const getHardDeleteUserByIdMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof hardDeleteUserById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof hardDeleteUserById>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof hardDeleteUserById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  hardDeleteUserById(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type HardDeleteUserByIdMutationResult = NonNullable<Awaited<ReturnType<typeof hardDeleteUserById>>>
    
    export type HardDeleteUserByIdMutationError = ErrorType<ProblemDetails>

    export const useHardDeleteUserById = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof hardDeleteUserById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getHardDeleteUserByIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Configures a client to be used by a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const configureClientForUser = (
    userId: string,
    clientId: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${userId}/clients/${clientId}`, method: 'POST'
    },
      options);
    }
  


export const getConfigureClientForUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof configureClientForUser>>, TError,{userId: string;clientId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof configureClientForUser>>, TError,{userId: string;clientId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof configureClientForUser>>, {userId: string;clientId: string}> = (props) => {
          const {userId,clientId} = props ?? {};

          return  configureClientForUser(userId,clientId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ConfigureClientForUserMutationResult = NonNullable<Awaited<ReturnType<typeof configureClientForUser>>>
    
    export type ConfigureClientForUserMutationError = ErrorType<ProblemDetails>

    export const useConfigureClientForUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof configureClientForUser>>, TError,{userId: string;clientId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getConfigureClientForUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the basic information of the clients, configured for a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserClients = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserClientsResponse>(
      {url: `/users/${id}/clients`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserClientsQueryKey = (id: string,) => {
    return [`/users/${id}/clients`] as const;
    }

    
export const getGetUserClientsQueryOptions = <TData = Awaited<ReturnType<typeof getUserClients>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserClientsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserClients>>> = ({ signal }) => getUserClients(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserClients>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserClientsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserClients>>>
export type GetUserClientsQueryError = ErrorType<ProblemDetails>

export const useGetUserClients = <TData = Awaited<ReturnType<typeof getUserClients>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserClients>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserClientsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the basic information of the roles, that are assigned to a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserRoles = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserRolesResponse>(
      {url: `/users/${id}/roles`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRolesQueryKey = (id: string,) => {
    return [`/users/${id}/roles`] as const;
    }

    
export const getGetUserRolesQueryOptions = <TData = Awaited<ReturnType<typeof getUserRoles>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRolesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRoles>>> = ({ signal }) => getUserRoles(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRoles>>>
export type GetUserRolesQueryError = ErrorType<ProblemDetails>

export const useGetUserRoles = <TData = Awaited<ReturnType<typeof getUserRoles>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserRolesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the basic information of the groups, a user is assigned to<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserGroups = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserGroupsResponse>(
      {url: `/users/${id}/groups`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserGroupsQueryKey = (id: string,) => {
    return [`/users/${id}/groups`] as const;
    }

    
export const getGetUserGroupsQueryOptions = <TData = Awaited<ReturnType<typeof getUserGroups>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserGroupsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserGroups>>> = ({ signal }) => getUserGroups(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserGroups>>>
export type GetUserGroupsQueryError = ErrorType<ProblemDetails>

export const useGetUserGroups = <TData = Awaited<ReturnType<typeof getUserGroups>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserGroupsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Validates if a password matches the criteria for the current user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.confidential
 */
export const validatePassword = (
    validatePasswordBody: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<ValidatePasswordResponse>(
      {url: `/users/me/validate-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: validatePasswordBody
    },
      options);
    }
  


export const getValidatePasswordMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validatePassword>>, TError,{data: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof validatePassword>>, TError,{data: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validatePassword>>, {data: string}> = (props) => {
          const {data} = props ?? {};

          return  validatePassword(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ValidatePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof validatePassword>>>
    export type ValidatePasswordMutationBody = string
    export type ValidatePasswordMutationError = ErrorType<ProblemDetails>

    export const useValidatePassword = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validatePassword>>, TError,{data: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getValidatePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Validates if a password matches the criteria without checking a users password history
 */
export const validatePasswordUnauthorized = (
    validateNewUserPasswordModel: ValidateNewUserPasswordModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<ValidatePasswordResponse>(
      {url: `/users/new/validate-password`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: validateNewUserPasswordModel
    },
      options);
    }
  


export const getValidatePasswordUnauthorizedMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validatePasswordUnauthorized>>, TError,{data: ValidateNewUserPasswordModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof validatePasswordUnauthorized>>, TError,{data: ValidateNewUserPasswordModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validatePasswordUnauthorized>>, {data: ValidateNewUserPasswordModel}> = (props) => {
          const {data} = props ?? {};

          return  validatePasswordUnauthorized(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ValidatePasswordUnauthorizedMutationResult = NonNullable<Awaited<ReturnType<typeof validatePasswordUnauthorized>>>
    export type ValidatePasswordUnauthorizedMutationBody = ValidateNewUserPasswordModel
    export type ValidatePasswordUnauthorizedMutationError = ErrorType<ProblemDetails>

    export const useValidatePasswordUnauthorized = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validatePasswordUnauthorized>>, TError,{data: ValidateNewUserPasswordModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getValidatePasswordUnauthorizedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Updates an existing user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.confidential
 */
export const updateUserPassword = (
    id: string,
    updateUserPasswordModel: UpdateUserPasswordModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}/password`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserPasswordModel
    },
      options);
    }
  


export const getUpdateUserPasswordMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserPassword>>, TError,{id: string;data: UpdateUserPasswordModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserPassword>>, TError,{id: string;data: UpdateUserPasswordModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserPassword>>, {id: string;data: UpdateUserPasswordModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateUserPassword(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateUserPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserPassword>>>
    export type UpdateUserPasswordMutationBody = UpdateUserPasswordModel
    export type UpdateUserPasswordMutationError = ErrorType<ProblemDetails>

    export const useUpdateUserPassword = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserPassword>>, TError,{id: string;data: UpdateUserPasswordModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateUserPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Updates an existing user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.confidential
 */
export const updateCurrentUserPassword = (
    updateUserPasswordModel: UpdateUserPasswordModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/me/password`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateUserPasswordModel
    },
      options);
    }
  


export const getUpdateCurrentUserPasswordMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCurrentUserPassword>>, TError,{data: UpdateUserPasswordModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCurrentUserPassword>>, TError,{data: UpdateUserPasswordModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCurrentUserPassword>>, {data: UpdateUserPasswordModel}> = (props) => {
          const {data} = props ?? {};

          return  updateCurrentUserPassword(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateCurrentUserPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof updateCurrentUserPassword>>>
    export type UpdateCurrentUserPasswordMutationBody = UpdateUserPasswordModel
    export type UpdateCurrentUserPasswordMutationError = ErrorType<ProblemDetails>

    export const useUpdateCurrentUserPassword = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCurrentUserPassword>>, TError,{data: UpdateUserPasswordModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateCurrentUserPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Resolves user ids to its corresponding models<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const resolveUsers = (
    params: ResolveUsersParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<ResolveUsersResponse>(
      {url: `/users/resolve`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getResolveUsersQueryKey = (params: ResolveUsersParams,) => {
    return [`/users/resolve`, ...(params ? [params]: [])] as const;
    }

    
export const getResolveUsersQueryOptions = <TData = Awaited<ReturnType<typeof resolveUsers>>, TError = ErrorType<ProblemDetails>>(params: ResolveUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolveUsers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getResolveUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof resolveUsers>>> = ({ signal }) => resolveUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof resolveUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type ResolveUsersQueryResult = NonNullable<Awaited<ReturnType<typeof resolveUsers>>>
export type ResolveUsersQueryError = ErrorType<ProblemDetails>

export const useResolveUsers = <TData = Awaited<ReturnType<typeof resolveUsers>>, TError = ErrorType<ProblemDetails>>(
 params: ResolveUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolveUsers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getResolveUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Activates a deactivated user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const activateUser = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}/activate`, method: 'POST'
    },
      options);
    }
  


export const getActivateUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof activateUser>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activateUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  activateUser(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ActivateUserMutationResult = NonNullable<Awaited<ReturnType<typeof activateUser>>>
    
    export type ActivateUserMutationError = ErrorType<ProblemDetails>

    export const useActivateUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getActivateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deactivates an activated user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const deactivateUser = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}/deactivate`, method: 'POST'
    },
      options);
    }
  


export const getDeactivateUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deactivateUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deactivateUser>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deactivateUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deactivateUser(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeactivateUserMutationResult = NonNullable<Awaited<ReturnType<typeof deactivateUser>>>
    
    export type DeactivateUserMutationError = ErrorType<ProblemDetails>

    export const useDeactivateUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deactivateUser>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeactivateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Forces a user to reset their password on their next login<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const forcePasswordReset = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/${id}/force-password-reset`, method: 'POST'
    },
      options);
    }
  


export const getForcePasswordResetMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forcePasswordReset>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof forcePasswordReset>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof forcePasswordReset>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  forcePasswordReset(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ForcePasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof forcePasswordReset>>>
    
    export type ForcePasswordResetMutationError = ErrorType<ProblemDetails>

    export const useForcePasswordReset = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof forcePasswordReset>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getForcePasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Sends a reset link to a user, or generates and returns the url if user has no email configured<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const resetPassword = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<UriApiResponse>(
      {url: `/users/${id}/reset-password`, method: 'POST'
    },
      options);
    }
  


export const getResetPasswordMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  resetPassword(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>
    
    export type ResetPasswordMutationError = ErrorType<ProblemDetails>

    export const useResetPassword = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetPassword>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Provides the user activity log for a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserActivityLogById = (
    id: string,
    params?: GetUserActivityLogByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserActivityResponse>(
      {url: `/users/${id}/activity-log`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserActivityLogByIdQueryKey = (id: string,
    params?: GetUserActivityLogByIdParams,) => {
    return [`/users/${id}/activity-log`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserActivityLogByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserActivityLogById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetUserActivityLogByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserActivityLogById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserActivityLogByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserActivityLogById>>> = ({ signal }) => getUserActivityLogById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserActivityLogById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserActivityLogByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserActivityLogById>>>
export type GetUserActivityLogByIdQueryError = ErrorType<ProblemDetails>

export const useGetUserActivityLogById = <TData = Awaited<ReturnType<typeof getUserActivityLogById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetUserActivityLogByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserActivityLogById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserActivityLogByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Provides the user activity log for the current user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getCurrentUsersActivityLog = (
    params?: GetCurrentUsersActivityLogParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserActivityResponse>(
      {url: `/users/me/activity-log`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCurrentUsersActivityLogQueryKey = (params?: GetCurrentUsersActivityLogParams,) => {
    return [`/users/me/activity-log`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCurrentUsersActivityLogQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentUsersActivityLog>>, TError = ErrorType<ProblemDetails>>(params?: GetCurrentUsersActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentUsersActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentUsersActivityLogQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUsersActivityLog>>> = ({ signal }) => getCurrentUsersActivityLog(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrentUsersActivityLog>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentUsersActivityLogQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentUsersActivityLog>>>
export type GetCurrentUsersActivityLogQueryError = ErrorType<ProblemDetails>

export const useGetCurrentUsersActivityLog = <TData = Awaited<ReturnType<typeof getCurrentUsersActivityLog>>, TError = ErrorType<ProblemDetails>>(
 params?: GetCurrentUsersActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentUsersActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentUsersActivityLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Provides the user activity log for all users<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read
 */
export const getUserActivityLog = (
    params?: GetUserActivityLogParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserActivityResponse>(
      {url: `/users/activity-log`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUserActivityLogQueryKey = (params?: GetUserActivityLogParams,) => {
    return [`/users/activity-log`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserActivityLogQueryOptions = <TData = Awaited<ReturnType<typeof getUserActivityLog>>, TError = ErrorType<ProblemDetails>>(params?: GetUserActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserActivityLogQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserActivityLog>>> = ({ signal }) => getUserActivityLog(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserActivityLog>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserActivityLogQueryResult = NonNullable<Awaited<ReturnType<typeof getUserActivityLog>>>
export type GetUserActivityLogQueryError = ErrorType<ProblemDetails>

export const useGetUserActivityLog = <TData = Awaited<ReturnType<typeof getUserActivityLog>>, TError = ErrorType<ProblemDetails>>(
 params?: GetUserActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserActivityLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of all supported culture codes
 */
export const getSupportedCultureCodes = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetSupportedCultureCodesResponse>(
      {url: `/users/culture-codes`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSupportedCultureCodesQueryKey = () => {
    return [`/users/culture-codes`] as const;
    }

    
export const getGetSupportedCultureCodesQueryOptions = <TData = Awaited<ReturnType<typeof getSupportedCultureCodes>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSupportedCultureCodes>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSupportedCultureCodesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSupportedCultureCodes>>> = ({ signal }) => getSupportedCultureCodes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSupportedCultureCodes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSupportedCultureCodesQueryResult = NonNullable<Awaited<ReturnType<typeof getSupportedCultureCodes>>>
export type GetSupportedCultureCodesQueryError = ErrorType<ProblemDetails>

export const useGetSupportedCultureCodes = <TData = Awaited<ReturnType<typeof getSupportedCultureCodes>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSupportedCultureCodes>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSupportedCultureCodesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Invites a new user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const inviteUser = (
    inviteUserModel: InviteUserModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<UriApiResponse>(
      {url: `/users/invite`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inviteUserModel
    },
      options);
    }
  


export const getInviteUserMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUser>>, TError,{data: InviteUserModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof inviteUser>>, TError,{data: InviteUserModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUser>>, {data: InviteUserModel}> = (props) => {
          const {data} = props ?? {};

          return  inviteUser(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type InviteUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUser>>>
    export type InviteUserMutationBody = InviteUserModel
    export type InviteUserMutationError = ErrorType<ProblemDetails>

    export const useInviteUser = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUser>>, TError,{data: InviteUserModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getInviteUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Resends the invitation of a user or returns the url if no email is set<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const resendInvitation = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<UriApiResponse>(
      {url: `/users/${id}/resend-invitation`, method: 'POST'
    },
      options);
    }
  


export const getResendInvitationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendInvitation>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof resendInvitation>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resendInvitation>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  resendInvitation(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ResendInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof resendInvitation>>>
    
    export type ResendInvitationMutationError = ErrorType<ProblemDetails>

    export const useResendInvitation = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendInvitation>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getResendInvitationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Creates or updates a pre registration for a user<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.write
 */
export const createOrUpdatePreRegistration = (
    preRegistrationModel: PreRegistrationModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/users/pre-registration`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: preRegistrationModel
    },
      options);
    }
  


export const getCreateOrUpdatePreRegistrationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrUpdatePreRegistration>>, TError,{data: PreRegistrationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrUpdatePreRegistration>>, TError,{data: PreRegistrationModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrUpdatePreRegistration>>, {data: PreRegistrationModel}> = (props) => {
          const {data} = props ?? {};

          return  createOrUpdatePreRegistration(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateOrUpdatePreRegistrationMutationResult = NonNullable<Awaited<ReturnType<typeof createOrUpdatePreRegistration>>>
    export type CreateOrUpdatePreRegistrationMutationBody = PreRegistrationModel
    export type CreateOrUpdatePreRegistrationMutationError = ErrorType<ProblemDetails>

    export const useCreateOrUpdatePreRegistration = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrUpdatePreRegistration>>, TError,{data: PreRegistrationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getCreateOrUpdatePreRegistrationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    