import { Group, Radio, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ClientAccessibility } from 'api/v3/models';
import { useClientFormContext } from 'modules/applications/client/ClientFormProvider';
import { PrincipalWithAccessGrid } from 'modules/applications/client/components/PrincipalWithAccessGrid';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { RadioGroup, Switch } from 'shared/components/inputs';

type Props = DetailFormChildProps;

export const Accessibility = (_: Props) => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    return (
        <Stack>
            <RadioGroup
                defaultValue={form.values.accessibilitySettings.accessibility}
                form={form}
                label={t('accessibility')}
                propertyPath="accessibilitySettings.accessibility"
            >
                <Group mt="xs">
                    {Object.keys(ClientAccessibility).map((accessibility) => (
                        <Radio
                            key={accessibility}
                            disabled={!canUpdateOrCreate}
                            label={accessibility}
                            value={accessibility}
                        />
                    ))}
                </Group>
            </RadioGroup>
            {form.values.accessibilitySettings.accessibility === ClientAccessibility.Dedicated && (
                <PrincipalWithAccessGrid />
            )}
            <Switch
                description={t('visibleToUsersDescription')}
                disabled={!canUpdateOrCreate}
                form={form}
                label={t('visibleToUsers')}
                labelPosition="left"
                propertyPath="accessibilitySettings.visibleToUsers"
            />
        </Stack>
    );
};
