import { Group, Select } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetApiResources, useGetApiResourceScopes } from 'api/v3/api-resources/api-resources';
import { BasicApiResourceScopeModel } from 'api/v3/models';
import { useClientFormContext } from 'modules/applications/client/ClientFormProvider';

type Props = {
    index: number;
    onScopeSelected?: (principal: BasicApiResourceScopeModel) => void;
};

export const ScopeSelector = ({ index, onScopeSelected }: Props) => {
    const { t } = useTranslation();
    const form = useClientFormContext();

    const { data: apis, isLoading: areApisLoading } = useGetApiResources();
    const [selectedApiId, setSelectedApiId] = useState<string>();

    const { data: scopes, isLoading: areScopesLoading } = useGetApiResourceScopes(
        selectedApiId ?? '',
        {},
        {
            query: { enabled: !!selectedApiId }
        }
    );

    const handleSelectApi = async (value: string | null) => {
        setSelectedApiId(value ?? undefined);
    };
    const handleSelectScope = (value: string | null) => {
        if (value) {
            const foundScope = scopes?.data.find((scope) => scope.data.id === value);
            if (foundScope) {
                onScopeSelected && onScopeSelected(foundScope.data);
            }
        }
    };

    const apiOptions = apis
        ? apis.data.map((api) => ({
              value: api.data.id,
              label: [api.data.displayName, `(${api.data.name})`].join(' ')
          }))
        : [];

    const scopeOptions = scopes
        ? scopes.data.map((scope) => {
              const scopeName = scope.data.name.split('/').pop();
              const label = [scope.data.displayName, `(${scopeName})`].join(' ');
              return {
                  value: scope.data.id,
                  label
              };
          })
        : [];

    return (
        <Group grow>
            <Select
                data={apiOptions}
                disabled={areApisLoading}
                nothingFoundMessage={t('noOptions')}
                placeholder={t('selectApi')}
                value={selectedApiId ?? null}
                autoFocus
                clearable
                searchable
                onChange={handleSelectApi}
            />
            <Select
                data={scopeOptions}
                disabled={!scopes || areScopesLoading}
                error={form.errors[`permissionSettings.clientScopes.${index}.name`]}
                nothingFoundMessage={t('noOptions')}
                placeholder={t('selectItem', { itemType: t('scope') })}
                clearable
                searchable
                onChange={handleSelectScope}
            />
        </Group>
    );
};
