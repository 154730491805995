import { OidcUserInfo } from '@axa-fr/oidc-client';
import { useOidcUser } from '@axa-fr/react-oidc';
import { Menu, Stack, Tooltip } from '@mantine/core';

import { Avatar } from 'shared/components/avatar';
import { UserImageDropDownContent } from './UserImageDropDownContent';

const getPictureRendition = (userProfile: OidcUserInfo | undefined, rendition: string) => {
    if (!userProfile) {
        return undefined;
    }
    const renditionName = `picture_${rendition}` as keyof typeof userProfile;

    return (renditionName in userProfile ? (userProfile[renditionName] as string) : undefined) ?? userProfile?.picture;
};

export const UserImage = () => {
    const { oidcUser } = useOidcUser();

    const label = (
        <Stack gap={0}>
            <div>{oidcUser?.name}</div>
            <div>{oidcUser?.email}</div>
        </Stack>
    );

    const pictureS = getPictureRendition(oidcUser, 's');
    const pictureM = getPictureRendition(oidcUser, 'm');

    return (
        <>
            <Menu position="bottom-end" shadow="lg" width={300}>
                <Menu.Target>
                    <Tooltip label={label} position="bottom-end">
                        <Avatar name={oidcUser?.name} src={pictureS} />
                    </Tooltip>
                </Menu.Target>

                <Menu.Dropdown className="">
                    <UserImageDropDownContent userImage={pictureM} userProfile={oidcUser} />
                </Menu.Dropdown>
            </Menu>
        </>
    );
};
