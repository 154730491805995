/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Combobox,
    OptionsDropdown,
    useCombobox,
    getParsedComboboxData,
    getOptionsLockup,
    ComboboxLikeProps,
    ComboboxLikeStylesNames,
    InputBase,
    BoxProps,
    ElementProps,
    InputVariant,
    StylesApiProps,
    __BaseInputProps,
    __CloseButtonProps,
    __InputStylesNames,
    factory,
    Factory,
    useProps,
    useResolvedStylesApi,
    isOptionsGroup,
    ComboboxItem,
    ComboboxData,
    Text
} from '@mantine/core';
import { useId, useUncontrolled } from '@mantine/hooks';
import React, { ReactNode, useEffect, useMemo } from 'react';

import { getItemValue } from './getItemValue';
import { Option } from './Option';

export type SelectStylesNames = __InputStylesNames | ComboboxLikeStylesNames;

export interface SelectProps
    extends BoxProps,
        __BaseInputProps,
        ComboboxLikeProps,
        StylesApiProps<SelectFactory>,
        ElementProps<'input', 'onChange' | 'size' | 'value' | 'defaultValue'> {
    /** Controlled component value */
    value?: string | null;

    /** Uncontrolled component default value */
    defaultValue?: string | null;

    /** Called when value changes */
    onChange?(value: string | null): void;

    /** Determines whether the select should be searchable, `false` by default */
    searchable?: boolean;

    /** Determines whether check icon should be displayed near the selected option label, `true` by default */
    withCheckIcon?: boolean;

    /** Position of the check icon relative to the option label, `'left'` by default */
    checkIconPosition?: 'left' | 'right';

    /** Message displayed when no option matched current search query, only applicable when `searchable` prop is set */
    nothingFoundMessage?: React.ReactNode;

    /** Controlled search value */
    searchValue?: string;

    /** Default search value */
    defaultSearchValue?: string;

    /** Called when search changes */
    onSearchChange?(value: string): void;

    /** Determines whether it should be possible to deselect value by clicking on the selected option, `true` by default */
    allowDeselect?: boolean;

    /** Determines whether the clear button should be displayed in the right section when the component has value, `false` by default */
    clearable?: boolean;

    /** Props passed down to the clear button */
    clearButtonProps?: __CloseButtonProps & ElementProps<'button'>;

    /** Props passed down to the hidden input */
    hiddenInputProps?: React.ComponentPropsWithoutRef<'input'>;

    /** Function to get create Label */
    getCreateLabel?: (query: string) => ReactNode;

    /** Function to determine if create label should be displayed */
    shouldCreate?(query: string, data: ComboboxData): boolean;

    /** Called when create option is selected */
    onCreate?(query: string): ComboboxItem | string | null | undefined;
}

export type SelectFactory = Factory<{
    props: SelectProps;
    ref: HTMLInputElement;
    stylesNames: SelectStylesNames;
    variant: InputVariant;
}>;

const defaultProps: Partial<SelectProps> = {
    searchable: false,
    withCheckIcon: true,
    allowDeselect: true,
    checkIconPosition: 'left'
};

export const Select = factory<SelectFactory>((_props, ref) => {
    const props = useProps('Select', defaultProps, _props);
    const {
        classNames,
        styles,
        unstyled,
        vars,
        dropdownOpened,
        defaultDropdownOpened,
        onDropdownClose,
        onDropdownOpen,
        onFocus,
        onBlur,
        onClick,
        onChange,
        data,
        value,
        defaultValue,
        selectFirstOptionOnChange,
        onOptionSubmit,
        comboboxProps,
        readOnly,
        disabled,
        filter,
        limit,
        withScrollArea,
        maxDropdownHeight,
        size,
        searchable,
        rightSection,
        checkIconPosition,
        withCheckIcon,
        nothingFoundMessage,
        name,
        form,
        searchValue,
        defaultSearchValue,
        onSearchChange,
        allowDeselect,
        error,
        rightSectionPointerEvents,
        id,
        clearable,
        clearButtonProps,
        hiddenInputProps,
        shouldCreate,
        getCreateLabel,
        onCreate,
        ...others
    } = props;

    const parsedData = useMemo(() => getParsedComboboxData(data), [data]);
    const optionsLockup = useMemo(() => getOptionsLockup(parsedData), [parsedData]);
    const _id = useId(id);

    const [_value, setValue] = useUncontrolled({
        value,
        defaultValue,
        finalValue: null,
        onChange
    });

    const [_searchValue, setSearchValue] = useUncontrolled({
        value: searchValue,
        defaultValue: defaultSearchValue,
        finalValue: '',
        onChange: onSearchChange
    });

    const selectedOption = _value ? optionsLockup[_value] : undefined;

    const combobox = useCombobox({
        opened: dropdownOpened,
        defaultOpened: defaultDropdownOpened,
        onDropdownOpen,
        onDropdownClose: () => {
            onDropdownClose?.();
            combobox.resetSelectedOption();
        }
    });

    const { resolvedClassNames, resolvedStyles } = useResolvedStylesApi<SelectFactory>({
        props,
        styles,
        classNames
    });

    useEffect(() => {
        if (selectFirstOptionOnChange) {
            combobox.selectFirstOption();
        }
    }, [selectFirstOptionOnChange, _value]);

    useEffect(() => {
        if (value === null) {
            setSearchValue('');
        }

        if (typeof value === 'string' && selectedOption) {
            setSearchValue(selectedOption.label);
        }
    }, [value, selectedOption]);

    const clearButton = clearable && !!_value && !disabled && !readOnly && (
        <Combobox.ClearButton
            size={size as string}
            {...clearButtonProps}
            onClear={() => {
                setValue(null);
                setSearchValue('');
            }}
        />
    );

    const options = parsedData.map((item) => (
        <Option
            key={isOptionsGroup(item) ? item.group : item.value}
            checkIconPosition={checkIconPosition}
            data={item}
            unstyled={unstyled}
            value={value}
            withCheckIcon={withCheckIcon}
        />
    ));

    const createVal = '$create$';

    return (
        <>
            <Combobox
                __staticSelector="Select"
                classNames={resolvedClassNames}
                readOnly={readOnly}
                size={size}
                store={combobox}
                styles={resolvedStyles}
                unstyled={unstyled}
                onOptionSubmit={(val) => {
                    onOptionSubmit?.(val);

                    if (val === createVal) {
                        const newItem = onCreate && onCreate(_searchValue);
                        const newItemValue = getItemValue(newItem);
                        if (newItemValue) {
                            setValue(newItemValue);
                        }
                        setSearchValue(newItemValue ?? '');
                    } else {
                        const nextValue = allowDeselect
                            ? optionsLockup[val].value === _value
                                ? null
                                : optionsLockup[val].value
                            : optionsLockup[val].value;
                        setValue(nextValue);
                        setSearchValue(nextValue ? optionsLockup[val].label : '');
                    }
                    combobox.closeDropdown();
                }}
                {...comboboxProps}
            >
                <Combobox.Target targetType={searchable ? 'input' : 'button'}>
                    <InputBase
                        ref={ref}
                        id={_id}
                        rightSection={rightSection || clearButton || <Combobox.Chevron error={error} size={size} unstyled={unstyled} />}
                        rightSectionPointerEvents={rightSectionPointerEvents || clearButton ? 'all' : 'none'}
                        {...others}
                        __staticSelector="Select"
                        classNames={resolvedClassNames}
                        disabled={disabled}
                        error={error}
                        pointer={!searchable}
                        readOnly={readOnly || !searchable}
                        size={size}
                        styles={resolvedStyles}
                        unstyled={unstyled}
                        value={_searchValue}
                        onBlur={(event) => {
                            searchable && combobox.closeDropdown();
                            setSearchValue(_value ? optionsLockup[_value].label : '');
                            onBlur?.(event);
                        }}
                        onChange={(event) => {
                            setSearchValue(event.currentTarget.value);
                            combobox.openDropdown();
                            selectFirstOptionOnChange && combobox.selectFirstOption();
                        }}
                        onClick={(event) => {
                            searchable ? combobox.openDropdown() : combobox.toggleDropdown();
                            onClick?.(event);
                        }}
                        onFocus={(event) => {
                            searchable && combobox.openDropdown();
                            onFocus?.(event);
                        }}
                    />
                </Combobox.Target>
                <Combobox.Dropdown>
                    <Combobox.Options>
                        {options.length > 0 ? (
                            options
                        ) : shouldCreate && getCreateLabel && data && shouldCreate(_searchValue, data) ? (
                            <Combobox.Option value={createVal}>{getCreateLabel && getCreateLabel(_searchValue)}</Combobox.Option>
                        ) : (
                            <Combobox.Empty>{nothingFoundMessage}</Combobox.Empty>
                        )}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
            <input disabled={disabled} form={form} name={name} type="hidden" value={_value || ''} {...hiddenInputProps} />
        </>
    );
});

Select.classes = { ...InputBase.classes, ...Combobox.classes };
Select.displayName = '@mantine/core/Select';
