import { ActionIcon, ActionIconProps, Tooltip, useMantineTheme } from '@mantine/core';
import { IconCopy, IconSquareCheck } from '@tabler/icons-react';
import { forwardRef, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type CopyToClipboardProps = ActionIconProps & {
    textToCopy: string;
    copiedTime?: number;
    onClick?: (event: MouseEvent) => void;
};

export const CopyToClipboard = forwardRef<HTMLButtonElement, CopyToClipboardProps>(
    ({ textToCopy, copiedTime = 1000, onClick: handleClick, ...rest }: CopyToClipboardProps, ref) => {
        const [isCopied, setIsCopied] = useState(false);
        const { t } = useTranslation();
        const theme = useMantineTheme();

        const handleCopyToClipboard = (event: MouseEvent) => {
            navigator.clipboard.writeText(textToCopy);
            if (!isCopied) {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, copiedTime);
            }
            handleClick && handleClick(event);
        };

        return (
            <Tooltip label={t('copyToClipboard')}>
                <ActionIcon ref={ref} variant="light" onClick={handleCopyToClipboard} {...rest}>
                    <IconCopy
                        style={{
                            position: 'absolute',
                            strokeDasharray: 60,
                            strokeDashoffset: isCopied ? -60 : 0,
                            transition: 'all 300ms ease-in-out'
                        }}
                    />
                    <IconSquareCheck
                        color={theme.colors.green[7]}
                        style={{
                            position: 'absolute',
                            strokeDasharray: 80,
                            strokeDashoffset: isCopied ? 0 : -80,
                            transition: 'all 300ms ease-in-out'
                        }}
                    />
                </ActionIcon>
            </Tooltip>
        );
    }
);

CopyToClipboard.displayName = 'CopyToClipboard';
