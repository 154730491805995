import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { generateNewPrincipalAssignment } from 'modules/shared/manageAssignedPrincipals/functions';
import { usePrincipalAssignmentFormContext } from './FormProvider';

export const AddAssignedPrincipal = () => {
    const form = usePrincipalAssignmentFormContext();
    const { t } = useTranslation();

    return (
        <Button
            disabled={form.values.principalsAssignments.some((item) => item.isEditable)}
            leftSection={<IconPlus />}
            onClick={() => form.insertListItem('principalsAssignments', generateNewPrincipalAssignment())}
        >
            {t('addItem', { itemType: t('assignment') })}
        </Button>
    );
};
