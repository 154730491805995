import { useOidcUser } from '@axa-fr/react-oidc';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

type Props = {
    children?: ReactNode;
};
export const UserIntlProvider = ({ children }: Props) => {
    const { oidcUser } = useOidcUser();

    return <IntlProvider locale={oidcUser?.locale ?? 'en'}>{children}</IntlProvider>;
};
