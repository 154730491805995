import { ActionIcon, Flex, Menu, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { IconUserShare } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useResendInvitation, getSearchUsersQueryKey } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { CopyToClipboard } from 'shared/components';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { errorNotification, hideNotification, loadingNotification, successNotification } from 'shared/notifications';

export const ResendInviteAction = (
    user: BasicUserModelApiResponse,
    index: number,
    rowActionVariant: RowActionVariant
) => {
    const { t } = useTranslation();
    const { mutateAsync: resendInvitation } = useResendInvitation();
    const queryClient = useQueryClient();
    const id = useId();
    const canInvite = usePermissions('Invite', 'User', user);
    const canInviteWithoutEmail = usePermissions('InviteWithoutEmail', 'User', user);

    if (user.data.state !== UserState.InvitationPending && user.data.state !== UserState.InvitationExpired) {
        return null;
    }
    const notifySuccess = (responseUrl?: string | void) => {
        successNotification({
            message: (
                <Stack>
                    <Text>
                        {t('wasSuccessful', {
                            type: t('user'),
                            name: user.data.displayName,
                            action: t('resendInvitation')
                        })}
                    </Text>
                    {typeof responseUrl === 'string' && responseUrl && (
                        <Flex gap={6} ta="center">
                            <TextInput value={responseUrl} w="100%" readOnly />
                            <CopyToClipboard textToCopy={responseUrl} />
                        </Flex>
                    )}
                </Stack>
            ),
            autoClose: typeof responseUrl !== 'string' || !responseUrl
        });
    };
    const notifyInsuffientPermission = () => {
        errorNotification({
            message: t('insufficientPermission', {
                type: t('user'),
                action: t('resendInvitation')
            })
        });
    };

    const handleResendInvitation = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        loadingNotification({
            id,
            message: t('willBe', {
                type: t('user'),
                name: `${user.data.displayName} ${t('invitation').toLowerCase()}`,
                action: t('resent')
            })
        });
        try {
            if (user.data.email !== undefined) {
                if (canInvite) {
                    await resendInvitation({ id: user.data.id });
                    hideNotification(id);
                    notifySuccess();
                } else {
                    hideNotification(id);
                    notifyInsuffientPermission();
                }
            } else {
                if (canInviteWithoutEmail) {
                    const responseUrl = await resendInvitation({ id: user.data.id });
                    hideNotification(id);
                    notifySuccess(responseUrl?.data);
                } else {
                    hideNotification(id);
                    notifyInsuffientPermission();
                }
            }
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });
        } catch {
            errorNotification({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('resendInvitation')
                })
            });
        } finally {
            hideNotification(id);
        }
    };

    return (
        (canInvite || canInviteWithoutEmail) &&
        (rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('resendInvitation')}>
                <ActionIcon onClick={handleResendInvitation}>
                    <IconUserShare />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<IconUserShare />} onClick={handleResendInvitation}>
                {t('resendInvitation')}
            </Menu.Item>
        ))
    );
};
