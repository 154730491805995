import { Outlet } from 'react-router-dom';

import { BaseLayout, DefaultErrorBoundary } from 'shared/components';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';

export const UserManagementFrame = () => {
    return (
        <BaseLayout>
            <div style={{ position: 'relative', height: '100%' }}>
                <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                    <Outlet />
                </DefaultErrorBoundary>
            </div>
        </BaseLayout>
    );
};
