import { Stack, Grid } from '@mantine/core';
import { zodResolver } from '@mantine/form';
import { t } from 'i18next';
import { z } from 'zod';

import { MultiPersonSelect } from 'modules/shared/multiPersonSelect';
import { usePermissions } from 'modules/shared/permissions';
import { ImageFileButton } from 'shared/components/buttons';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedTextInput, ExtendedTextarea } from 'shared/components/inputs';
import { useApplicationFormContext } from '../ApplicationFormProvider';

type Props = DetailFormChildProps;

export const BasicInformation = (_: Props) => {
    const form = useApplicationFormContext();

    const warningValidator = zodResolver(
        z.object({
            application: z.object({
                owners: z
                    .object({})
                    .array()
                    .min(2, { message: t('dontStayAlone') })
            })
        })
    );

    const handleImageSelected = async (file?: File) => {
        const url = file ? window.URL.createObjectURL(file) : undefined;

        if (url) {
            form.setFieldValue('application.imageUri', url);
        }
    };

    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    const warnings = warningValidator(form.values);

    return (
        <Stack>
            <Grid grow>
                <Grid.Col span={8}>
                    <Stack>
                        <ExtendedTextInput
                            form={form}
                            label={t('name')}
                            placeholder={t('namePlaceholder')}
                            propertyPath="application.displayName"
                            readOnly={!canUpdateOrCreate}
                        />
                        <ExtendedTextarea
                            form={form}
                            label={t('description')}
                            maxRows={4}
                            minRows={4}
                            optional={t('optional')}
                            propertyPath="application.description"
                            readOnly={!canUpdateOrCreate}
                        />
                    </Stack>
                </Grid.Col>
                <Grid.Col mt="lg" span="content">
                    <ImageFileButton
                        height={180}
                        imageSrc={form.values.application.imageUri}
                        readOnly={!canUpdateOrCreate}
                        width={180}
                        onImageSelected={handleImageSelected}
                    />
                </Grid.Col>
            </Grid>
            <MultiPersonSelect
                form={form}
                iconDescription={t('applicationCollaboratorsDescription')}
                label={t('owners')}
                placeholder={t('ownersPlaceholder')}
                propertyPath="application.owners"
                readOnly={!canUpdateOrCreate}
                warnings={warnings}
            />
            <MultiPersonSelect
                form={form}
                iconDescription={t('applicationCollaboratorsDescription')}
                label={t('collaborators')}
                placeholder={t('collaborators')}
                propertyPath="application.collaborators"
                readOnly={!canUpdateOrCreate}
                warnings={warnings}
            />
        </Stack>
    );
};
