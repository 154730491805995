import { Button, ButtonGroup, Flex, FlexProps, NumberInput, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiResponseMetadata } from 'api/v3/models';

export type ItemsPerPageType = 25 | 50 | 100;

type Props = {
    page: number;
    onPageChange?: (page: number) => void;
    itemsPerPage?: ItemsPerPageType;
    onItemsPerPageChange?: (itemsPerPage: ItemsPerPageType) => void;
    totalPages?: number;
    disabled?: boolean;
    withSkip?: boolean;
    className?: string;
} & FlexProps;

type PagedItem = {
    metadata?: ApiResponseMetadata | null;
};

export const getTotalPages = (itemsPerPage: ItemsPerPageType, searchValue?: string, searchUsers?: PagedItem, users?: PagedItem) => {
    const totalCount = searchValue ? searchUsers?.metadata?.paging?.totalCount : users?.metadata?.paging?.totalCount;
    return Math.ceil((totalCount ?? 1) / itemsPerPage);
};

export const getPagingParams = (page: number, itemsPerPage: ItemsPerPageType) => ({
    $top: itemsPerPage,
    $skip: (page - 1) * itemsPerPage,
    $includeTotalCount: true,
    includePermissions: true
});

export const Pagination = forwardRef<HTMLDivElement, Props>(
    ({ itemsPerPage, page, onItemsPerPageChange, onPageChange, totalPages, withSkip, disabled, ...others }: Props, ref) => {
        const { t } = useTranslation();

        const showItemsPerPage = itemsPerPage && onItemsPerPageChange;

        const handleItemsPerPageChange = (newItemsPerPage: string | null) => {
            if (newItemsPerPage && showItemsPerPage) {
                switch (parseInt(newItemsPerPage)) {
                    case 50:
                        onItemsPerPageChange(50);
                        return;
                    case 100:
                        onItemsPerPageChange(100);
                        return;
                    case 25:
                    default:
                        onItemsPerPageChange(25);
                        return;
                }
            }
        };

        const handlePageChange = (newPageString: string | number) => {
            const newPage = typeof newPageString === 'string' ? parseInt(newPageString) : newPageString;
            onPageChange && onPageChange(newPage);
        };

        const maxPages = totalPages ?? Number.MAX_SAFE_INTEGER;

        const PreviousPageButton = (
            <Button disabled={disabled || page <= 1} p={8} variant="outline" w={40} onClick={() => handlePageChange(page - 1)}>
                <IconChevronLeft />
            </Button>
        );

        const NextPageButton = (
            <Button disabled={disabled || page >= maxPages} p={8} variant="outline" w={40} onClick={() => handlePageChange(page + 1)}>
                <IconChevronRight />
            </Button>
        );

        return (
            <Flex {...others} ref={ref} align="center" gap={16} justify={showItemsPerPage ? 'space-between' : 'center'}>
                {showItemsPerPage && (
                    <Flex align="center" gap={8}>
                        <span>{t('itemsPerPage', 'Items per page')}</span>
                        <Select
                            data={[
                                { value: '25', label: '25' },
                                { value: '50', label: '50' },
                                { value: '100', label: '100' }
                            ]}
                            disabled={disabled}
                            maw={88}
                            value={itemsPerPage.toString()}
                            onChange={handleItemsPerPageChange}
                        />
                    </Flex>
                )}

                <Flex align="center" gap={16}>
                    {withSkip ? (
                        <ButtonGroup>
                            <Button disabled={disabled || page <= 1} p={8} variant="outline" w={40} onClick={() => handlePageChange(1)}>
                                <IconChevronsLeft />
                            </Button>
                            {PreviousPageButton}
                        </ButtonGroup>
                    ) : (
                        PreviousPageButton
                    )}

                    <Flex align="center" gap={4}>
                        <span>{t('page', 'Page')}</span>
                        <NumberInput disabled={disabled} maw={56} max={maxPages} min={1} value={page} hideControls onChange={handlePageChange} />
                        {totalPages && (
                            <span>
                                {t('of')} {totalPages}
                            </span>
                        )}
                    </Flex>
                    {withSkip ? (
                        <ButtonGroup>
                            {NextPageButton}
                            <Button disabled={disabled || page >= maxPages} p={8} variant="outline" w={40} onClick={() => handlePageChange(maxPages)}>
                                <IconChevronsRight />
                            </Button>
                        </ButtonGroup>
                    ) : (
                        NextPageButton
                    )}
                </Flex>
            </Flex>
        );
    }
);

Pagination.displayName = 'Pagination';
