/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateOrUpdateTenantModel,
  GetAllLoginProvidersResponse,
  GetLoginProviderByIdResponse,
  GetLoginProvidersByTenantIdParams,
  GetLoginProvidersByTenantIdResponse,
  GetLoginProvidersParams,
  GetTenantByIdParams,
  GetTenantByIdResponse,
  GetTenantDetailParams,
  GetTenantDetailsByIdResponse,
  GetTenantUsersActivityLogParams,
  GetTenantsParams,
  GetTenantsResponse,
  GetUserActivityResponse,
  ProblemDetails,
  SearchTenantsParams,
  TenantConfigurationModel,
  UpdateLoginDomainModel
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of tenants<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getTenants = (
    params?: GetTenantsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTenantsResponse>(
      {url: `/tenants`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTenantsQueryKey = (params?: GetTenantsParams,) => {
    return [`/tenants`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTenantsQueryOptions = <TData = Awaited<ReturnType<typeof getTenants>>, TError = ErrorType<ProblemDetails>>(params?: GetTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenants>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenants>>> = ({ signal }) => getTenants(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTenants>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof getTenants>>>
export type GetTenantsQueryError = ErrorType<ProblemDetails>

export const useGetTenants = <TData = Awaited<ReturnType<typeof getTenants>>, TError = ErrorType<ProblemDetails>>(
 params?: GetTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenants>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.write
 */
export const createTenant = (
    createOrUpdateTenantModel: CreateOrUpdateTenantModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetTenantByIdResponse>(
      {url: `/tenants`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateTenantModel
    },
      options);
    }
  


export const getCreateTenantMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTenant>>, TError,{data: CreateOrUpdateTenantModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createTenant>>, TError,{data: CreateOrUpdateTenantModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTenant>>, {data: CreateOrUpdateTenantModel}> = (props) => {
          const {data} = props ?? {};

          return  createTenant(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof createTenant>>>
    export type CreateTenantMutationBody = CreateOrUpdateTenantModel
    export type CreateTenantMutationError = ErrorType<ProblemDetails>

    export const useCreateTenant = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTenant>>, TError,{data: CreateOrUpdateTenantModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getCreateTenantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a list of tenants, based on the given search term<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const searchTenants = (
    params?: SearchTenantsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTenantsResponse>(
      {url: `/tenants/search`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSearchTenantsQueryKey = (params?: SearchTenantsParams,) => {
    return [`/tenants/search`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchTenantsQueryOptions = <TData = Awaited<ReturnType<typeof searchTenants>>, TError = ErrorType<ProblemDetails>>(params?: SearchTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchTenants>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchTenantsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchTenants>>> = ({ signal }) => searchTenants(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchTenants>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof searchTenants>>>
export type SearchTenantsQueryError = ErrorType<ProblemDetails>

export const useSearchTenants = <TData = Awaited<ReturnType<typeof searchTenants>>, TError = ErrorType<ProblemDetails>>(
 params?: SearchTenantsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchTenants>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchTenantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns the basic information of a tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getTenantById = (
    id: string,
    params?: GetTenantByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTenantByIdResponse>(
      {url: `/tenants/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTenantByIdQueryKey = (id: string,
    params?: GetTenantByIdParams,) => {
    return [`/tenants/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTenantByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTenantById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetTenantByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenantById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantById>>> = ({ signal }) => getTenantById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTenantById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTenantByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantById>>>
export type GetTenantByIdQueryError = ErrorType<ProblemDetails>

export const useGetTenantById = <TData = Awaited<ReturnType<typeof getTenantById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTenantByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenantById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates an existing tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.write
 */
export const updateTenant = (
    id: string,
    createOrUpdateTenantModel: CreateOrUpdateTenantModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetTenantByIdResponse>(
      {url: `/tenants/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateTenantModel
    },
      options);
    }
  


export const getUpdateTenantMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenant>>, TError,{id: string;data: CreateOrUpdateTenantModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTenant>>, TError,{id: string;data: CreateOrUpdateTenantModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTenant>>, {id: string;data: CreateOrUpdateTenantModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTenant(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenant>>>
    export type UpdateTenantMutationBody = CreateOrUpdateTenantModel
    export type UpdateTenantMutationError = ErrorType<ProblemDetails>

    export const useUpdateTenant = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenant>>, TError,{id: string;data: CreateOrUpdateTenantModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateTenantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes an existing tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.write
 */
export const deleteTenant = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/tenants/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTenantMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenant>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTenant>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTenant>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTenant(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTenantMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTenant>>>
    
    export type DeleteTenantMutationError = ErrorType<ProblemDetails>

    export const useDeleteTenant = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenant>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeleteTenantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the details of a tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getTenantDetail = (
    id: string,
    params?: GetTenantDetailParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetTenantDetailsByIdResponse>(
      {url: `/tenants/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTenantDetailQueryKey = (id: string,
    params?: GetTenantDetailParams,) => {
    return [`/tenants/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTenantDetailQueryOptions = <TData = Awaited<ReturnType<typeof getTenantDetail>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetTenantDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenantDetail>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantDetailQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantDetail>>> = ({ signal }) => getTenantDetail(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTenantDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTenantDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantDetail>>>
export type GetTenantDetailQueryError = ErrorType<ProblemDetails>

export const useGetTenantDetail = <TData = Awaited<ReturnType<typeof getTenantDetail>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTenantDetailParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenantDetail>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantDetailQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates the configuration of an existing tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.write
 */
export const updateTenantConfiguration = (
    id: string,
    tenantConfigurationModel: TenantConfigurationModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetTenantDetailsByIdResponse>(
      {url: `/tenants/${id}/configuration`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: tenantConfigurationModel
    },
      options);
    }
  


export const getUpdateTenantConfigurationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantConfiguration>>, TError,{id: string;data: TenantConfigurationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTenantConfiguration>>, TError,{id: string;data: TenantConfigurationModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTenantConfiguration>>, {id: string;data: TenantConfigurationModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTenantConfiguration(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateTenantConfigurationMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenantConfiguration>>>
    export type UpdateTenantConfigurationMutationBody = TenantConfigurationModel
    export type UpdateTenantConfigurationMutationError = ErrorType<ProblemDetails>

    export const useUpdateTenantConfiguration = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantConfiguration>>, TError,{id: string;data: TenantConfigurationModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateTenantConfigurationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Provides the user activity log for all users of a tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getTenantUsersActivityLog = (
    id: string,
    params?: GetTenantUsersActivityLogParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetUserActivityResponse>(
      {url: `/tenants/${id}/user-activity-log`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTenantUsersActivityLogQueryKey = (id: string,
    params?: GetTenantUsersActivityLogParams,) => {
    return [`/tenants/${id}/user-activity-log`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTenantUsersActivityLogQueryOptions = <TData = Awaited<ReturnType<typeof getTenantUsersActivityLog>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetTenantUsersActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenantUsersActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantUsersActivityLogQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantUsersActivityLog>>> = ({ signal }) => getTenantUsersActivityLog(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTenantUsersActivityLog>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTenantUsersActivityLogQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantUsersActivityLog>>>
export type GetTenantUsersActivityLogQueryError = ErrorType<ProblemDetails>

export const useGetTenantUsersActivityLog = <TData = Awaited<ReturnType<typeof getTenantUsersActivityLog>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetTenantUsersActivityLogParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTenantUsersActivityLog>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantUsersActivityLogQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of login providers for a given tenant<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getLoginProvidersByTenantId = (
    id: string,
    params?: GetLoginProvidersByTenantIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetLoginProvidersByTenantIdResponse>(
      {url: `/tenants/${id}/login-provider`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLoginProvidersByTenantIdQueryKey = (id: string,
    params?: GetLoginProvidersByTenantIdParams,) => {
    return [`/tenants/${id}/login-provider`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLoginProvidersByTenantIdQueryOptions = <TData = Awaited<ReturnType<typeof getLoginProvidersByTenantId>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetLoginProvidersByTenantIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoginProvidersByTenantId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLoginProvidersByTenantIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoginProvidersByTenantId>>> = ({ signal }) => getLoginProvidersByTenantId(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLoginProvidersByTenantId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLoginProvidersByTenantIdQueryResult = NonNullable<Awaited<ReturnType<typeof getLoginProvidersByTenantId>>>
export type GetLoginProvidersByTenantIdQueryError = ErrorType<ProblemDetails>

export const useGetLoginProvidersByTenantId = <TData = Awaited<ReturnType<typeof getLoginProvidersByTenantId>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetLoginProvidersByTenantIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoginProvidersByTenantId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLoginProvidersByTenantIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates a login provider<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.write
 */
export const updateLoginProvider = (
    id: string,
    updateLoginDomainModel: UpdateLoginDomainModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/tenants/${id}/login-provider`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateLoginDomainModel
    },
      options);
    }
  


export const getUpdateLoginProviderMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLoginProvider>>, TError,{id: string;data: UpdateLoginDomainModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateLoginProvider>>, TError,{id: string;data: UpdateLoginDomainModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLoginProvider>>, {id: string;data: UpdateLoginDomainModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateLoginProvider(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateLoginProviderMutationResult = NonNullable<Awaited<ReturnType<typeof updateLoginProvider>>>
    export type UpdateLoginProviderMutationBody = UpdateLoginDomainModel
    export type UpdateLoginProviderMutationError = ErrorType<ProblemDetails>

    export const useUpdateLoginProvider = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLoginProvider>>, TError,{id: string;data: UpdateLoginDomainModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getUpdateLoginProviderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the information of a login provider<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getLoginProviderById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetLoginProviderByIdResponse>(
      {url: `/tenants/login-provider/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetLoginProviderByIdQueryKey = (id: string,) => {
    return [`/tenants/login-provider/${id}`] as const;
    }

    
export const getGetLoginProviderByIdQueryOptions = <TData = Awaited<ReturnType<typeof getLoginProviderById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoginProviderById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLoginProviderByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoginProviderById>>> = ({ signal }) => getLoginProviderById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLoginProviderById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLoginProviderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getLoginProviderById>>>
export type GetLoginProviderByIdQueryError = ErrorType<ProblemDetails>

export const useGetLoginProviderById = <TData = Awaited<ReturnType<typeof getLoginProviderById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoginProviderById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLoginProviderByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Deletes a login provider<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.write
 */
export const deleteLoginProvider = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/tenants/login-provider/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteLoginProviderMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLoginProvider>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteLoginProvider>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLoginProvider>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteLoginProvider(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteLoginProviderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLoginProvider>>>
    
    export type DeleteLoginProviderMutationError = ErrorType<ProblemDetails>

    export const useDeleteLoginProvider = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLoginProvider>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getDeleteLoginProviderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a list of login providers<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/tenants.read
 */
export const getLoginProviders = (
    params?: GetLoginProvidersParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetAllLoginProvidersResponse>(
      {url: `/tenants/login-provider`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLoginProvidersQueryKey = (params?: GetLoginProvidersParams,) => {
    return [`/tenants/login-provider`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLoginProvidersQueryOptions = <TData = Awaited<ReturnType<typeof getLoginProviders>>, TError = ErrorType<ProblemDetails>>(params?: GetLoginProvidersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoginProviders>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLoginProvidersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoginProviders>>> = ({ signal }) => getLoginProviders(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLoginProviders>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLoginProvidersQueryResult = NonNullable<Awaited<ReturnType<typeof getLoginProviders>>>
export type GetLoginProvidersQueryError = ErrorType<ProblemDetails>

export const useGetLoginProviders = <TData = Awaited<ReturnType<typeof getLoginProviders>>, TError = ErrorType<ProblemDetails>>(
 params?: GetLoginProvidersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLoginProviders>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLoginProvidersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



