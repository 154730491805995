import { Badge, Flex } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { BasicUserFormType, DetailUserFormType, isDetailUser } from '../UserFormProvider';

type Props = {
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>;
};

export const UserBasicInformationRightSection = ({ form }: Props) => {
    const { t } = useTranslation();
    return (
        <Flex columnGap="md">
            {isDetailUser(form.values) && form.values.basicInformation.tenant && (
                <Badge>{`${t('tenant')}: ${form.values.basicInformation.tenant.name}`}</Badge>
            )}
            <Badge>{`${t('loginProviderType')}: ${form.values.basicInformation.loginProviderType}`}</Badge>
        </Flex>
    );
};
