import { Group, useMantineTheme, Burger, Stack, Image, Text, Flex, AppShell } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { BreadcrumbContext } from 'shared/components/baseLayout/BreadcrumbContext';
import idsLogo from './assets/IDSLogo.png';
import { Breadcrumbs } from './Breadcrumbs';
import { default as styles } from './Header.module.scss';
import { UserImage } from './UserImage';

type Props = {
    isNavBarOpened: boolean;
    onBurgerMenuClick: () => void;
    isNavBarVisible: boolean;
};

export const Header = ({ isNavBarOpened, onBurgerMenuClick: handleBurgerMenuClicked, isNavBarVisible }: Props) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const baseBreadcrumbRoute: BreadcrumbsRoute = {
        path: '/',
        breadcrumb: 'Home'
    };
    const breadcrumbRoutes = useContext(BreadcrumbContext);
    const breadcrumbs = useBreadcrumbs([baseBreadcrumbRoute, ...breadcrumbRoutes]);

    return (
        <AppShell.Header>
            <Stack gap="0" pl={16} pr={16}>
                <Group bg="gray.9" className={styles.header} justify="space-between">
                    <Group justify="flex-start">
                        {isNavBarVisible && (
                            <Flex hiddenFrom="sm" ta="center">
                                <Burger
                                    color="gray.6"
                                    mr={-5}
                                    opened={isNavBarOpened}
                                    size="sm"
                                    onClick={handleBurgerMenuClicked}
                                />
                            </Flex>
                        )}
                        <Link state={{ preventRedirect: true }} style={{ textDecoration: 'none' }} to="/">
                            <Group>
                                <Image height={30} src={idsLogo} width={30} />
                                <Text c={theme.white} size="xl">
                                    Identity Management
                                </Text>
                            </Group>
                        </Link>
                    </Group>
                    <UserImage />
                </Group>
                {breadcrumbRoutes.length > 0 && (
                    <Breadcrumbs breadcrumbs={breadcrumbs} maxElements={isMobile ? 2 : undefined} />
                )}
            </Stack>
        </AppShell.Header>
    );
};
