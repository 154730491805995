import { Group, Notification, Title } from '@mantine/core';
import { IconExclamationMark } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { DetailGroupModel } from 'api/v3/models';
import { TranslationKeys } from 'i18n';

type Props = {
    name: TranslationKeys;
    data?: DetailGroupModel;
};

export const GroupFormChildHeader = ({ name, data }: Props) => {
    const { t } = useTranslation();
    return (
        <Group justify="space-between">
            <Title order={4}>{t(name)}</Title>
            {data?.groupType === 'DynamicTenantGroup' && (
                <Notification color="orange" icon={<IconExclamationMark size="1.2rem" />} withCloseButton={false}>
                    {t('dynamicTenantGroupDescription')}
                </Notification>
            )}
        </Group>
    );
};
