import { IconReplace } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMoveApiResource } from 'api/v3/api-resources/api-resources';
import { BasicApiResourceModel } from 'api/v3/models';
import { ItemCardAction } from 'shared/components/ItemCardAction';
import { MoveApiModal } from './MoveApi';

type Props = {
    api: BasicApiResourceModel;
};

export const MoveApiAction = ({ api }: Props) => {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const { mutateAsync } = useMoveApiResource();

    const handleMoveApi = async (applicationId: string) => {
        await mutateAsync({ id: api.id, applicationId });
        setIsOpened(false);
    };

    return (
        <>
            {isOpened && (
                <MoveApiModal
                    api={api}
                    opened={isOpened}
                    onCancel={() => setIsOpened(false)}
                    onConfirm={handleMoveApi}
                />
            )}
            <ItemCardAction icon={<IconReplace />} title={t('moveApiDescription')} onClick={() => setIsOpened(true)} />
        </>
    );
};
