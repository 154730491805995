import { Grid } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
    getGetApplicationByIdQueryKey,
    getGetApplicationDetailsByIdQueryKey,
    useUpdateApplication,
    useUploadApplicationImage
} from 'api/v3/application/application';
import { PermissionProvider } from 'modules/shared/permissions';
import { DetailFormVariant } from 'shared/components/detailForm';
import { LoadingAnimation } from 'shared/components/LoadingAnimation';
import { ApplicationForm } from './ApplicationForm';
import { ApplicationFormValues } from './ApplicationFormProvider';
import { useApplication } from './ApplicationProvider';
import { getApplicationModel } from './mappings';

export const Application = () => {
    const { t } = useTranslation();
    const { application, permissions, applicationId } = useApplication();
    const { mutateAsync: updateApplicationAsync } = useUpdateApplication();
    const { mutateAsync: uploadImageAsync } = useUploadApplicationImage();
    const queryClient = useQueryClient();

    if (!application) {
        return <LoadingAnimation />;
    }

    const handleOnSubmit = async (savedApplication: ApplicationFormValues) => {
        const applicationToUpdate = getApplicationModel(savedApplication);
        await updateApplicationAsync({ id: applicationId, data: applicationToUpdate });

        if (savedApplication.application.imageUri && savedApplication.application.imageUri !== application.imageUri) {
            const file = await fetch(savedApplication.application.imageUri).then((response) => response.blob());
            await uploadImageAsync({ id: applicationId, data: { File: file } });
        }

        await queryClient.invalidateQueries({ queryKey: getGetApplicationByIdQueryKey(applicationId) });
        await queryClient.invalidateQueries({ queryKey: getGetApplicationDetailsByIdQueryKey(applicationId) });
    };

    return (
        <Grid>
            <Grid.Col span={{ lg: 8, md: 12 }}>
                <PermissionProvider context="Application" item={permissions}>
                    <ApplicationForm
                        application={application}
                        title={t('overview')}
                        variant={DetailFormVariant.EDIT}
                        onSubmit={handleOnSubmit}
                    />
                </PermissionProvider>
            </Grid.Col>
        </Grid>
    );
};
