import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useGetClientSecretsByClientId } from 'api/v3/client-secrets/client-secrets';
import { BasicClientSecretModel } from 'api/v3/models';
import { listQueryDefault } from 'app/queryDefaults';
import { Props } from 'modules/shared/multiPersonSelect/MultiPersonSelectInternal';
import { usePermissions } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { FieldDataType } from 'shared/components/extendedDataTable';
import { OverviewGrid } from 'shared/components/OverviewGrid';
import { timespanRegex } from 'shared/regex';
import { useClientFormContext } from '../ClientFormProvider';
import { AddClientSecretButton } from './AddClientSecretButton';
import { ClientSecretExpirationState } from './ClientSecretExpirationState';
import classes from './ClientSecretGrid.module.css';
import { DeleteClientSecretAction } from './DeleteClientSecretAction';

const getRowClass = (item: BasicClientSecretModel) => {
    if (item.expirationState === 'Expired') {
        return classes.rowExpired;
    }
    if (item.expirationState === 'NearExpired') {
        return classes.rowNearExpiration;
    }
};
export const ClientSecretGrid = (_: Props) => {
    const form = useClientFormContext();
    const { t } = useTranslation();
    const canReadSecret = usePermissions('ReadSecret');
    const canDeleteSecret = usePermissions('DeleteSecret');
    const canCreateSecret = usePermissions('CreateSecret');

    const {
        data: clientSecrets,
        error,
        isLoading: areClientSecretsLoading
    } = useGetClientSecretsByClientId(form.values.basicInformation.id ?? '', {
        query: {
            enabled:
                !!form.values.basicInformation.id && form.values.securitySettings.requireClientSecret && canReadSecret,
            ...listQueryDefault
        }
    });

    if (!form.values.basicInformation.id || !canReadSecret) {
        return null;
    }

    if (areClientSecretsLoading || !clientSecrets?.data) {
        return <LoadingAnimation />;
    }

    return (
        <OverviewGrid
            additionalRowActions={canDeleteSecret ? [DeleteClientSecretAction] : undefined}
            data={clientSecrets.data}
            error={error}
            fieldDescriptions={[
                {
                    header: '',
                    width: '50px',
                    data: 'expirationState',
                    render: (item) => <ClientSecretExpirationState expirationState={item.expirationState} />
                },
                {
                    header: t('lifetime'),
                    data: 'lifetime',
                    render: (item) => {
                        const match = item.lifetime?.match(timespanRegex);
                        if (match && match.groups && match.groups['Days']) {
                            return <Text>{`${match.groups['Days']} ${t('days')}`}</Text>;
                        }
                        return null;
                    }
                },
                { header: t('description'), data: 'description' },
                { header: t('creationDate'), data: 'created', dataType: FieldDataType.DateTime },
                { header: t('expirationDate'), data: 'expiration', dataType: FieldDataType.DateTime },
                {
                    header: t('value'),
                    data: 'cleartextValuePrefix',
                    render: (item) =>
                        item.cleartextValuePrefix && <Text>{`${item.cleartextValuePrefix}${'*'.repeat(10)}`}</Text>
                }
            ]}
            getRowClass={getRowClass}
            headerActions={canCreateSecret ? [<AddClientSecretButton key="AddClientSecret" />] : undefined}
        />
    );
};
