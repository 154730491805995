import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetApplications } from 'api/v3/application/application';
import { BasicApiResourceModel } from 'api/v3/models';
import { LoadingAnimation, MoveModal } from 'shared/components';

type Props = {
    api: BasicApiResourceModel;
    opened: boolean;
    onConfirm: (applicationId: string) => Promise<void>;
    onCancel: () => void;
};

export const MoveApiModal = ({ opened, api, onCancel, onConfirm }: Props) => {
    const { t } = useTranslation();
    const { data } = useGetApplications();
    const navigate = useNavigate();

    if (!data) {
        return <LoadingAnimation />;
    }

    const handleOnConfirm = async (selectedApplicationId: string) => {
        await onConfirm(selectedApplicationId);
        navigate(`/applications/${selectedApplicationId}/apis`);
    };

    const applications =
        data.data
            ?.filter((application) => application.data.id !== api.applicationId)
            .map((application) => ({
                value: application.data.id,
                label: application.data.displayName ?? application.data.id
            })) ?? [];

    return (
        <MoveModal
            data={applications}
            opened={opened}
            text={t('targetApplication')}
            title={t('moveApi')}
            onCancel={() => onCancel()}
            onConfirm={handleOnConfirm}
        />
    );
};
