import { Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { DetailFormChildProps, DetailFormVariant } from 'shared/components/detailForm';
import { Switch } from 'shared/components/inputs';
import { ExtendedTextarea, ExtendedTextInput } from 'shared/components/inputs/ExtendedInputControls';
import { useApiFormContext } from '../ApiFormProvider';

type Props = DetailFormChildProps;

export const BasicInformation = ({ variant, readOnly }: Props) => {
    const form = useApiFormContext();
    const { t } = useTranslation();

    return (
        <>
            <Group align="top" grow>
                <ExtendedTextInput
                    form={form}
                    label={t('name')}
                    placeholder={t('namePlaceholder')}
                    propertyPath="api.displayName"
                    readOnly={readOnly}
                />
                <ExtendedTextInput
                    form={form}
                    iconDescription={t('apiIdentifierDescription')}
                    label={t('identifier')}
                    placeholder={t('apiIdentifierPlaceholder')}
                    propertyPath="api.name"
                    readOnly={readOnly || variant === DetailFormVariant.EDIT}
                />
            </Group>
            <ExtendedTextarea
                form={form}
                label={t('description')}
                optional={t('optional')}
                propertyPath="api.description"
                readOnly={readOnly}
                rows={6}
            />
            <Switch
                description={t('includeGroupsInAccessTokenDescription')}
                disabled={readOnly}
                form={form}
                label={t('includeGroupsInAccessToken')}
                labelPosition="left"
                propertyPath="api.includeGroupsInToken"
            />
        </>
    );
};
