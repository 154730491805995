/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type TokenUsage = typeof TokenUsage[keyof typeof TokenUsage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TokenUsage = {
  ReUse: 'ReUse',
  OneTimeOnly: 'OneTimeOnly',
} as const;
