import { TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetClientActivityLog } from 'api/v3/clients/clients';
import { listQueryDefault } from 'app/queryDefaults';
import { ItemsPerPageType, Pagination, getPagingParams } from 'shared/components/pagination';
import { ActivityLogGrid } from './ActivityLogGrid';
import { addDays } from './helpers';

type Props = {
    clientId: string;
};

export const ClientActivityLogGrid = ({ clientId }: Props) => {
    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [username, setUsername] = useState('');
    const [debounceUsername] = useDebouncedValue(username, 500);
    const { t } = useTranslation();

    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const {
        data: activities,
        isLoading,
        error
    } = useGetClientActivityLog(
        clientId,
        {
            userNameFilter: debounceUsername,
            start: startDate?.toISOString(),
            end: endDate?.toISOString(),
            ...getPagingParams(page, itemsPerPage)
        },
        listQueryDefault
    );

    const totalPages = Math.ceil((activities?.metadata?.paging?.totalCount ?? 1) / itemsPerPage);

    const userFilter = <TextInput label={t('username')} value={username} w={180} onChange={(event) => setUsername(event.currentTarget.value)} />;

    return (
        <>
            <ActivityLogGrid
                activities={activities?.data}
                additionalFilters={userFilter}
                endDate={endDate}
                error={error}
                isLoading={isLoading}
                showClientColumn={false}
                startDate={startDate}
                onEndDateChange={setEndDate}
                onStartDateChange={setStartDate}
            />
            <Pagination
                disabled={isLoading}
                itemsPerPage={itemsPerPage}
                mb={32}
                page={page}
                totalPages={totalPages}
                withSkip
                onItemsPerPageChange={setItemsPerPage}
                onPageChange={setPage}
            />
        </>
    );
};
