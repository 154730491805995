import { CheckIcon, Combobox, ComboboxItem, isOptionsGroup } from '@mantine/core';

export interface OptionsGroup {
    group: string;
    items: ComboboxItem[];
}

export type OptionsData = (ComboboxItem | OptionsGroup)[];

interface OptionProps {
    data: ComboboxItem | OptionsGroup;
    withCheckIcon?: boolean;
    value?: string | string[] | null;
    checkIconPosition?: 'left' | 'right';
    unstyled: boolean | undefined;
}

function isValueChecked(value: string | string[] | undefined | null, optionValue: string) {
    return Array.isArray(value) ? value.includes(optionValue) : value === optionValue;
}

export const Option = ({ data, withCheckIcon, value, checkIconPosition, unstyled }: OptionProps) => {
    if (!isOptionsGroup(data)) {
        const check = withCheckIcon && isValueChecked(value, data.value) && <CheckIcon />;
        return (
            <Combobox.Option
                aria-selected={isValueChecked(value, data.value)}
                data-checked={isValueChecked(value, data.value) || undefined}
                data-reverse={checkIconPosition === 'right' || undefined}
                disabled={data.disabled}
                value={data.value}
            >
                {checkIconPosition === 'left' && check}
                {data.label}
                {checkIconPosition === 'right' && check}
            </Combobox.Option>
        );
    }

    const options = data.items.map((item) => (
        <Option
            key={item.value}
            checkIconPosition={checkIconPosition}
            data={item}
            unstyled={unstyled}
            value={value}
            withCheckIcon={withCheckIcon}
        />
    ));

    return <Combobox.Group label={data.group}>{options}</Combobox.Group>;
};
