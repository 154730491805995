import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useGetApiResourcesByApplicationId } from 'api/v3/application/application';
import { listQueryDefault } from 'app/queryDefaults';
import { useApplication } from 'modules/applications/application/ApplicationProvider';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { CreateItemModal } from 'shared/components';
import { FlexList } from 'shared/components/FlexList';
import { Placeholder } from 'shared/components/placeholder';
import { ApiCard } from './ApiCard';
import ApisEmpty from './assets/apis_empty.svg';
import { CreateApi } from './CreateApi';

const AddApiButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<IconPlus />} onClick={() => onClick()}>
            {t('createItem', { itemType: t('api') })}
        </Button>
    );
};

const NoApisPlaceholder = () => {
    const { t } = useTranslation();
    const canCreate = usePermissions('Create');
    return (
        <Placeholder
            action={
                canCreate && (
                    <CreateItemModal
                        AddButton={AddApiButton}
                        AddDialogType={CreateApi}
                        title={t('createItem', { itemType: t('api') })}
                    />
                )
            }
            description={canCreate && t('noApisDescription')}
            picture={ApisEmpty}
            text={t('noApis')}
        />
    );
};

export const Apis = () => {
    const { t } = useTranslation();
    const { applicationId } = useApplication();

    const {
        data: apis,
        isLoading,
        queryKey,
        error
    } = useGetApiResourcesByApplicationId(applicationId, { includePermissions: true }, listQueryDefault);
    const queryClient = useQueryClient();
    const canCreate = usePermissions('Create', 'ApiResource', apis);

    return (
        <PermissionProvider context="ApiResource" item={apis}>
            <FlexList
                AddDialogType={canCreate ? CreateApi : undefined}
                addDialogTitle={t('createItem', { itemType: t('api') })}
                error={error}
                header={t('api')}
                isLoading={isLoading}
                placeholder={<NoApisPlaceholder />}
            >
                {!!apis &&
                    !!apis.data &&
                    apis.data.map((api) => (
                        <PermissionProvider key={api.data?.id} context="ApiResource" item={api}>
                            <ApiCard item={api} onItemDelete={() => queryClient.invalidateQueries({ queryKey })} />
                        </PermissionProvider>
                    ))}
            </FlexList>
        </PermissionProvider>
    );
};
