import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router';

import { useGetApiResourceById } from 'api/v3/api-resources/api-resources';
import { LoadingAnimation } from 'shared/components';
import { ItemNotFound } from 'shared/components/ItemNotFound';

export const ApiRedirect = () => {
    const { t } = useTranslation();
    const { apiId = '' } = useParams();

    const { data: api, error, isLoading: isApiLoading } = useGetApiResourceById(apiId);

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="/" itemId={apiId} itemType={t('api')} />;
    }

    if (!api || isApiLoading) {
        return <LoadingAnimation />;
    }

    return <Navigate to={`/applications/${api.data.applicationId}/apis/${apiId}`} />;
};
