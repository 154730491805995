import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetUserById } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { EditBasicUser } from './EditBasicUser.tsx';
import { EditDetailUser } from './EditDetailUser';

export const User = () => {
    const { t } = useTranslation();
    const { userId = '' } = useParams();
    const { data: user, error } = useGetUserById(userId, { includePermissions: true });
    const canReadFull = usePermissions('ReadFull', 'User', user);

    if (error) {
        return (
            <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={userId} itemType={t('user')} />
        );
    }

    if (!user) {
        return <LoadingAnimation />;
    }

    return canReadFull ? <EditDetailUser /> : <EditBasicUser />;
};
