import { Loader, Overlay, Box, Stack, Text, StyleProp, useMantineTheme, Center } from '@mantine/core';
import { CSSProperties, ReactNode } from 'react';

import classes from './LoadingAnimation.module.css';

type LoadingAnimationProps = {
    children?: ReactNode;
    text?: string;
    showLoadingAnimation?: boolean;
    h?: StyleProp<CSSProperties['height']>;
};

export const LoadingAnimation = ({ children, text, showLoadingAnimation = true, h }: LoadingAnimationProps) => {
    const theme = useMantineTheme();

    if (showLoadingAnimation) {
        return (
            <Center h={h}>
                <Box className={classes.root}>
                    <Stack align="center">
                        <Loader style={{ zIndex: 201 }} />
                        {text && <Text c="Black">{text}</Text>}
                    </Stack>

                    <Overlay color={theme.white} opacity={0.75} />
                </Box>
            </Center>
        );
    }
    return <>{children}</>;
};
