import { useOidcUser } from '@axa-fr/react-oidc';
import { Stack, Title } from '@mantine/core';
import { t } from 'i18next';

import { useGetIdentityPermissionAssignments } from 'api/v3/role/role';
import { PermissionProvider, PermissionUtils } from 'modules/shared/permissions';
import { RoleAssignmentsGrid } from 'modules/shared/roleAssignments/RoleAssignmentsGrid';
import { LoadingAnimation } from 'shared/components';

export const RoleAssignments = () => {
    const { oidcUser } = useOidcUser();

    const { data } = useGetIdentityPermissionAssignments(
        {
            principalType: 'UserId',
            principalIdentifier: oidcUser?.sub,
            contextType: 'Global'
        },
        { query: { enabled: !!oidcUser } }
    );

    if (!data) {
        return <LoadingAnimation />;
    }

    const permissions = PermissionUtils.getPermittedItemFromPermissionResponse(data);

    return (
        <PermissionProvider context="IdentityRole" item={permissions}>
            <Stack>
                <Title order={2}>{t('globalRoleAssignments')}</Title>
                <RoleAssignmentsGrid contextType="Global" includeGlobalAssignments={true} includeInherited={false} />
            </Stack>
        </PermissionProvider>
    );
};
