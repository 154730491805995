import React from 'react';

import { NavLinksConfig, NavLinksContext } from './NavLinksContext';

type Props = {
    navLinks: NavLinksConfig[];
    children: React.ReactNode;
};

export const NavLinksProvider = ({ children, navLinks }: Props) => {
    return <NavLinksContext.Provider value={navLinks}>{children}</NavLinksContext.Provider>;
};
