import { Indicator, Text } from '@mantine/core';

import { UserState } from 'api/v3/models';
import { i18n } from 'i18n';

export const getIndicatorPropsByUserState = (userState: UserState): { text: string; color: string } => {
    switch (userState) {
        case UserState.InvitationPending:
            return { text: i18n.t('invitationPending'), color: 'cyan.5' };
        case UserState.InvitationExpired:
            return { text: i18n.t('invitationExpired'), color: 'grape.5' };
        case UserState.Activated:
            return { text: i18n.t('activated'), color: 'green.5' };
        case UserState.Deactivated:
            return { text: i18n.t('deactivated'), color: 'gray.5' };
        case UserState.Locked:
            return { text: i18n.t('locked'), color: 'orange.5' };
        case UserState.Deleted:
            return { text: i18n.t('deleted'), color: 'red.5' };
    }
};

type Props = {
    userState: UserState;
};

export const UserStateIndicator = ({ userState }: Props) => {
    const indicatorProps = getIndicatorPropsByUserState(userState);

    return (
        <Indicator color={indicatorProps.color} offset={8} position="middle-start" size={14} zIndex={0}>
            <Text ml={25}>{indicatorProps.text}</Text>
        </Indicator>
    );
};
