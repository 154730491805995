import { Image, FileButton, ActionIcon } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconPencil, IconX } from '@tabler/icons-react';

import { LoadingAnimation } from 'shared/components';

type ImageFileButtonProps = {
    width: string | number;
    height: string | number;
    imageSrc?: string | null;
    isLoading?: boolean;
    readOnly?: boolean;
    onImageSelected?: (file: File) => void;
    onDeleteImage?: () => void;
};

export const ImageFileButton = ({
    width,
    height,
    imageSrc,
    isLoading = false,
    readOnly = false,
    onImageSelected,
    onDeleteImage
}: ImageFileButtonProps) => {
    const { hovered, ref } = useHover();

    return (
        <div
            ref={ref}
            style={{
                position: 'relative',
                borderColor: 'lightgray',
                borderWidth: 0.1,
                height,
                width,
                overflow: 'hidden',
                borderStyle: 'solid'
            }}
        >
            {!readOnly && onImageSelected && (
                <FileButton accept="image/png,image/jpeg" onChange={(file) => file && onImageSelected(file)}>
                    {(props) =>
                        hovered && (
                            <ActionIcon
                                {...props}
                                bg="gray.8"
                                color="white"
                                h={height}
                                loading={isLoading}
                                opacity={0.8}
                                pos="absolute"
                                style={{
                                    zIndex: 2
                                }}
                                w={width}
                            >
                                <IconPencil size={50} />
                            </ActionIcon>
                        )
                    }
                </FileButton>
            )}
            {hovered && !readOnly && onDeleteImage && imageSrc && (
                <ActionIcon
                    color="white"
                    pos="absolute"
                    right={0}
                    style={{
                        zIndex: 3
                    }}
                    onClick={onDeleteImage}
                >
                    <IconX />
                </ActionIcon>
            )}
            {isLoading && <LoadingAnimation />}

            {!isLoading && (
                <Image
                    alt="image"
                    fit="cover"
                    h={height}
                    pos="absolute"
                    src={imageSrc ? imageSrc : `https://placehold.co/${width}x${height}?text=1920+x+1080`}
                    w={width}
                />
            )}
        </div>
    );
};
