import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAddRemoveGroupMembers } from 'api/v3/groups/groups';
import { useAssignRevokeApiResourceRoles } from 'api/v3/role/role';
import { useGetUserDetailsById, useUpdateUser } from 'api/v3/user/user';
import { PermissionProvider } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { DetailFormVariant } from 'shared/components/detailForm';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { UserDetailForm } from './UserDetailForm';
import { DetailUserFormType } from './UserFormProvider';

export const EditDetailUser = () => {
    const { userId = '' } = useParams();
    const { t } = useTranslation();

    const { data: userApiResponse, isLoading: isUserLoading, error } = useGetUserDetailsById(userId, { includePermissions: true });

    const { mutateAsync: assignRevokeRolesAsync } = useAssignRevokeApiResourceRoles();
    const { mutateAsync: addRemoveGroupMembersAsync } = useAddRemoveGroupMembers();
    const { mutateAsync: updateUserAsync } = useUpdateUser();

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={userId} itemType={t('user')} />;
    }

    const handleSubmit = async (user: DetailUserFormType) => {
        await updateUserAsync({ id: userId, data: user.basicInformation });
        const roleActions = user.roles?.filter((currentRole) => currentRole.isRemoved || !currentRole.id);

        if (roleActions) {
            for (const action of roleActions) {
                await assignRevokeRolesAsync({
                    roleId: action.roleId,
                    data: {
                        roleAssigneeActions: [
                            {
                                assignee: { identifier: userId, type: 'UserId' },
                                assignmentAction: action.isRemoved ? 'Revoke' : 'Assign'
                            }
                        ]
                    }
                });
            }
        }

        const groupActions = user.groups?.filter((currentRole) => currentRole.isRemoved || !currentRole.id);

        if (groupActions) {
            for (const action of groupActions) {
                await addRemoveGroupMembersAsync({
                    id: action.groupId,
                    data: {
                        memberAssigneeActions: [
                            {
                                assignee: { identifier: userId, type: 'UserId' },
                                assignmentAction: action.isRemoved ? 'Revoke' : 'Assign'
                            }
                        ]
                    }
                });
            }
        }
    };

    if (!userApiResponse || isUserLoading) {
        return <LoadingAnimation />;
    }
    return (
        <PermissionProvider context="User" item={userApiResponse}>
            <UserDetailForm user={userApiResponse.data} variant={DetailFormVariant.EDIT} onSubmit={handleSubmit} />
        </PermissionProvider>
    );
};
