import { Group } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { RoleAssignmentContextType } from 'api/v3/models';
import { useGetAllIdentityRoles } from 'api/v3/role/role';
import { LoadingAnimation } from 'shared/components';
import { Select } from 'shared/components/inputs';
import { PrincipalSelector } from '../PrincipalSelector';
import { RoleAssignmentsFormType } from './RoleAssignmentsFormProvider';
import { handlePrincipalSelected } from './RoleAssignmentsGrid';

type Props = {
    form: UseFormReturnType<RoleAssignmentsFormType>;
    contextType?: RoleAssignmentContextType;
    contextId?: string;
    index: number;
};

export const NewRoleAssignment = ({ form, index }: Props) => {
    const { data: roles } = useGetAllIdentityRoles();
    const { t } = useTranslation();

    if (!roles) {
        return <LoadingAnimation />;
    }

    const handleOnChange = (itemId: string | null) => {
        const selectedRole = roles.data.find((role) => role.data.id === itemId);
        form.setFieldValue(`roleAssignments.${index}.role.roleId`, selectedRole?.data.id);
        form.setFieldValue(`roleAssignments.${index}.role.roleIdentifier`, selectedRole?.data.roleIdentifier);
        form.setFieldValue(`roleAssignments.${index}.role.roleDisplayName`, selectedRole?.data.displayName);
    };

    return (
        <Group>
            <PrincipalSelector
                form={form}
                label={t('principal')}
                propertyPath={`roleAssignments.${index}.principalId`}
                autoFocus
                onPrincipalSelected={(principal) => {
                    handlePrincipalSelected(form, index, principal);
                }}
            />
            <Select
                data={roles.data.map((role) => ({
                    label: role.data.displayName ?? role.data.id,
                    value: role.data.id
                }))}
                form={form}
                label={t('role')}
                propertyPath={`roleAssignments.${index}.role.roleId`}
                onChange={handleOnChange}
            />
        </Group>
    );
};
