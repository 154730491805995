import { Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { BasicApiResourceRoleModel } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { RoleSelector } from 'modules/shared/RoleSelector';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';
import {
    GroupDataGridRoleAssignmentsType,
    GroupFormRoleAssignmentsType,
    GroupFormType,
    useGroupFormContext
} from '../GroupFormProvider';

type Props = DetailFormChildProps;

const generateNewRoleAssignment = (): GroupDataGridRoleAssignmentsType => ({
    roleId: '',
    name: '',
    key: randomId(),
    isEditable: true,
    isRemoved: false
});

const AddRole = () => {
    const form = useGroupFormContext();
    const { t } = useTranslation();
    return (
        <Button leftSection={<IconPlus />} onClick={() => form.insertListItem('roles', generateNewRoleAssignment())}>
            {t('addItem', { itemType: t('role') })}
        </Button>
    );
};

function getGridRoleAssignments(data: GroupFormRoleAssignmentsType[]): GroupDataGridRoleAssignmentsType[] {
    return data.map((s) => ({
        key: randomId(),
        isEditable: false,
        isRemoved: false,
        ...s
    }));
}

const handleRoleSelected = (form: UseFormReturnType<GroupFormType>, role: BasicApiResourceRoleModel, index: number) => {
    form.setFieldValue(`roles.${index}.isEditable`, false);
    form.setFieldValue(`roles.${index}.roleId`, role.id);
    form.setFieldValue(`roles.${index}.name`, role.name);
    form.setFieldValue(`roles.${index}.displayName`, role.displayName);
};

export const Roles = (_: Props) => {
    const { t } = useTranslation();
    const form = useGroupFormContext();
    const canAssignRoles = usePermissions('AssignRole');

    return (
        <ExtendedDataTable
            canCreate={canAssignRoles}
            canEdit={canAssignRoles}
            canRemove={canAssignRoles}
            data={getGridRoleAssignments(form.values.roles)}
            fieldDescriptions={[
                { header: t('name'), data: 'name', lockUpdate: true },
                {
                    header: t('displayName'),
                    data: 'displayName',
                    lockUpdate: true
                }
            ]}
            form={form}
            generateNewItem={generateNewRoleAssignment}
            maxHeight={300}
            placeholder={<Placeholder action={canAssignRoles && <AddRole />} text={t('noAssignedRoles')} />}
            propertyPathBase="roles"
            renderEdit={(_item, index, onItemSaved) => {
                return (
                    <RoleSelector
                        form={form}
                        index={index}
                        propertyBasePath="roles"
                        onRoleSelected={(role) => {
                            handleRoleSelected(form, role, index);
                            onItemSaved && onItemSaved();
                        }}
                    />
                );
            }}
        />
    );
};
