import { useNavigate } from 'react-router-dom';

import {
    useCreateApiResourceRole,
    useCreateApiResourceScope,
    useCreateApiResource
} from 'api/v3/api-resources/api-resources';
import {
    BasicApiResourceRoleModelApiResponse,
    BasicApiResourceScopeModelApiResponse,
    DetailApiResourceModel
} from 'api/v3/models';
import { DetailFormVariant } from 'shared/components/detailForm';
import { AddDialogProps } from 'shared/components/FlexList';
import { useApplication } from '../application/ApplicationProvider';
import { ApiForm } from './ApiForm';
import { ApiFormValues } from './ApiFormProvider';

type Props = {
    initialValues?: {
        api: DetailApiResourceModel;
        scopes: BasicApiResourceScopeModelApiResponse[];
        roles: BasicApiResourceRoleModelApiResponse[];
    };
} & AddDialogProps;

export const CreateApi = ({ initialValues }: Props) => {
    const navigate = useNavigate();
    const { applicationId } = useApplication();

    const api: DetailApiResourceModel = {
        id: '',
        name: '',
        displayName: '',
        description: '',
        enabled: true,
        includeGroupsInToken: false,
        applicationId
    };

    const { mutateAsync: createAsync } = useCreateApiResource();
    const { mutateAsync: createScopeAsync } = useCreateApiResourceScope();
    const { mutateAsync: createRolesAsync } = useCreateApiResourceRole();

    const handleCreate = async (formValues: ApiFormValues) => {
        const newApi = await createAsync({ data: formValues.api });

        if (formValues.scopes) {
            const promises = formValues.scopes.map((element) =>
                createScopeAsync({ id: newApi.data.id, data: element })
            );
            await Promise.all(promises);
        }

        if (formValues.roles) {
            const promises = formValues.roles.map((element) => createRolesAsync({ id: newApi.data.id, data: element }));
            await Promise.all(promises);
        }

        navigate(`${newApi.data.id}`);
    };

    return (
        <ApiForm
            api={initialValues?.api ?? api}
            roles={initialValues?.roles ?? []}
            scopes={initialValues?.scopes ?? []}
            variant={DetailFormVariant.CREATE}
            onSubmit={handleCreate}
        />
    );
};
