import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
    getGetClientByIdQueryKey,
    useDeleteClientBackgroundImage,
    useGetClientDetailById,
    useUpdateClient,
    useUploadClientBackgroundImage,
    useUploadClientPicture
} from 'api/v3/clients/clients';
import { GetClientDetailsByIdResponse } from 'api/v3/models';
import { PermissionProvider } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { DetailFormVariant } from 'shared/components/detailForm';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { ClientForm } from './ClientForm';
import { ClientFormValues } from './ClientFormProvider';
import { getUpdateClientModel } from './mappings';

interface ClientProps {
    onClientChanged?: (client: GetClientDetailsByIdResponse) => void;
}

export const EditClient = ({ onClientChanged }: ClientProps) => {
    const { clientId = '' } = useParams();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { mutateAsync: updateClientAsync } = useUpdateClient();
    const { mutateAsync: uploadClientPicture } = useUploadClientPicture();
    const { mutateAsync: uploadClientBackgroundImage } = useUploadClientBackgroundImage();
    const { mutateAsync: deleteClientBackgroundImage } = useDeleteClientBackgroundImage();

    const {
        data: client,
        isLoading: isClientLoading,
        error,
        queryKey
    } = useGetClientDetailById(clientId, { includePermissions: true });

    if (error) {
        return (
            <ItemNotFound
                errorStatus={error.response?.status}
                goBackRoute="../"
                itemId={clientId}
                itemType={t('client')}
            />
        );
    }

    if (!client || isClientLoading) {
        return <LoadingAnimation />;
    }

    if (onClientChanged && client) {
        onClientChanged(client);
    }

    const handleSubmit = async (clientFormValues: ClientFormValues) => {
        const clientToUpdate = getUpdateClientModel(clientFormValues);
        await updateClientAsync({ id: clientId, data: clientToUpdate });

        if (
            clientToUpdate.basicInformation.clientPictureUri &&
            clientToUpdate.basicInformation.clientPictureUri !== client.data.basicInformation.clientPictureUri
        ) {
            const file = await fetch(clientToUpdate.basicInformation.clientPictureUri).then((response) =>
                response.blob()
            );
            await uploadClientPicture({ id: clientId, data: { File: file } });
        }

        if (
            clientFormValues.basicInformation.clientBackgroundUri !== client.data.basicInformation.clientBackgroundUri
        ) {
            if (clientFormValues.basicInformation.clientBackgroundUri) {
                const file = await fetch(clientFormValues.basicInformation.clientBackgroundUri).then((response) =>
                    response.blob()
                );
                await uploadClientBackgroundImage({ id: clientId, data: { File: file } });
            } else {
                await deleteClientBackgroundImage({ id: clientId });
            }
        }

        await queryClient.invalidateQueries({ queryKey });
        await queryClient.invalidateQueries({ queryKey: getGetClientByIdQueryKey(clientId) });
    };

    return (
        <PermissionProvider context="Client" item={client}>
            <ClientForm client={client?.data} variant={DetailFormVariant.EDIT} onSubmit={handleSubmit} />
        </PermissionProvider>
    );
};
