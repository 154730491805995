import { Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

type Props = {
    description: string;
};

export const IconDescription = ({ description }: Props) => {
    return (
        <Tooltip label={description}>
            <IconInfoCircle color="var(--mantine-color-gray-5" size={18} style={{ cursor: 'pointer' }} />
        </Tooltip>
    );
};
