import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetApiResourceById } from 'api/v3/api-resources/api-resources';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { BasicApi } from './BasicApi';
import { EditApi } from './EditApi';

export const Api = () => {
    const { t } = useTranslation();
    const { apiId = '' } = useParams();
    const { data: api, error } = useGetApiResourceById(apiId, { includePermissions: true });
    const canReadFull = usePermissions('ReadFull', 'ApiResource', api);

    if (error) {
        return (
            <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={apiId} itemType={t('api')} />
        );
    }

    if (!api) {
        return <LoadingAnimation />;
    }

    return (
        <PermissionProvider context="ApiResource" item={api}>
            {canReadFull ? <EditApi /> : <BasicApi api={api.data} />}
        </PermissionProvider>
    );
};
