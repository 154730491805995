import { Text, ScrollArea, HoverCard, List } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAllGroupMembers } from 'api/v3/groups/groups';
import { LoadingAnimation } from 'shared/components';
import { RoleAssignmentsFormRowType } from './RoleAssignmentsFormProvider';

type Props = {
    roleAssignment: RoleAssignmentsFormRowType;
};

export const GroupNameColumn = ({ roleAssignment }: Props) => {
    const [isOpened, setIsOpened] = useState(false);
    const { data: groupMembers } = useGetAllGroupMembers(
        roleAssignment.principal.id,
        {},
        {
            query: { enabled: isOpened }
        }
    );
    const { t } = useTranslation();

    const permissionDescription =
        groupMembers && groupMembers.data ? (
            groupMembers.data.map((groupMember) => (
                <List.Item key={groupMember.id}>{groupMember.displayName}</List.Item>
            ))
        ) : (
            <LoadingAnimation />
        );

    if (!roleAssignment.principal.displayName) {
        return <Text>{roleAssignment.principal.id}</Text>;
    }

    return (
        <HoverCard
            arrowSize={10}
            position="bottom"
            shadow="lg"
            withArrow
            withinPortal
            onClose={() => setIsOpened(false)}
            onOpen={() => setIsOpened(true)}
        >
            <HoverCard.Target>
                <Text td="underline">{roleAssignment.principal.displayName ?? roleAssignment.principal.id}</Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                <ScrollArea.Autosize mah={500}>
                    <Text>{t('groupMembers')}</Text>
                    <List>{permissionDescription}</List>
                </ScrollArea.Autosize>
            </HoverCard.Dropdown>
        </HoverCard>
    );
};
