import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { TenantLoginProviderType } from 'api/v3/models';
import {
    getGetLoginProvidersByTenantIdQueryKey,
    useGetLoginProvidersByTenantId,
    useGetTenantDetail,
    useUpdateTenantConfiguration,
    useUpdateTenant,
    getGetTenantByIdQueryKey,
    useUpdateLoginProvider
} from 'api/v3/tenant/tenant';
import { PermissionProvider } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { DetailFormVariant } from 'shared/components/detailForm';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { getTenantConfigurationModel, getTenantModel } from './mappings';
import { TenantForm } from './TenantForm';
import { TenantFormType } from './TenantFormProvider';

export const EditTenant = () => {
    const { tenantId = '' } = useParams();
    const { t } = useTranslation();
    const { mutateAsync: updateTenantAsync } = useUpdateTenant();
    const { mutateAsync: updateLoginDomains } = useUpdateLoginProvider();
    const { mutateAsync: updateTenantConfiguration } = useUpdateTenantConfiguration();
    const queryClient = useQueryClient();

    const {
        data: tenant,
        isLoading: isTenantLoading,
        error,
        queryKey
    } = useGetTenantDetail(tenantId, { includePermissions: true });
    const { data: loginProviders, isLoading: isLoginProvidersLoading } = useGetLoginProvidersByTenantId(tenantId);

    if (error) {
        return (
            <ItemNotFound
                errorStatus={error.response?.status}
                goBackRoute="../"
                itemId={tenantId}
                itemType={t('tenant')}
            />
        );
    }

    if (!tenant || isTenantLoading || !loginProviders || isLoginProvidersLoading) {
        return <LoadingAnimation />;
    }

    const localLoginDomains = loginProviders
        ? loginProviders.data
              .filter((loginProvider) => loginProvider.type === TenantLoginProviderType.Local)
              .map((localLoginProvider) => localLoginProvider.loginDomains)
              .flat()
              .map((localLoginProvider) => localLoginProvider.replace('.grpid', ''))
        : [];

    const handleSubmit = async (tenantFormValues: TenantFormType) => {
        const tenantToUpdate = getTenantModel(tenantFormValues);
        const localLoginDomainsToUpdate = tenantFormValues.localLoginDomains.map(
            (localLoginDomain) => `${localLoginDomain}.grpid`
        );
        const tenantConfigurationToUpdate = getTenantConfigurationModel(tenantFormValues);

        await updateTenantAsync({ id: tenantId, data: tenantToUpdate });

        await updateLoginDomains({
            id: tenantId,
            data: { loginProviderType: TenantLoginProviderType.Local, loginDomains: localLoginDomainsToUpdate }
        });
        await updateTenantConfiguration({ id: tenantId, data: tenantConfigurationToUpdate });

        await queryClient.invalidateQueries({ queryKey });
        await queryClient.invalidateQueries({ queryKey: getGetTenantByIdQueryKey(tenantId) });
        await queryClient.invalidateQueries({ queryKey: getGetLoginProvidersByTenantIdQueryKey(tenantId) });
    };

    return (
        <PermissionProvider context="Tenant" item={tenant}>
            <TenantForm
                localLoginDomains={localLoginDomains}
                tenant={tenant}
                variant={DetailFormVariant.EDIT}
                onSubmit={handleSubmit}
            />
        </PermissionProvider>
    );
};
