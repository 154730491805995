import { Title, Container, Center } from '@mantine/core';
import React from 'react';

import { BaseLayout } from 'shared/components/baseLayout';
import classes from './ErrorView.module.css';

type Props = {
    title: string;
    message: string;
    children?: React.ReactElement;
};

export const ErrorView = ({ title, message, children }: Props) => {
    return (
        <BaseLayout showNavBar={false}>
            <Container className={classes.root}>
                <div className={classes.label}>{title}</div>
                <Title className={classes.title}>{message}</Title>
                <Center pt="sm">{children}</Center>
            </Container>
        </BaseLayout>
    );
};
