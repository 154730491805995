import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetUserRoles, useGetUserById, useGetUserGroups } from 'api/v3/user/user';
import { PermissionProvider, PermissionUtils } from 'modules/shared/permissions';
import { LoadingAnimation } from 'shared/components';
import { DetailFormVariant } from 'shared/components/detailForm';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { UserBasicForm } from './UserBasicForm';

export const EditBasicUser = () => {
    const { userId = '' } = useParams();
    const { t } = useTranslation();

    const { data: userApiResponse, isLoading: isUserLoading, error } = useGetUserById(userId, { includePermissions: true });

    const canAssignRoles = PermissionUtils.hasPermission('User', 'AssignRole', userApiResponse);
    const { data: userRoles, isLoading: userRolesLoading } = useGetUserRoles(userId, {
        query: { enabled: canAssignRoles }
    });
    const { data: userGroups, isLoading: userGroupsLoading } = useGetUserGroups(userId, {});

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={userId} itemType={t('user')} />;
    }

    if (!userApiResponse || isUserLoading || userGroupsLoading || (canAssignRoles && userRolesLoading)) {
        return <LoadingAnimation />;
    }
    return (
        <PermissionProvider context="User" item={userApiResponse}>
            <UserBasicForm groups={userGroups?.data} roles={userRoles?.data} user={userApiResponse.data} variant={DetailFormVariant.EDIT} />
        </PermissionProvider>
    );
};
