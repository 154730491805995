import { AppShell, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode, useContext } from 'react';

import classes from './BaseLayout.module.css';
import { BreadcrumbContext } from './BreadcrumbContext';
import { Header } from './Header';
import { NavBar } from './NavBar';

type BaseLayoutProps = {
    showNavBar?: boolean;
    children: ReactNode;
};

export const BaseLayout = ({ children, showNavBar = true }: BaseLayoutProps) => {
    const [isMobileNavbarOpened, { toggle: toggleMobileNavbar, close: closeMobileNavbar }] = useDisclosure(false);

    const handleBurgerMenuClick = () => {
        toggleMobileNavbar();
    };

    const handleNavLinkClicked = () => {
        closeMobileNavbar();
    };

    const breadcrumbRoutes = useContext(BreadcrumbContext);
    const showBreadcrumbs = breadcrumbRoutes.length > 0;
    const colorScheme = useMantineTheme();

    return (
        <AppShell
            header={{ height: showBreadcrumbs ? 80 : 50 }}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !isMobileNavbarOpened, desktop: !showNavBar }
            }}
            padding="md"
            styles={{
                main: {
                    backgroundColor: colorScheme.colors.gray[0]
                }
            }}
        >
            <AppShell.Header>
                <Header
                    isNavBarOpened={isMobileNavbarOpened}
                    isNavBarVisible={showNavBar}
                    onBurgerMenuClick={handleBurgerMenuClick}
                />
            </AppShell.Header>
            {showNavBar && (
                <AppShell.Navbar p="md" w={{ sm: 300 }}>
                    <NavBar onNavLinkClicked={handleNavLinkClicked} />
                </AppShell.Navbar>
            )}
            <AppShell.Main className={classes.main}>{children}</AppShell.Main>
        </AppShell>
    );
};
