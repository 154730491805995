import { Title, Stack, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetTenantById } from 'api/v3/tenant/tenant';
import { Users } from 'modules/userManagement/users/Users';
import { LoadingAnimation } from 'shared/components';
import { ItemNotFound } from 'shared/components/ItemNotFound';

export const ViewTenant = () => {
    const { tenantId = '' } = useParams();
    const { t } = useTranslation();
    const { data: tenant, isLoading: isTenantLoading, error } = useGetTenantById(tenantId);

    if (error) {
        return (
            <ItemNotFound
                errorStatus={error.response?.status}
                goBackRoute="../"
                itemId={tenantId}
                itemType={t('tenant')}
            />
        );
    }

    if (!tenant || isTenantLoading) {
        return <LoadingAnimation />;
    }

    return (
        <Stack>
            <Title order={4}>{t('basicInformation')}</Title>
            <TextInput label={t('name')} placeholder={t('namePlaceholder')} value={tenant.data.name} readOnly />
            <Title order={4}>{t('users')}</Title>
            <Users label={t('users')} name="users" tenant={tenant} />
        </Stack>
    );
};
