import { Flex, Stack, Tooltip, Title, Text, Center } from '@mantine/core';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientFormContext } from 'modules/applications/client/ClientFormProvider';
import { usePermissions } from 'modules/shared/permissions';
import { ImageFileButton } from 'shared/components/buttons';
import { CopyToClipboard } from 'shared/components/CopyToClipboard';
import { DetailFormChildProps, DetailFormVariant } from 'shared/components/detailForm';
import { ExtendedTextarea, ExtendedTextInput } from 'shared/components/inputs';
import { Switch } from 'shared/components/inputs/Switch';
import { errorNotification } from 'shared/notifications';

type Props = DetailFormChildProps;

const grantTypes = ['authorizationCode', 'clientCredentials', 'tokenExchange'];

export const BasicInformation = ({ variant }: Props) => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);
    const canUploadBackgroundImage = usePermissions('UploadClientBackground');

    const handleImageSelected = (file?: File) => {
        const url = file ? window.URL.createObjectURL(file) : undefined;

        if (url) {
            form.setFieldValue('basicInformation.clientPictureUri', url);
        }
    };

    const handleGrantTypeChange = (value: ChangeEvent<HTMLInputElement>, selectedGrantType: string) => {
        const checked = value?.currentTarget?.checked;

        form.setFieldValue(`basicInformation.allowedGrantTypes.${selectedGrantType}`, checked);

        if (value?.currentTarget?.checked) {
            grantTypes
                .filter((grantType) => selectedGrantType !== grantType)
                .forEach((grantType) => {
                    form.setFieldValue(`basicInformation.allowedGrantTypes.${grantType}`, false);
                });
        }

        return checked;
    };

    const handleClientCredentialsChange = (value: ChangeEvent<HTMLInputElement>) => {
        const checked = handleGrantTypeChange(value, 'clientCredentials');

        if (checked && !form.values.securitySettings.requireClientSecret) {
            form.setFieldValue('securitySettings.requireClientSecret', true);
        }
    };
    const handleBackgroundImageSelected = async (file: File) => {
        if (file.size < 1048576) {
            const url = file ? window.URL.createObjectURL(file) : undefined;
            if (url) {
                const image = new Image();
                image.src = url;
                await image.decode();
                if (image.width < 1920 || image.height < 1080) {
                    errorNotification({ message: t('backgroundImageResolution') });
                    return false;
                } else {
                    form.setFieldValue('basicInformation.clientBackgroundUri', url);
                }
            }
        } else {
            errorNotification({ message: t('backgroundImageFileSize') });
        }
    };

    return (
        <Stack>
            <Flex align="center" gap="md">
                <Stack style={{ flex: 1 }}>
                    <Flex align="end">
                        <ExtendedTextInput
                            form={form}
                            label={t('name')}
                            placeholder={t('namePlaceholder')}
                            propertyPath="basicInformation.name"
                            readOnly={!canUpdateOrCreate}
                            style={{ flex: '1' }}
                        />
                        {variant === DetailFormVariant.EDIT && (
                            <Tooltip label={t('copyToClipboard')}>
                                <CopyToClipboard m={4} textToCopy={form.values.basicInformation.name ?? ''} />
                            </Tooltip>
                        )}
                    </Flex>
                    {variant === DetailFormVariant.EDIT && (
                        <Flex align="end">
                            <ExtendedTextInput
                                form={form}
                                label={t('clientId')}
                                propertyPath="basicInformation.id"
                                style={{ flex: '1' }}
                                readOnly
                            />
                            <Tooltip label={t('copyToClipboard')}>
                                <CopyToClipboard m={4} textToCopy={form.values.basicInformation.id ?? ''} />
                            </Tooltip>
                        </Flex>
                    )}
                </Stack>

                <ImageFileButton
                    height={180}
                    imageSrc={form.values.basicInformation.clientPictureUri}
                    readOnly={!canUpdateOrCreate}
                    width={180}
                    onImageSelected={handleImageSelected}
                />
            </Flex>
            <ExtendedTextarea
                form={form}
                label={t('description')}
                maxRows={4}
                minRows={4}
                optional={t('optional')}
                placeholder={t('descriptionPlaceholder')}
                propertyPath="basicInformation.description"
                readOnly={!canUpdateOrCreate}
            />
            <Switch
                disabled={!canUpdateOrCreate}
                form={form}
                iconDescription={t('enableClientDescription')}
                label={t('enableClient')}
                labelPosition="left"
                propertyPath="basicInformation.enabled"
            />
            <Title order={4}>{t('grantTypes')}</Title>
            <Text>{t('grantTypesDescription')}</Text>
            <Switch
                disabled={!canUpdateOrCreate}
                form={form}
                iconDescription={t('authorizationCodeDescription')}
                label={t('authorizationCode')}
                labelPosition="left"
                propertyPath="basicInformation.allowedGrantTypes.authorizationCode"
                onChange={(value) => handleGrantTypeChange(value, 'authorizationCode')}
            />
            <Switch
                disabled={!canUpdateOrCreate}
                form={form}
                iconDescription={t('clientCredentialsDescription')}
                label={t('clientCredentials')}
                labelPosition="left"
                propertyPath="basicInformation.allowedGrantTypes.clientCredentials"
                onChange={(value) => handleClientCredentialsChange(value)}
            />
            <Switch
                disabled={!canUpdateOrCreate}
                form={form}
                iconDescription={t('tokenExchangeDescription')}
                label={t('tokenExchange')}
                labelPosition="left"
                propertyPath="basicInformation.allowedGrantTypes.tokenExchange"
                onChange={(value) => handleGrantTypeChange(value, 'tokenExchange')}
            />
            {canUploadBackgroundImage && (
                <>
                    <Title order={4}>{t('backgroundImage')}</Title>
                    <Text>{t('backgroundImageDescription')}</Text>
                    <ul style={{ margin: 0 }}>
                        <li>
                            <Flex gap={6}>
                                <Text fw="bold" span>
                                    {t('approval')}:
                                </Text>
                                <Text style={{ flex: 1 }}>{t('backgroundImageApproval')}</Text>
                            </Flex>
                        </li>
                        <li>
                            <Flex gap={6}>
                                <Text fw="bold" span>
                                    {t('resolution')}:
                                </Text>
                                <Text style={{ flex: 1 }}>{t('backgroundImageResolution')}</Text>
                            </Flex>
                        </li>
                        <li>
                            <Flex gap={6}>
                                <Text fw="bold" span>
                                    {t('fileSize')}:
                                </Text>
                                <Text style={{ flex: 1 }}>{t('backgroundImageFileSize')}</Text>
                            </Flex>
                        </li>
                        <li>
                            <Flex gap={6}>
                                <Text fw="bold" span>
                                    {t('aspectRatio')}:
                                </Text>
                                <Text style={{ flex: 1 }}>{t('backgroundImageAspectRatio')}</Text>
                            </Flex>
                        </li>
                        <li>
                            <Flex gap={6}>
                                <Text fw="bold" span>
                                    {t('content')}:
                                </Text>
                                <Text style={{ flex: 1 }}>{t('backgroundImageContent')}</Text>
                            </Flex>
                        </li>
                        <li>
                            <Flex gap={6}>
                                <Text fw="bold" span>
                                    {t('clarity')}:
                                </Text>
                                <Text style={{ flex: 1 }}>{t('backgroundImageClarity')}</Text>
                            </Flex>
                        </li>
                    </ul>
                    <Center>
                        <ImageFileButton
                            height={324}
                            imageSrc={form.values.basicInformation.clientBackgroundUri}
                            readOnly={!canUpdateOrCreate}
                            width={578}
                            onDeleteImage={() => form.setFieldValue('basicInformation.clientBackgroundUri', '')}
                            onImageSelected={handleBackgroundImageSelected}
                        />
                    </Center>
                </>
            )}
        </Stack>
    );
};
