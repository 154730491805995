import { MantineThemeOverride, rem } from '@mantine/core';

export const ThemeOverrides: MantineThemeOverride = {
    colors: {
        brand: [
            '#DDEEEF',
            '#CEE5E8',
            '#AED5D9',
            '#8FC4CB',
            '#70B2BC',
            '#51A0AD',
            '#448591',
            '#376B75',
            '#2A515A',
            '#1D383E'
        ],
        red: [
            '#FDF3ED',
            '#FCE5DA',
            '#F9C4B5',
            '#F59F8F',
            '#F2746A',
            '#EF4444',
            '#EC1E2E',
            '#D0122F',
            '#AA0F32',
            '#840B30'
        ]
    },
    fontSizes: {
        md: rem(14)
    },
    components: {
        ActionIcon: {
            defaultProps: {
                variant: 'subtle',
                color: 'gray'
            }
        },
        Textarea: {
            defaultProps: {
                autosize: true
            }
        },
        Modal: {
            styles: () => ({
                title: { fontWeight: 700, fontSize: '1.625rem', lineHeight: 1.35 }
            })
        },
        Switch: {
            styles: () => ({
                body: {
                    justifyContent: 'space-between'
                },
                track: {
                    cursor: 'pointer'
                }
            })
        }
    },
    primaryColor: 'brand',
    primaryShade: 5
};
