import { Flex, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { CSSProperties, Fragment, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

import { TranslationKeys } from 'i18n';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';
import { CardBase, CardDimensions } from './CardBase';

export type ItemCardPropsBase<TItemType> = {
    item: TItemType;
    height?: number;
    width?: number;
    onItemDelete: () => void | Promise<void>;
} & CardDimensions;

type Props = {
    to?: To;
    itemType: TranslationKeys;
    onDelete?: () => void | Promise<void>;
    name?: string;
    deleteConfirmation?: string;
    style?: CSSProperties | undefined;
    actions?: ReactNode[];
    children: ReactNode;
} & CardDimensions;

export const ItemCard = ({
    onDelete: handleDelete,
    name,
    itemType,
    deleteConfirmation,
    width,
    children,
    actions,
    ...others
}: Props) => {
    const { hovered, ref } = useHover();

    return (
        <div ref={ref}>
            <CardBase width={width} {...others} component={Link}>
                <Flex direction="column" style={{ height: '100%', alignItems: 'stretch', paddingBottom: '30px' }}>
                    {children}
                    <Group
                        display={hovered ? 'block' : 'none'}
                        gap={0}
                        justify="flex-end"
                        style={{
                            position: 'absolute',
                            bottom: '15px',
                            right: '15px'
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        <>
                            {!!actions && actions.map((action, index) => <Fragment key={index}>{action}</Fragment>)}
                            {handleDelete && (
                                <DeleteItemAction
                                    confirmationText={deleteConfirmation}
                                    itemType={itemType}
                                    name={name}
                                    onDeleteItem={handleDelete}
                                />
                            )}
                        </>
                    </Group>
                </Flex>
            </CardBase>
        </div>
    );
};
