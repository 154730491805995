import { createContext, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetApplicationDetailsById } from 'api/v3/application/application';
import { DetailApplicationModel } from 'api/v3/models';
import { PermittedItem } from 'modules/shared/permissions';
import { BaseLayout } from 'shared/components/baseLayout';
import { ItemNotFound } from 'shared/components/ItemNotFound';

export type ApplicationContextProps = {
    application?: DetailApplicationModel;
    permissions?: PermittedItem;
    applicationId: string;
    isLoading: boolean;
};

type ApplicationProviderProps = {
    applicationId: string;
    children?: ReactNode;
};

const ApplicationContext = createContext<ApplicationContextProps>({ applicationId: '', isLoading: false });

export const ApplicationProvider = ({ children, applicationId }: ApplicationProviderProps) => {
    const {
        data: application,
        isLoading,
        error
    } = useGetApplicationDetailsById(applicationId, { includePermissions: true });
    const { t } = useTranslation();

    if (error) {
        return (
            <BaseLayout showNavBar={false}>
                <ItemNotFound
                    errorStatus={error.response?.status}
                    goBackRoute="/"
                    itemId={applicationId}
                    itemType={t('application')}
                />
            </BaseLayout>
        );
    }

    return (
        <ApplicationContext.Provider
            value={{
                application: application?.data,
                permissions: application,
                applicationId,
                isLoading
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplication = () => useContext(ApplicationContext);
