import { Text, Title, Image, Stack } from '@mantine/core';
import { ReactNode } from 'react';

import classes from './Placeholder.module.css';

type Props = {
    text: string;
    description?: ReactNode;
    picture?: string;
    action?: ReactNode;
};

export const Placeholder = ({ text, description, picture, action }: Props) => {
    return (
        <Stack align="center">
            {picture && <Image src={picture} w={300} />}
            <Title order={3}>{text}</Title>
            {description && <Text className={classes.description}>{description}</Text>}
            {action}
        </Stack>
    );
};
