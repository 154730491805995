import { Modal } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetApiResourceRoleAssignments } from 'api/v3/role/role';
import { ManageAssignedPrincipalsForm } from 'modules/shared/manageAssignedPrincipals';
import { LoadingAnimation } from 'shared/components';
import { RolesFormType } from '../ApiFormProvider';

type Props = {
    role: RolesFormType;
    onClose: () => void;
};

export const ManageRoleMembersModal = ({ role, onClose }: Props) => {
    const [opened, setOpened] = useState(true);
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const { data, isFetching, queryKey } = useGetApiResourceRoleAssignments({
        roleIdentifiers: [role.name]
    });

    const handleClose = async () => {
        await queryClient.invalidateQueries({ queryKey });
        setOpened(false);
        onClose();
    };

    if (!role.id || !role.name) {
        throw new Error('Cannot manage role members when id or name of the role is not set.');
    }

    return (
        <Modal keepMounted={false} opened={opened} size="xl" title={t('manageMember')} onClose={handleClose}>
            {isFetching || !data || !data.data ? (
                <LoadingAnimation h={100} />
            ) : (
                <ManageAssignedPrincipalsForm
                    contextId={role.id}
                    contextType="Role"
                    principals={data.data.map((response) => response.principal)}
                    onClose={handleClose}
                />
            )}
        </Modal>
    );
};
