import { Checkbox, UnstyledButton, Text, Center, Table, Group } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';
import { ReactNode, useContext } from 'react';

import classes from './Header.module.css';
import { IFieldDescription, IRowData } from './interfaces';
import { SelectionContext } from './SelectionContextProvider';

type ThProps = {
    children: ReactNode;
    reversed: boolean;
    withSort: boolean;
    sorted: boolean;
    onSort: () => void;
};

const Th = ({ children, reversed, sorted, onSort, withSort }: ThProps) => {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

    const handleSort = onSort;

    return (
        <Table.Th className={classes.th}>
            <UnstyledButton className={classes.control} onClick={handleSort}>
                <Group>
                    <Text fw={500} size="sm">
                        {children}
                    </Text>
                    {withSort && (
                        <Center className={classes.icon}>
                            <Icon size={14} stroke={1.5} />
                        </Center>
                    )}
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
};

type Props<TRowType extends IRowData> = {
    onToggleAll(): void;
    withSort: boolean;
    fieldDescriptions: IFieldDescription<TRowType>[];
    reverseSortDirection: boolean;
    sortBy?: string | null;
    data: TRowType[];
    onSort: (key: string) => void;
    withRowActionsColumn: boolean;
};

export const Header = <TRowType extends IRowData>({
    onToggleAll,
    sortBy,
    data,
    withSort,
    onSort,
    reverseSortDirection,
    fieldDescriptions,
    withRowActionsColumn
}: Props<TRowType>) => {
    const { selectedItemKeys, selectionEnabled } = useContext(SelectionContext);
    const handleSort = (sortKey: string) => onSort(sortKey);

    return (
        <Table.Thead>
            <Table.Tr>
                {selectionEnabled && (
                    <Table.Th>
                        <Checkbox
                            checked={selectedItemKeys.length === data.length}
                            indeterminate={selectedItemKeys.length > 0 && selectedItemKeys.length !== data.length}
                            readOnly={true}
                            onChange={() => onToggleAll()}
                        />
                    </Table.Th>
                )}

                {fieldDescriptions.map((key) => (
                    <Th
                        key={key.data}
                        reversed={reverseSortDirection}
                        sorted={sortBy === key.data}
                        withSort={withSort}
                        onSort={() => withSort && handleSort(key.data)}
                    >
                        {key.header}
                    </Th>
                ))}
                {/* Column for action rows indicator */}
                {withRowActionsColumn && <th />}
            </Table.Tr>
        </Table.Thead>
    );
};
