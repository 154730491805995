import { Center, Image, Text } from '@mantine/core';

import { useDeleteClient } from 'api/v3/clients/clients';
import { BasicClientModel } from 'api/v3/models';
import { ShowActivityLogAction } from 'modules/shared/activityLog/ShowActivityLogAction';
import { usePermissions } from 'modules/shared/permissions';
import { ShowRoleAssignmentsAction } from 'modules/shared/roleAssignments/ShowRoleAssignmentsAction';
import { ItemCard } from 'shared/components';
import { ItemCardPropsBase } from 'shared/components/ItemCard';
import { DuplicateClientAction } from './components/DuplicateClientAction';
import { MoveClientAction } from './moveClient/MoveClientAction';

type Props = ItemCardPropsBase<BasicClientModel>;

type ClientCardContentProps = {
    client: BasicClientModel;
};

const ClientCardContent = ({ client }: ClientCardContentProps) => {
    return (
        <>
            <Center style={{ marginBottom: '15px' }}>
                <Image fit="cover" height={100} src={client.clientPictureUri} width={100} />
            </Center>

            <Text fw={500} lineClamp={1} size="lg">
                {client.name}
            </Text>

            <Text c="dimmed" lineClamp={4} size="sm">
                {client.description}
            </Text>
        </>
    );
};

export const ClientCard = ({ item: client, onItemDelete }: Props) => {
    const { mutateAsync: deleteClientMutationAsync } = useDeleteClient();
    const canDelete = usePermissions('Delete');

    const handleDeleteClient = async () => {
        await deleteClientMutationAsync({ id: client.id });
        onItemDelete();
    };

    return (
        <ItemCard
            actions={[
                <ShowActivityLogAction key="showActivityLog" clientId={client.id} />,
                <ShowRoleAssignmentsAction
                    key="showRoleAssignments"
                    contextId={client.id}
                    contextType="Client"
                    itemName={client.name}
                />,
                <DuplicateClientAction key="duplicateClient" clientId={client.id} />,
                <MoveClientAction key="moveClient" client={client} />
            ]}
            deleteConfirmation={client.name}
            itemType="client"
            name={client.name}
            to={`${client.id}`}
            onDelete={canDelete ? handleDeleteClient : undefined}
        >
            <ClientCardContent client={client} />
        </ItemCard>
    );
};
