import { Breadcrumbs as MantineBreadcrumbs, Anchor, useMantineTheme, Text } from '@mantine/core';
import { IconChevronsRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

type Props = {
    breadcrumbs: BreadcrumbData[];
    maxElements?: number;
};

export const Breadcrumbs = ({ breadcrumbs, maxElements }: Props) => {
    const mantineTheme = useMantineTheme();

    const elements = !maxElements ? breadcrumbs : breadcrumbs.slice((maxElements + 1) * -1);

    return (
        <MantineBreadcrumbs
            separator={<IconChevronsRight size="20" />}
            styles={{
                root: {
                    marginTop: '4px',
                    
                    textAlign: 'center'
                },
                breadcrumb: {
                    color: mantineTheme.black
                },
                separator: {
                    color: mantineTheme.black
                }
            }}
        >
            {elements.map((item, index, array) => {
                return index !== array.length - 1 ? (
                    <Anchor
                        key={item.key}
                        component={Link}
                        state={index <= 0 ? { preventRedirect: true } : undefined}
                        to={item.key}
                    >
                        {item.breadcrumb}
                    </Anchor>
                ) : (
                    <Text key={item.key} component="span">
                        {item.breadcrumb}
                    </Text>
                );
            })}
        </MantineBreadcrumbs>
    );
};
