import { Flex, Stack, Title, Card, Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import { ApplicationBaseRoute } from 'modules/applications/Routes';
import { UserManagementBaseRoute } from 'modules/userManagement/Routes';
import { BaseLayout } from 'shared/components/baseLayout';
import ApplicationsIllustration from './assets/applications.svg';
import UserManagementIllustration from './assets/user_management.svg';

export const Home = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const redirectRoot = localStorage.getItem('redirectRoot');

    if (redirectRoot && !location.state?.preventRedirect) {
        return <Navigate to={`/${redirectRoot}`} />;
    }

    const handleChosenOption = (option: string) => {
        localStorage.setItem('redirectRoot', option);
    };

    return (
        <BaseLayout showNavBar={false}>
            <Stack align="center">
                <Title my="xl" order={3}>
                    {t('chooseWhatToManage')}
                </Title>
                <Flex gap="xl" justify="center" wrap="wrap">
                    <Card
                        component={Link}
                        p="xl"
                        radius="md"
                        shadow="lg"
                        style={{ flexDirection: 'row' }}
                        to={`/${ApplicationBaseRoute}`}
                        onClick={() => handleChosenOption(ApplicationBaseRoute)}
                    >
                        <Stack>
                            <Image fit="contain" h={300} src={ApplicationsIllustration} w={300} />
                            <Title order={3} ta="center">
                                {t('applications')}
                            </Title>
                        </Stack>
                    </Card>
                    <Card
                        component={Link}
                        p="xl"
                        radius="md"
                        shadow="lg"
                        style={{ flexDirection: 'row' }}
                        to={`/${UserManagementBaseRoute}`}
                        onClick={() => handleChosenOption(UserManagementBaseRoute)}
                    >
                        <Stack align="center" justify="space-between">
                            <Image fit="contain" h={300} src={UserManagementIllustration} w={300} />
                            <Title order={3}>{t('userManagement')}</Title>
                        </Stack>
                    </Card>
                </Flex>
            </Stack>
        </BaseLayout>
    );
};
