import { useState } from 'react';

import { useGetUserActivityLogById } from 'api/v3/user/user';
import { listQueryDefault } from 'app/queryDefaults';
import { ItemsPerPageType, Pagination, getPagingParams } from 'shared/components/pagination';
import { ActivityLogGrid } from './ActivityLogGrid';
import { ClientComboBox } from './ClientsComboBox';
import { addDays } from './helpers';

type Props = {
    userId: string;
};
export const UserActivityLogGrid = ({ userId }: Props) => {
    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [clientName, setClientName] = useState<string | undefined>();

    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const {
        data: activities,
        isLoading,
        error
    } = useGetUserActivityLogById(
        userId,
        {
            clientNameFilter: clientName,
            start: startDate?.toISOString(),
            end: endDate?.toISOString(),
            ...getPagingParams(page, itemsPerPage)
        },
        listQueryDefault
    );

    const totalPages = Math.ceil((activities?.metadata?.paging?.totalCount ?? 1) / itemsPerPage);

    const clientFilter = <ClientComboBox key="clientCombo" value={clientName} onChange={setClientName} />;

    return (
        <>
            <ActivityLogGrid
                activities={activities?.data}
                additionalFilters={clientFilter}
                endDate={endDate}
                error={error}
                isLoading={isLoading}
                showUsernameColumn={false}
                startDate={startDate}
                onEndDateChange={setEndDate}
                onStartDateChange={setStartDate}
            />
            <Pagination
                disabled={isLoading}
                itemsPerPage={itemsPerPage}
                mb={32}
                page={page}
                totalPages={totalPages}
                withSkip
                onItemsPerPageChange={setItemsPerPage}
                onPageChange={setPage}
            />
        </>
    );
};
