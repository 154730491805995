import { ActionIcon, Button, Modal, Stack, Tooltip } from '@mantine/core';
import { IconChartInfographic } from '@tabler/icons-react';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ClientActivityLogGrid } from './ClientActivityLogGrid';
import { GlobalActivityLogGrid } from './GlobalActivityLogGrid';

type Props = {
    clientId?: string;
};

export const ShowActivityLogAction = ({ clientId }: Props) => {
    const [isShowActivityLogOpened, setIsShowActivityLogOpened] = useState(false);
    const { t } = useTranslation();

    const handleShowActivityLog = () => {
        setIsShowActivityLogOpened(true);
    };

    const handleHideActivityLog = () => {
        setIsShowActivityLogOpened(false);
    };

    return (
        <>
            {isShowActivityLogOpened && (
                <Modal
                    opened={isShowActivityLogOpened}
                    size={1300}
                    title={t('activityLog')}
                    centered
                    onClick={(event: MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                    }}
                    onClose={handleHideActivityLog}
                >
                    <Stack align="right">
                        {clientId && <ClientActivityLogGrid clientId={clientId} />}
                        {!clientId && <GlobalActivityLogGrid />}
                        <Button ml="auto" onClick={handleHideActivityLog}>
                            {t('close')}
                        </Button>
                    </Stack>
                </Modal>
            )}
            <Tooltip label={t('showItem', { itemType: t('activityLog') })}>
                <ActionIcon onClick={handleShowActivityLog}>
                    <IconChartInfographic />
                </ActionIcon>
            </Tooltip>
        </>
    );
};
