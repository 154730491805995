import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetClientsByApplicationId } from 'api/v3/application/application';
import { listQueryDefault } from 'app/queryDefaults';
import { useApplication } from 'modules/applications/application/ApplicationProvider';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { CreateItemModal, FlexList } from 'shared/components';
import { Placeholder } from 'shared/components/placeholder';
import clientsEmpty from './assets/clients_empty.svg';
import { ClientCard } from './ClientCard';
import { CreateClient } from './CreateClient';

const AddClientButton = ({ onClick }: { onClick: () => void }): ReactNode => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<IconPlus />} onClick={() => onClick()}>
            {t('createItem', { itemType: t('client') })}
        </Button>
    );
};

const NoClientsPlaceholder = () => {
    const { t } = useTranslation();
    const canCreate = usePermissions('Create');

    return (
        <Placeholder
            action={
                canCreate && (
                    <CreateItemModal
                        AddButton={AddClientButton}
                        AddDialogType={CreateClient}
                        title={t('createItem', { itemType: t('client') })}
                    />
                )
            }
            description={canCreate && t('noClientsDescription')}
            picture={clientsEmpty}
            text={t('noClients')}
        />
    );
};

export const Clients = () => {
    const { applicationId = '' } = useApplication();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const {
        isLoading,
        queryKey,
        data: clients,
        error
    } = useGetClientsByApplicationId(
        applicationId,
        { includeClientPictureUri: true, includePermissions: true },
        listQueryDefault
    );
    const canCreate = usePermissions('Create', 'Client', clients);

    return (
        <PermissionProvider context="Client" item={clients}>
            <FlexList
                AddDialogType={canCreate ? CreateClient : undefined}
                addDialogTitle={t('createItem', { itemType: t('client') })}
                error={error}
                header={t('clients')}
                isLoading={isLoading}
                placeholder={<NoClientsPlaceholder />}
            >
                {!!clients?.data &&
                    clients.data.map((client) => (
                        <PermissionProvider key={client.data.id} item={clients}>
                            <ClientCard
                                item={client.data}
                                onItemDelete={() => queryClient.invalidateQueries({ queryKey })}
                            />
                        </PermissionProvider>
                    ))}
            </FlexList>
        </PermissionProvider>
    );
};
