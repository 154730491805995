import { Notification, NotificationProps } from '@mantine/core';
import { IconExclamationMark } from '@tabler/icons-react';

import { ErrorNotificationContent } from './ErrorNotificationContent';

export type Props = {
    title: string;
    error?: unknown;
} & NotificationProps;

export const ErrorNotification = ({ title, error, ...others }: Props & Omit<NotificationProps, 'message'>) => {
    if (!error) {
        return null;
    }

    return (
        <Notification
            {...others}
            color="red"
            icon={<IconExclamationMark size={24} />}
            radius="2px"
            styles={(theme) => ({
                root: {
                    border: `1px solid ${theme.colors.red[6]}`,
                    borderLeftColor: `1px solid ${theme.colors.red[6]}`
                }
            })}
            withCloseButton={false}
        >
            <ErrorNotificationContent error={error} title={title} />
        </Notification>
    );
};
