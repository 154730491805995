import { ComboboxItem } from '@mantine/core';

export const getItemValue = (newItem: string | ComboboxItem | null | undefined) => {
    if (!newItem) {
        return newItem;
    }

    if (typeof newItem === 'string') {
        return newItem;
    }

    return newItem.value;
};
