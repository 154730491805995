import {
    TextInput,
    Group,
    UnstyledButton,
    Input,
    ActionIcon,
    InputWrapperProps,
    Stack,
    Text,
    Flex
} from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { IMantineFormControl, IIconDescription, Label } from 'shared/components/inputs/ExtendedInputControls';
import classes from './MultiTextInput.module.css';

type Props = {
    description?: ReactNode;
    label?: ReactNode;
    defaultValue?: string;
    addButtonText?: string;
    maxItems?: number;
    prefix?: ReactNode;
    postfix?: ReactNode;
    readonly?: boolean;
} & IMantineFormControl &
    IIconDescription &
    Omit<InputWrapperProps, 'children'>;

export const MultiTextInput = ({
    form,
    propertyPath,
    label,
    description,
    iconDescription,
    defaultValue = '',
    addButtonText,
    maxItems,
    prefix,
    postfix,
    readonly = false,
    ...others
}: Props) => {
    const { t } = useTranslation();

    const { value } = form.getInputProps(propertyPath);

    return (
        <Stack>
            <Input.Wrapper
                description={description}
                label={label && <Label iconDescription={iconDescription} label={label} />}
                {...form.getInputProps(`${propertyPath}`)}
                {...others}
            >
                {value.map((_: string, index: number) => (
                    <Flex key={index} align="end" gap="md">
                        {prefix}
                        <TextInput
                            mt="sm"
                            readOnly={readonly}
                            {...form.getInputProps(`${propertyPath}.${index}`)}
                            rightSection={
                                !readonly && (
                                    <ActionIcon color="dark" onClick={() => form.removeListItem(propertyPath, index)}>
                                        <IconTrash />
                                    </ActionIcon>
                                )
                            }
                            w="100%"
                        />
                        {postfix}
                    </Flex>
                ))}
            </Input.Wrapper>
            {(!maxItems || value.length < maxItems) && !readonly && (
                <UnstyledButton
                    className={classes.addButton}
                    onClick={() => form.insertListItem(propertyPath, defaultValue)}
                >
                    <Group gap="xs">
                        <IconPlus size={18} />
                        <Text>{addButtonText ?? t('addItem', { itemType: t('item') })}</Text>
                    </Group>
                </UnstyledButton>
            )}
        </Stack>
    );
};
