import { useId } from '@mantine/hooks';
import { closeModal } from '@mantine/modals';
import { useQueryClient } from '@tanstack/react-query';

import { getGetClientSecretsByClientIdQueryKey, useDeleteClientSecret } from 'api/v3/client-secrets/client-secrets';
import { BasicClientSecretModel } from 'api/v3/models';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';

export const DeleteClientSecretAction = (clientSecret: BasicClientSecretModel) => {
    const { mutateAsync: deleteClientSecretAsync } = useDeleteClientSecret();
    const queryClient = useQueryClient();
    const modalId = useId();

    const handleDeleteClientSecret = async () => {
        await deleteClientSecretAsync({ clientSecretId: clientSecret.id });
        await queryClient.invalidateQueries({ queryKey: getGetClientSecretsByClientIdQueryKey(clientSecret.clientId) });
        closeModal(modalId);
    };

    return <DeleteItemAction itemType="clientSecret" onDeleteItem={handleDeleteClientSecret} />;
};
