import { OidcUserInfo } from '@axa-fr/oidc-client';
import { useOidc } from '@axa-fr/react-oidc';
import { Button, Stack, Text, Divider, Center } from '@mantine/core';
import { IconExternalLink, IconLogout } from '@tabler/icons-react';

import { Avatar } from 'shared/components/avatar';
import classes from './UserImageDropDownContent.module.css';

type UserImageDropDownContentProps = {
    userProfile?: OidcUserInfo | undefined;
    userImage?: string | undefined;
};

export const UserImageDropDownContent = ({ userProfile, userImage }: UserImageDropDownContentProps) => {
    const loginServerUrl = import.meta.env.VITE_APP_OIDC_AUTHORITY;
    const { logout } = useOidc();

    return (
        <>
            <Center style={{ marginTop: '5px' }}>
                <Avatar name={userProfile?.name} size={100} src={userImage} />
            </Center>

            <Stack className={classes.userStack} gap={0} py="xs" ta="center">
                <Text ta="center" w="bold">
                    {userProfile?.name}
                </Text>
                <Text>{userProfile?.email}</Text>
            </Stack>
            <Divider style={{ width: '100%' }} />
            <Stack className={classes.buttonStack} gap={0} py="xs" ta="center">
                <Button
                    className={classes.button}
                    color="dark"
                    component="a"
                    href={`${loginServerUrl}/User`}
                    leftSection={<IconExternalLink size={18} />}
                    target="_blank"
                    variant="subtle"
                    fullWidth
                >
                    Manage Profile
                </Button>
                <Button
                    className={classes.button}
                    color="dark"
                    leftSection={<IconLogout size={18} />}
                    variant="subtle"
                    fullWidth
                    onClick={() => logout('')}
                >
                    Logout
                </Button>
            </Stack>

            {/* <Divider style={{ width: '100%' }} />
                <Text px={5} py={2} m={0} size="xs" underline italic component={Link} to="#">
                    {t('terms')}
                </Text> */}
        </>
    );
};
