import { Card } from '@mantine/core';
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { To } from 'react-router-dom';

export type CardDimensions = {
    width?: number | undefined;
};

type CardBaseProps = {
    children?: ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;

    to?: To;
    onClick?: MouseEventHandler<typeof CardBase> | undefined;
    style?: CSSProperties | undefined;
} & CardDimensions;

export const CardBase = (props: CardBaseProps) => {
    const baseStyle: CSSProperties = {
        height: '100%',
        minHeight: 180,
        width: props.width ?? 360
    };

    const { style, children } = props;

    return (
        <Card {...props} p="lg" radius="md" shadow="sm" style={{ ...baseStyle, ...style }} withBorder>
            {children}
        </Card>
    );
};
