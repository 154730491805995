import { NotificationData, notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

export const loadingNotificationTemplate = {
    loading: true,
    autoClose: false,
    withCloseButton: false
};

export const successNotificationTemplate = {
    color: 'teal',
    icon: <IconCheck size="1rem" />
};

export const errorNotificationTemplate = {
    color: 'red',
    icon: <IconX size="1rem" />
};

export const loadingNotification = (props: NotificationData & { id: string }) => {
    notifications.show({ ...loadingNotificationTemplate, ...props });
};

export const hideNotification = (id: string) => {
    notifications.hide(id);
};

export const successNotification = (props: NotificationData) => {
    notifications.show({ ...successNotificationTemplate, ...props });
};

export const errorNotification = (props: NotificationData) => {
    notifications.show({ ...errorNotificationTemplate, ...props });
};
