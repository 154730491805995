import { IconProps } from '@tabler/icons-react';
import { ForwardRefExoticComponent, createContext } from 'react';

export interface NavLinksConfig {
    link: string;
    label?: string | undefined;
    icon: ForwardRefExoticComponent<Omit<IconProps, 'ref'>>;
}

export const NavLinksContext = createContext<NavLinksConfig[]>([]);
