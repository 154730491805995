/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  BulkResponse,
  GetAllIdentityRolesResponse,
  GetApiResourceRoleAssignmentsParams,
  GetIdentityPermissionAssignmentsParams,
  GetIdentityRoleAssignmentsParams,
  GetPermissionAssignmentsResponse,
  GetRoleAssignmentsResponse,
  GetRoleAssignmentsWithContextResponse,
  GetRoleImportTemplateResponse,
  IdentityRoleAssignmentRequestModel,
  ProblemDetails,
  RoleAssignmentRequestModel,
  RoleImportModel
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.write
 */
export const assignRevokeApiResourceRoles = (
    roleId: string,
    roleAssignmentRequestModel: RoleAssignmentRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<BulkResponse>(
      {url: `/roles/api-resource-roles/${roleId}/bulkassignrevoke`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: roleAssignmentRequestModel
    },
      options);
    }
  


export const getAssignRevokeApiResourceRolesMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignRevokeApiResourceRoles>>, TError,{roleId: string;data: RoleAssignmentRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof assignRevokeApiResourceRoles>>, TError,{roleId: string;data: RoleAssignmentRequestModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignRevokeApiResourceRoles>>, {roleId: string;data: RoleAssignmentRequestModel}> = (props) => {
          const {roleId,data} = props ?? {};

          return  assignRevokeApiResourceRoles(roleId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AssignRevokeApiResourceRolesMutationResult = NonNullable<Awaited<ReturnType<typeof assignRevokeApiResourceRoles>>>
    export type AssignRevokeApiResourceRolesMutationBody = RoleAssignmentRequestModel
    export type AssignRevokeApiResourceRolesMutationError = ErrorType<ProblemDetails>

    export const useAssignRevokeApiResourceRoles = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignRevokeApiResourceRoles>>, TError,{roleId: string;data: RoleAssignmentRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getAssignRevokeApiResourceRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.read
 */
export const getApiResourceRoleAssignments = (
    params?: GetApiResourceRoleAssignmentsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetRoleAssignmentsResponse>(
      {url: `/roles/api-resource-roles/role-assignments`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiResourceRoleAssignmentsQueryKey = (params?: GetApiResourceRoleAssignmentsParams,) => {
    return [`/roles/api-resource-roles/role-assignments`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiResourceRoleAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof getApiResourceRoleAssignments>>, TError = ErrorType<ProblemDetails>>(params?: GetApiResourceRoleAssignmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleAssignments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiResourceRoleAssignmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiResourceRoleAssignments>>> = ({ signal }) => getApiResourceRoleAssignments(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiResourceRoleAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiResourceRoleAssignments>>>
export type GetApiResourceRoleAssignmentsQueryError = ErrorType<ProblemDetails>

export const useGetApiResourceRoleAssignments = <TData = Awaited<ReturnType<typeof getApiResourceRoleAssignments>>, TError = ErrorType<ProblemDetails>>(
 params?: GetApiResourceRoleAssignmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiResourceRoleAssignments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiResourceRoleAssignmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.read
 */
export const getRoleImportTemplate = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetRoleImportTemplateResponse>(
      {url: `/roles/identity-roles/export`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRoleImportTemplateQueryKey = () => {
    return [`/roles/identity-roles/export`] as const;
    }

    
export const getGetRoleImportTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getRoleImportTemplate>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleImportTemplate>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRoleImportTemplateQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoleImportTemplate>>> = ({ signal }) => getRoleImportTemplate(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRoleImportTemplate>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRoleImportTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getRoleImportTemplate>>>
export type GetRoleImportTemplateQueryError = ErrorType<ProblemDetails>

export const useGetRoleImportTemplate = <TData = Awaited<ReturnType<typeof getRoleImportTemplate>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRoleImportTemplate>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRoleImportTemplateQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.read
 */
export const getAllIdentityRoles = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetAllIdentityRolesResponse>(
      {url: `/roles/identity-roles`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllIdentityRolesQueryKey = () => {
    return [`/roles/identity-roles`] as const;
    }

    
export const getGetAllIdentityRolesQueryOptions = <TData = Awaited<ReturnType<typeof getAllIdentityRoles>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllIdentityRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllIdentityRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllIdentityRoles>>> = ({ signal }) => getAllIdentityRoles(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllIdentityRoles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllIdentityRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllIdentityRoles>>>
export type GetAllIdentityRolesQueryError = ErrorType<ProblemDetails>

export const useGetAllIdentityRoles = <TData = Awaited<ReturnType<typeof getAllIdentityRoles>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllIdentityRoles>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllIdentityRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.write
 */
export const assignRevokeIdentityRoles = (
    roleId: string,
    identityRoleAssignmentRequestModel: IdentityRoleAssignmentRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<BulkResponse>(
      {url: `/roles/identity-roles/${roleId}/bulkassignrevoke`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: identityRoleAssignmentRequestModel
    },
      options);
    }
  


export const getAssignRevokeIdentityRolesMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignRevokeIdentityRoles>>, TError,{roleId: string;data: IdentityRoleAssignmentRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof assignRevokeIdentityRoles>>, TError,{roleId: string;data: IdentityRoleAssignmentRequestModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignRevokeIdentityRoles>>, {roleId: string;data: IdentityRoleAssignmentRequestModel}> = (props) => {
          const {roleId,data} = props ?? {};

          return  assignRevokeIdentityRoles(roleId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AssignRevokeIdentityRolesMutationResult = NonNullable<Awaited<ReturnType<typeof assignRevokeIdentityRoles>>>
    export type AssignRevokeIdentityRolesMutationBody = IdentityRoleAssignmentRequestModel
    export type AssignRevokeIdentityRolesMutationError = ErrorType<ProblemDetails>

    export const useAssignRevokeIdentityRoles = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignRevokeIdentityRoles>>, TError,{roleId: string;data: IdentityRoleAssignmentRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getAssignRevokeIdentityRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.write
 */
export const importIdentityRoles = (
    roleImportModel: RoleImportModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/roles/identity-roles/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: roleImportModel
    },
      options);
    }
  


export const getImportIdentityRolesMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importIdentityRoles>>, TError,{data: RoleImportModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof importIdentityRoles>>, TError,{data: RoleImportModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importIdentityRoles>>, {data: RoleImportModel}> = (props) => {
          const {data} = props ?? {};

          return  importIdentityRoles(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ImportIdentityRolesMutationResult = NonNullable<Awaited<ReturnType<typeof importIdentityRoles>>>
    export type ImportIdentityRolesMutationBody = RoleImportModel
    export type ImportIdentityRolesMutationError = ErrorType<ProblemDetails>

    export const useImportIdentityRoles = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importIdentityRoles>>, TError,{data: RoleImportModel}, TContext>, request?: SecondParameter<typeof customMutator>}
) => {

      const mutationOptions = getImportIdentityRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.read
 */
export const getIdentityRoleAssignments = (
    params?: GetIdentityRoleAssignmentsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetRoleAssignmentsWithContextResponse>(
      {url: `/roles/identity-roles/role-assignments`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetIdentityRoleAssignmentsQueryKey = (params?: GetIdentityRoleAssignmentsParams,) => {
    return [`/roles/identity-roles/role-assignments`, ...(params ? [params]: [])] as const;
    }

    
export const getGetIdentityRoleAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof getIdentityRoleAssignments>>, TError = ErrorType<ProblemDetails>>(params?: GetIdentityRoleAssignmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIdentityRoleAssignments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIdentityRoleAssignmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIdentityRoleAssignments>>> = ({ signal }) => getIdentityRoleAssignments(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getIdentityRoleAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIdentityRoleAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getIdentityRoleAssignments>>>
export type GetIdentityRoleAssignmentsQueryError = ErrorType<ProblemDetails>

export const useGetIdentityRoleAssignments = <TData = Awaited<ReturnType<typeof getIdentityRoleAssignments>>, TError = ErrorType<ProblemDetails>>(
 params?: GetIdentityRoleAssignmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIdentityRoleAssignments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIdentityRoleAssignmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * <br><b>Required Scopes</b><br>login.umdaschgroup.com/permissions.read
 */
export const getIdentityPermissionAssignments = (
    params?: GetIdentityPermissionAssignmentsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetPermissionAssignmentsResponse>(
      {url: `/roles/identity-roles/permission-assignments`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetIdentityPermissionAssignmentsQueryKey = (params?: GetIdentityPermissionAssignmentsParams,) => {
    return [`/roles/identity-roles/permission-assignments`, ...(params ? [params]: [])] as const;
    }

    
export const getGetIdentityPermissionAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof getIdentityPermissionAssignments>>, TError = ErrorType<ProblemDetails>>(params?: GetIdentityPermissionAssignmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIdentityPermissionAssignments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIdentityPermissionAssignmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIdentityPermissionAssignments>>> = ({ signal }) => getIdentityPermissionAssignments(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getIdentityPermissionAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIdentityPermissionAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getIdentityPermissionAssignments>>>
export type GetIdentityPermissionAssignmentsQueryError = ErrorType<ProblemDetails>

export const useGetIdentityPermissionAssignments = <TData = Awaited<ReturnType<typeof getIdentityPermissionAssignments>>, TError = ErrorType<ProblemDetails>>(
 params?: GetIdentityPermissionAssignmentsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIdentityPermissionAssignments>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIdentityPermissionAssignmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



