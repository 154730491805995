import { CloseButton, Combobox, InputBase, Loader, useCombobox } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetClients } from 'api/v3/clients/clients';

type Props = {
    value: string | undefined;
    onChange: (value: string | undefined) => void;
};

export const ClientComboBox = ({ value, onChange }: Props) => {
    const [search, setSearch] = useState('');
    const { t } = useTranslation();

    const { data: apiClients, isLoading: areClientsLoading } = useGetClients({ $top: 500 });
    const clients = apiClients?.data.map((client) => ({ id: client.data.id, name: client.data.name })) ?? [];
    const filteredOptions = clients.filter((item) => item.name.toLowerCase().includes(search.toLowerCase().trim()));

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption()
    });

    const options = filteredOptions.map((item) => (
        <Combobox.Option key={item.id} value={item.name}>
            {item.name}
        </Combobox.Option>
    ));

    const rightSection = areClientsLoading ? (
        <Loader size={18} />
    ) : value ? (
        <CloseButton
            size="sm"
            onClick={() => {
                onChange(undefined);
                setSearch('');
            }}
            onMouseDown={(event) => event.preventDefault()}
        />
    ) : (
        <Combobox.Chevron />
    );

    return (
        <Combobox
            store={combobox}
            onOptionSubmit={(val) => {
                onChange(val);
                setSearch(val);
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target>
                <InputBase
                    label={t('client')}
                    rightSection={rightSection}
                    rightSectionPointerEvents={value === null ? 'none' : 'all'}
                    value={search}
                    pointer
                    onBlur={() => {
                        combobox.closeDropdown();
                        setSearch(value || '');
                    }}
                    onChange={(event) => {
                        combobox.openDropdown();
                        combobox.updateSelectedOptionIndex();
                        setSearch(event.currentTarget.value);
                    }}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                />
            </Combobox.Target>
            <Combobox.Dropdown>
                <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
                    {options.length > 0 ? options : <Combobox.Empty>{t('noOptions')}</Combobox.Empty>}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};
