import { Button, Popover, Group, NativeSelect, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getGetClientSecretsByClientIdQueryKey, useCreateClientSecret } from 'api/v3/client-secrets/client-secrets';
import { CreateClientSecretResponse } from 'api/v3/models';
import { CopyToClipboard } from 'shared/components/CopyToClipboard';
import { useClientFormContext } from '../ClientFormProvider';

export const AddClientSecretButton = () => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const lifetimeRef = useRef<HTMLSelectElement>(null);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const queryClient = useQueryClient();

    const [createdSecret, setCreatedSecret] = useState<CreateClientSecretResponse | undefined>();
    const { mutateAsync, isPending } = useCreateClientSecret();

    const lifeTimes = [90, 180, 360, 720].map((lifetime) => ({
        value: lifetime.toString(),
        label: `${lifetime} ${t('days')}`
    }));

    if (!form.values.basicInformation.id) {
        return null;
    }

    const handleGeneration = async () => {
        const lifeTime = Number(lifetimeRef.current?.value);
        const description = descriptionRef.current?.value;

        if (form.values.basicInformation.id) {
            const secret = await mutateAsync({
                clientId: form.values.basicInformation.id,
                data: { type: 'SharedSecret', description, lifetime: `${lifeTime}.00:00:00` }
            });

            setCreatedSecret(secret);
            queryClient.invalidateQueries({
                queryKey: getGetClientSecretsByClientIdQueryKey(form.values.basicInformation.id)
            });
        }
    };

    return (
        <Popover position="left" shadow="md" withArrow onClose={() => setCreatedSecret(undefined)}>
            <Popover.Target>
                <Button leftSection={<IconPlus />}>{t('generateNewClientSecret')}</Button>
            </Popover.Target>
            <Popover.Dropdown>
                {createdSecret ? (
                    <Group>
                        <TextInput value={createdSecret.data.value} w={300} readOnly />
                        <CopyToClipboard m={4} textToCopy={createdSecret.data.value ?? ''} />
                    </Group>
                ) : (
                    <Group align="end">
                        <NativeSelect ref={lifetimeRef} data={lifeTimes} label={t('lifetime')} />
                        <TextInput ref={descriptionRef} label={t('description')} width={500} />
                        <Button loading={isPending} variant="outline" onClick={handleGeneration}>
                            {t('generate')}
                        </Button>
                    </Group>
                )}
            </Popover.Dropdown>
        </Popover>
    );
};
