/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    BoxProps,
    Combobox,
    ComboboxData,
    ComboboxItem,
    ComboboxLikeProps,
    ComboboxLikeStylesNames,
    ElementProps,
    Factory,
    InputBase,
    Pill,
    PillsInput,
    StylesApiProps,
    __BaseInputProps,
    __CloseButtonProps,
    __InputStylesNames,
    extractStyleProps,
    factory,
    getOptionsLockup,
    getParsedComboboxData,
    isOptionsGroup,
    useCombobox,
    useProps,
    useResolvedStylesApi,
    useStyles
} from '@mantine/core';
import { useId, useUncontrolled } from '@mantine/hooks';
import React, { ReactNode, useEffect } from 'react';

import { filterPickedValues } from './filterPickedValues';
import { getItemValue } from './getItemValue';
import { Option } from './Option';

export type MultiSelectStylesNames = __InputStylesNames | ComboboxLikeStylesNames | 'pill' | 'pillsList' | 'inputField';

export interface MultiSelectProps
    extends BoxProps,
        __BaseInputProps,
        ComboboxLikeProps,
        StylesApiProps<MultiSelectFactory>,
        ElementProps<'input', 'size' | 'value' | 'defaultValue' | 'onChange'> {
    /** Controlled component value */
    value?: string[];

    /** Default value for uncontrolled component */
    defaultValue?: string[];

    /** Called whe value changes */
    onChange?(value: string[]): void;

    /** Controlled search value */
    searchValue?: string;

    /** Default search value */
    defaultSearchValue?: string;

    /** Called when search changes */
    onSearchChange?(value: string): void;

    /** Maximum number of values, `Infinity` by default */
    maxValues?: number;

    /** Determines whether the select should be searchable, `false` by default */
    searchable?: boolean;

    /** Message displayed when no option matched current search query, only applicable when `searchable` prop is set */
    nothingFoundMessage?: React.ReactNode;

    /** Determines whether check icon should be displayed near the selected option label, `true` by default */
    withCheckIcon?: boolean;

    /** Position of the check icon relative to the option label, `'left'` by default */
    checkIconPosition?: 'left' | 'right';

    /** Determines whether picked options should be removed from the options list, `false` by default */
    hidePickedOptions?: boolean;

    /** Determines whether the clear button should be displayed in the right section when the component has value, `false` by default */
    clearable?: boolean;

    /** Props passed down to the clear button */
    clearButtonProps?: __CloseButtonProps & ElementProps<'button'>;

    /** Props passed down to the hidden input */
    hiddenInputProps?: React.ComponentPropsWithoutRef<'input'>;

    /** Function to get create Label */
    getCreateLabel?: (query: string) => ReactNode;

    /** Function to determine if create label should be displayed */
    shouldCreate?(query: string, data: ComboboxData): boolean;

    /** Called when create option is selected */
    onCreate?(query: string): ComboboxItem | string | null | undefined;
}

export type MultiSelectFactory = Factory<{
    props: MultiSelectProps;
    ref: HTMLInputElement;
    stylesNames: MultiSelectStylesNames;
}>;

const defaultProps: Partial<MultiSelectProps> = {
    maxValues: Infinity,
    withCheckIcon: true,
    checkIconPosition: 'left'
};

export const MultiSelect = factory<MultiSelectFactory>((_props, ref) => {
    const props = useProps('MultiSelect', defaultProps, _props);
    const {
        classNames,
        className,
        style,
        styles,
        unstyled,
        size,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        vars,
        value,
        defaultValue,
        onChange,
        onKeyDown,
        variant,
        data,
        dropdownOpened,
        defaultDropdownOpened,
        onDropdownOpen,
        onDropdownClose,
        selectFirstOptionOnChange,
        onOptionSubmit,
        comboboxProps,
        filter,
        limit,
        withScrollArea,
        maxDropdownHeight,
        searchValue,
        defaultSearchValue,
        onSearchChange,
        readOnly,
        disabled,
        onFocus,
        onBlur,
        radius,
        rightSection,
        rightSectionWidth,
        rightSectionPointerEvents,
        rightSectionProps,
        leftSection,
        leftSectionWidth,
        leftSectionPointerEvents,
        leftSectionProps,
        inputContainer,
        inputWrapperOrder,
        withAsterisk,
        labelProps,
        descriptionProps,
        errorProps,
        wrapperProps,
        description,
        label,
        error,
        maxValues,
        searchable,
        nothingFoundMessage,
        withCheckIcon,
        checkIconPosition,
        hidePickedOptions,
        withErrorStyles,
        name,
        form,
        id,
        clearable,
        clearButtonProps,
        hiddenInputProps,
        placeholder,
        shouldCreate,
        getCreateLabel,
        onCreate,
        ...others
    } = props;

    const _id = useId(id);
    const parsedData = getParsedComboboxData(data);
    const optionsLockup = getOptionsLockup(parsedData);

    const combobox = useCombobox({
        opened: dropdownOpened,
        defaultOpened: defaultDropdownOpened,
        onDropdownOpen,
        onDropdownClose: () => {
            onDropdownClose?.();
            combobox.resetSelectedOption();
        }
    });

    const {
        styleProps,
        rest: { ...rest }
    } = extractStyleProps(others);

    const [_value, setValue] = useUncontrolled({
        value,
        defaultValue,
        finalValue: [],
        onChange
    });

    const [_searchValue, setSearchValue] = useUncontrolled({
        value: searchValue,
        defaultValue: defaultSearchValue,
        finalValue: '',
        onChange: onSearchChange
    });

    const getStyles = useStyles<MultiSelectFactory>({
        name: 'MultiSelect',
        classes: {} as never,
        props,
        classNames,
        styles,
        unstyled
    });

    const { resolvedClassNames, resolvedStyles } = useResolvedStylesApi<MultiSelectFactory>({
        props,
        styles,
        classNames
    });

    const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyDown?.(event);

        if (event.key === 'Backspace' && _searchValue.length === 0 && _value.length > 0) {
            setValue(_value.slice(0, _value.length - 1));
        }
    };

    const values = _value.map((item, index) => (
        <Pill
            key={`${item}-${index}`}
            unstyled={unstyled}
            withRemoveButton={!readOnly}
            onRemove={() => setValue(_value.filter((i) => item !== i))}
            {...getStyles('pill')}
        >
            {optionsLockup[item]?.label || item}
        </Pill>
    ));

    useEffect(() => {
        if (selectFirstOptionOnChange) {
            combobox.selectFirstOption();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectFirstOptionOnChange, _value]);

    const clearButton = clearable && _value.length > 0 && !disabled && !readOnly && (
        <Combobox.ClearButton
            size={size as string}
            {...clearButtonProps}
            onClear={() => {
                setValue([]);
                setSearchValue('');
            }}
        />
    );

    const filteredData = filterPickedValues({ data: parsedData, value: _value });

    const options = filteredData.map((item) => (
        <Option
            key={isOptionsGroup(item) ? item.group : item.value}
            checkIconPosition={checkIconPosition}
            data={item}
            unstyled={unstyled}
            value={value}
            withCheckIcon={withCheckIcon}
        />
    ));

    const createVal = '$create$';

    return (
        <>
            <Combobox
                __staticSelector="MultiSelect"
                classNames={resolvedClassNames}
                readOnly={readOnly}
                size={size}
                store={combobox}
                styles={resolvedStyles}
                unstyled={unstyled}
                onOptionSubmit={(val) => {
                    onOptionSubmit?.(val);
                    setSearchValue('');
                    combobox.updateSelectedOptionIndex('selected');

                    if (val) {
                        if (val === createVal) {
                            const newItem = onCreate && onCreate(_searchValue);
                            const newItemValue = getItemValue(newItem);
                            if (newItemValue) {
                                setValue([..._value, newItemValue]);
                                combobox.closeDropdown();
                            }
                        } else if (_value.includes(optionsLockup[val].value)) {
                            setValue(_value.filter((v) => v !== optionsLockup[val].value));
                        } else if (_value.length < maxValues!) {
                            setValue([..._value, optionsLockup[val].value]);
                        }
                    }
                }}
                {...comboboxProps}
            >
                <Combobox.DropdownTarget>
                    <PillsInput
                        {...styleProps}
                        __staticSelector="MultiSelect"
                        __stylesApiProps={{ ...props, multiline: true }}
                        className={className}
                        classNames={resolvedClassNames}
                        description={description}
                        descriptionProps={descriptionProps}
                        disabled={disabled}
                        error={error}
                        errorProps={errorProps}
                        id={_id}
                        inputContainer={inputContainer}
                        inputWrapperOrder={inputWrapperOrder}
                        label={label}
                        labelProps={labelProps}
                        leftSection={leftSection}
                        leftSectionPointerEvents={leftSectionPointerEvents}
                        leftSectionProps={leftSectionProps}
                        leftSectionWidth={leftSectionWidth}
                        pointer={!searchable}
                        radius={radius}
                        rightSection={
                            rightSection ||
                            clearButton || <Combobox.Chevron error={error} size={size} unstyled={unstyled} />
                        }
                        rightSectionPointerEvents={rightSectionPointerEvents || clearButton ? 'all' : 'none'}
                        rightSectionProps={rightSectionProps}
                        rightSectionWidth={rightSectionWidth}
                        size={size}
                        style={style}
                        styles={resolvedStyles}
                        unstyled={unstyled}
                        variant={variant}
                        withAsterisk={withAsterisk}
                        withErrorStyles={withErrorStyles}
                        wrapperProps={wrapperProps}
                        multiline
                        onClick={() => (searchable ? combobox.openDropdown() : combobox.toggleDropdown())}
                    >
                        <Pill.Group disabled={disabled} unstyled={unstyled} {...getStyles('pillsList')}>
                            {values}
                            <Combobox.EventsTarget>
                                <PillsInput.Field
                                    {...rest}
                                    ref={ref}
                                    id={_id}
                                    placeholder={value?.length === 0 ? placeholder : undefined}
                                    type={!searchable && !placeholder ? 'hidden' : 'visible'}
                                    {...getStyles('inputField')}
                                    disabled={disabled}
                                    pointer={!searchable}
                                    readOnly={readOnly || !searchable}
                                    unstyled={unstyled}
                                    value={_searchValue}
                                    onBlur={(event) => {
                                        onBlur?.(event);
                                        combobox.closeDropdown();
                                        searchable && combobox.closeDropdown();
                                        setSearchValue('');
                                    }}
                                    onChange={(event) => {
                                        setSearchValue(event.currentTarget.value);
                                        searchable && combobox.openDropdown();
                                        selectFirstOptionOnChange && combobox.selectFirstOption();
                                    }}
                                    onFocus={(event) => {
                                        onFocus?.(event);
                                        searchable && combobox.openDropdown();
                                    }}
                                    onKeyDown={handleInputKeydown}
                                />
                            </Combobox.EventsTarget>
                        </Pill.Group>
                    </PillsInput>
                </Combobox.DropdownTarget>
                <Combobox.Dropdown>
                    <Combobox.Options>
                        {options.length > 0 ? (
                            options
                        ) : shouldCreate && getCreateLabel && data && shouldCreate(_searchValue, data) ? (
                            <Combobox.Option value={createVal}>
                                {getCreateLabel && getCreateLabel(_searchValue)}
                            </Combobox.Option>
                        ) : (
                            <Combobox.Empty>{nothingFoundMessage}</Combobox.Empty>
                        )}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
            <input
                disabled={disabled}
                form={form}
                name={name}
                type="hidden"
                value={_value.join(',')}
                {...hiddenInputProps}
            />
        </>
    );
});

MultiSelect.classes = { ...InputBase.classes, ...Combobox.classes };
MultiSelect.displayName = '@mantine/core/MultiSelect';
