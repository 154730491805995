import { Button } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { PrincipalType } from 'api/v3/models';
import { RelatedClientGridType, useClientFormContext } from 'modules/applications/client/ClientFormProvider';
import { usePermissions } from 'modules/shared/permissions';
import { PrincipalSelector, SelectedPrincipal } from 'modules/shared/PrincipalSelector';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';

const AddRelatedClient = () => {
    const form = useClientFormContext();
    const { t } = useTranslation();

    return (
        <Button
            leftSection={<IconPlus />}
            onClick={() => form.insertListItem('permissionSettings.relatedClients', generateNewRelatedClient())}
        >
            {t('addItem', { itemType: t('relatedClient') })}
        </Button>
    );
};

export const RelatedClientsGrid = () => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canCreate = usePermissions('Create');
    const canDelete = usePermissions('Delete');

    const handlePrincipalSelected = (selectedPrincipal: SelectedPrincipal, index: number) => {
        form.setFieldValue(`permissionSettings.relatedClients.${index}.isEditable`, false);
        form.setFieldValue(`permissionSettings.relatedClients.${index}.id`, selectedPrincipal.id);
        form.setFieldValue(`permissionSettings.relatedClients.${index}.displayName`, selectedPrincipal.displayName);
    };

    return (
        <ExtendedDataTable
            canCreate={canCreate}
            canRemove={canDelete}
            data={form.values.permissionSettings.relatedClients}
            fieldDescriptions={[
                {
                    header: t('name'),
                    data: 'displayName',
                    lockUpdate: true
                },
                {
                    header: t('type'),
                    data: 'principalType',
                    lockUpdate: true
                }
            ]}
            form={form}
            generateNewItem={generateNewRelatedClient}
            placeholder={
                <Placeholder
                    action={canCreate ? <AddRelatedClient /> : undefined}
                    text={t('noRelatedClientsAssigned')}
                />
            }
            propertyPathBase="permissionSettings.relatedClients"
            renderEdit={(_item, index) => {
                return (
                    <PrincipalSelector
                        additionalPrincipals={form.values.permissionSettings.relatedClients
                            .filter((relatedClient) => !relatedClient.isRemoved)
                            .map((relatedClient) => ({
                                id: relatedClient.id,
                                principalType: PrincipalType.Client
                            }))}
                        form={form}
                        principalTypeFilter={[PrincipalType.Client]}
                        propertyPath={`permissionSettings.relatedClients.${index}.id`}
                        onPrincipalSelected={
                            canCreate ? (principal) => handlePrincipalSelected(principal, index) : undefined
                        }
                    />
                );
            }}
        />
    );
};

const generateNewRelatedClient = (): RelatedClientGridType => ({
    id: '',
    displayName: '',
    key: randomId(),
    isEditable: true,
    isRemoved: false
});
