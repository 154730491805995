import { useDebouncedValue } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { RefAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrincipalModel } from 'api/v3/models';
import { useSearchPrincipals } from 'api/v3/principal/principal';
import { MultiSelect, MultiSelectProps } from 'shared/components';
import { emailRegex } from 'shared/regex';
import { mergeData, handleOnChange, getEmailItem, getSelectItemsFromUserList } from './functions';

export type Props = {
    value?: PrincipalModel[];
    onChange?: (principals: PrincipalModel[]) => void;
} & Omit<MultiSelectProps, 'data' | 'value' | 'onChange'> &
    RefAttributes<HTMLInputElement>;

export const MultiPersonSelectInternal = ({ value, onChange, ...props }: Props) => {
    const { t } = useTranslation();
    const [emailItems, setEmailItems] = useState<PrincipalModel[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [debounceSearchValue] = useDebouncedValue(searchValue, 200);

    const { data, isLoading } = useSearchPrincipals(
        {
            searchTerm: debounceSearchValue,
            principalType: 'User'
        },
        { query: { enabled: !!debounceSearchValue } }
    );
    const filteredData: PrincipalModel[] = [
        ...emailItems,
        ...(data?.data
            .filter((principal) => !!principal.data.displayName && !!principal.data.email)
            .map((principal) => ({
                ...principal.data,
                // Use principal always have a displayName and an email adress
                displayName: principal.data.displayName!,
                email: principal.data.email!
            })) ?? [])
    ];
    const mergedList = mergeData(filteredData, value);

    return (
        <MultiSelect
            {...props}
            data={getSelectItemsFromUserList(mergedList)}
            getCreateLabel={(query) => t('inviteUserWithMail', { email: query })}
            hidePickedOptions={true}
            maxDropdownHeight={150}
            nothingFoundMessage={
                debounceSearchValue
                    ? isLoading
                        ? 'Fetching users'
                        : 'No user found matching the given search criteria'
                    : 'Please enter a search string'
            }
            rightSection={<IconChevronDown size={14} />}
            shouldCreate={(query, _data) =>
                emailRegex.test(query) && !mergedList.some((principal) => principal.email === query)
            }
            value={value?.map((principal) => principal.id ?? '')}
            onChange={(value) => handleOnChange(mergedList, value, onChange)}
            onCreate={(query) => {
                const item = getEmailItem(query);
                setEmailItems((prev) => [...prev, item]);
                return item;
            }}
            // ESLint doesn't detect this correct :(
            // eslint-disable-next-line react/jsx-sort-props
            onSearchChange={(value) => {
                setSearchValue(value);
            }}
            clearable
            // creatable
            searchable
        />
    );
};
