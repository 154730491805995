import { ActionIcon, Menu, Tooltip } from '@mantine/core';
import { IconUserShield } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { TenantLoginProviderType, UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useForcePasswordReset, getSearchUsersQueryKey } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { errorNotification, hideNotification, loadingNotification, successNotification } from 'shared/notifications';

export const ForceResetPasswordAction = (
    user: BasicUserModelApiResponse,
    index: number,
    rowActionVariant: RowActionVariant
) => {
    const { t } = useTranslation();
    const { mutateAsync: forceResetPassword } = useForcePasswordReset();
    const queryClient = useQueryClient();
    const id = useId();
    const canForceResetPassword = usePermissions('ForcePasswordReset', 'User', user);

    if (user.data.state !== UserState.Activated || user.data.loginProviderType !== TenantLoginProviderType.Local) {
        return null;
    }

    const handleForceResetPassword = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        loadingNotification({
            id,
            message: t('willBe', {
                type: t('user'),
                name: user.data.displayName,
                action: t('forcedToResetPassword')
            })
        });
        try {
            await forceResetPassword({ id: user.data.id });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            successNotification({
                message: t('wasSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('forcedToResetPassword')
                })
            });
        } catch {
            errorNotification({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('forcedToResetPassword')
                })
            });
        } finally {
            hideNotification(id);
        }
    };

    return (
        canForceResetPassword &&
        (rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('forceResetPassword')}>
                <ActionIcon onClick={handleForceResetPassword}>
                    <IconUserShield />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<IconUserShield />} onClick={handleForceResetPassword}>
                {t('forceResetPassword')}
            </Menu.Item>
        ))
    );
};
