import { useOidcUser } from '@axa-fr/react-oidc';
import { IconFriends, IconKey, IconUser, IconUsers, IconChartInfographic } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { useGetIdentityPermissionAssignments } from 'api/v3/role/role';
import { PermissionUtils } from 'modules/shared/permissions';
import { TenantBaseRoute, TenantBreadcrumb, TenantRoutes } from 'modules/userManagement/tenants/Routes';
import { UserManagementFrame } from 'modules/userManagement/UserManagementFrame';
import { LoadingAnimation } from 'shared/components';
import { BreadcrumbProvider, NavLinksProvider } from 'shared/components/baseLayout';
import { ActivityLogBaseRoute, ActivityLogRoutes } from './activityLog/Routes';
import { GroupBaseRoute, GroupBreadcrumb, GroupRoutes } from './groups/Routes';
import { RoleAssignmentsBaseRoute, RoleAssignmentsRoutes } from './roleAssignments/Routes';
import { UserManagementHome } from './UserManagementHome';
import { UserBreadcrumb, UserRoutes, UsersBaseRoute as UserBaseRoute } from './users/Routes';

export const UserManagementBaseRoute = 'user_management';

export const UserManagementRoutes = () => {
    const { t } = useTranslation();
    const { oidcUser } = useOidcUser();

    const { data } = useGetIdentityPermissionAssignments(
        {
            principalType: 'UserId',
            principalIdentifier: oidcUser?.sub,
            contextType: 'Global'
        },
        { query: { enabled: !!oidcUser } }
    );

    const breadcrumbs: BreadcrumbsRoute[] = [
        {
            path: `${UserManagementBaseRoute}/${UserBaseRoute}/:userId`,
            breadcrumb: UserBreadcrumb
        },
        {
            path: `${UserManagementBaseRoute}/${GroupBaseRoute}/:groupId`,
            breadcrumb: GroupBreadcrumb
        },
        {
            path: `${UserManagementBaseRoute}/${TenantBaseRoute}/:tenantId`,
            breadcrumb: TenantBreadcrumb
        }
    ];

    const navLinks = [
        { link: UserBaseRoute, label: t('users'), icon: IconUser },
        { link: ActivityLogBaseRoute, label: t('activityLog'), icon: IconChartInfographic },
        { link: GroupBaseRoute, label: t('groups'), icon: IconUsers },
        { link: TenantBaseRoute, label: t('tenants'), icon: IconFriends }
    ];

    if (!data) {
        return <LoadingAnimation />;
    }

    const permissions = PermissionUtils.getPermittedItemFromPermissionResponse(data);
    const canAssignRoles = PermissionUtils.hasPermission('IdentityRole', 'AssignGlobal', permissions);

    if (canAssignRoles) {
        navLinks.push({ link: RoleAssignmentsBaseRoute, label: t('globalRoleAssignments'), icon: IconKey });
    }

    return (
        <BreadcrumbProvider breadcrumbs={breadcrumbs}>
            <NavLinksProvider navLinks={navLinks}>
                <Routes>
                    <Route element={<UserManagementFrame />} path="/">
                        <Route element={<UserManagementHome />} path="/" />
                        <Route element={<UserRoutes />} path={`${UserBaseRoute}/*`} />
                        <Route element={<GroupRoutes />} path={`${GroupBaseRoute}/*`} />
                        <Route element={<TenantRoutes />} path={`${TenantBaseRoute}/*`} />
                        <Route element={<RoleAssignmentsRoutes />} path={`${RoleAssignmentsBaseRoute}/*`} />
                        <Route element={<ActivityLogRoutes />} path={`${ActivityLogBaseRoute}/*`} />
                    </Route>
                </Routes>
            </NavLinksProvider>
        </BreadcrumbProvider>
    );
};
