import { Text, Group, Stack, Title, Accordion } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { Props } from './ErrorNotification';
import classes from './ErrorNotificationContent.module.css';
import { getExceptionDetails } from './functions';

export const ErrorNotificationContent = ({ title, error }: Props) => {
    const exception = getExceptionDetails(error);

    const { t } = useTranslation();

    return (
        <Accordion classNames={{ ...classes }} defaultChecked={false} m="0" variant="filled">
            <Accordion.Item value="customization">
                <Accordion.Control>
                    <Group>
                        <Title order={4}>{t('errorTitle', { action: title })}</Title>
                        <Text>{exception?.message}</Text>
                    </Group>
                </Accordion.Control>
                <Accordion.Panel>
                    <Stack>
                        {exception?.details && (
                            <Stack>
                                {exception.details.map((detail, index) => (
                                    <Text key={index}>
                                        {typeof detail !== 'string' ? `${detail.property}: ${detail.message}` : detail}
                                    </Text>
                                ))}
                            </Stack>
                        )}
                    </Stack>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
};
